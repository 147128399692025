import { formatNumber, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { WidgetDataRequest } from '@compiere-ws/models/widget-data-request';
import { WidgetCenterService } from '@compiere-ws/services/widget-center/widget-center.service';
import { ChartType } from '@iupics-components/models/view-type.enum';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { ButtonModule } from 'primeng/button';
import { ChartModule, UIChart } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { AbstractWidgetComponent } from '../../abstract-widget.component';

@Component({
  selector: 'iu-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonModule, TooltipModule, ChartModule, TranslateModule],
})
export default class ChartWidgetComponent extends AbstractWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild('chart', { read: UIChart })
  chart: UIChart;
  options: any;
  plugins: any;
  chartType: ChartType;
  dataToDisplay: any = null;
  multiDataset: boolean;
  lastLegendDisplayState: boolean;
  lang = 'fr-FR';
  indexAxis = 'x';
  valueColRefId;
  serieColRefId;
  categoryColRefId;
  constructor(
    private themeService: ThemeService,
    private connectorService: SecurityManagerService,
    private widgetService: WidgetCenterService,
    private translateService: TranslateService,
    uiCreator: UICreatorService
  ) {
    super(uiCreator);
  }
  updateWidget() {
    const chartJsType = this.getChartJsType(this.widget.widgetType);
    if (chartJsType == null) {
      return;
    }
    this.lastLegendDisplayState = false;
    this.chartType = chartJsType;
    this.getDatas();
  }
  getChartJsType(chartType: string): ChartType {
    let chartJsType = null;
    this.indexAxis = 'x';
    switch (chartType) {
      case 'DNC':
        chartJsType = ChartType.DOUGHNUT;
        break;
      case 'PLC':
        chartJsType = ChartType.POLARAREA;
        break;
      case 'RDC':
        chartJsType = ChartType.RADAR;
        break;
      case 'SCC':
        chartJsType = ChartType.SCATTER;
        break;
      case 'BUC':
        chartJsType = ChartType.BUBBLE;
        break;
      case 'PIC':
        chartJsType = ChartType.PIE;
        break;
      case 'LIC':
        chartJsType = ChartType.LINE;
        break;
      case 'CLC':
        chartJsType = ChartType.COLUMN;
        break;
      case 'BRC':
        chartJsType = ChartType.BAR;
        this.indexAxis = 'y';
        break;
      default:
        chartJsType = null;
        break;
    }
    return chartJsType;
  }
  ngOnInit() {
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
    //#START CUSTO-SAMVAZ
    if(this.lang === 'fr-FR') {
      this.lang = 'de-CH';
    }
    //#END CUSTO-SAMVAZ
    if (this.resizeWidgetEmitter)
      this.resizeWidgetEmitter.subscribe(() => {
        if (this.chart) {
          this.adaptOptionsWithScreen();
        }
      });
  }

  ngAfterViewInit() {
    // check si on est sur mobile ou si le widget est trop petit pour afficher la légende
    if (this.dataToDisplay) {
      this.adaptOptionsWithScreen();
    }
  }

  parseAndRound(value) {
    // Parse the value to a number
    const parsedValue = parseFloat(value);
    const multiplier = this.widget.multiplier ? this.widget.multiplier : 1;
    // Check if the parsed value is a valid number
    if (!isNaN(parsedValue)) {
      // multiply the parsed value
      const dividedValue = parsedValue * multiplier;

      // Round the divided value to chosen decimal places
      if (this.widget.precision > 0) {
        const precisionTen = Math.pow(10, this.widget.precision);
        let endValue = (dividedValue * precisionTen) / precisionTen;
        if (this.widget.isRounded) {
          endValue = Number(dividedValue.toFixed(this.widget.precision));
        }
        return endValue;
      }

      return dividedValue;
    } else {
      // Return an error message if the input value is not a valid number
      return 0;
    }
  }
  getDatas() {
    this.valueColRefId = null;
    this.serieColRefId = null;
    this.categoryColRefId = null;
    if (
      (this.widget.tabId || this.widget.tableId) &&
      this.widget.aggregateColumnId &&
      this.widget.aggregateOperator &&
      this.widget.categoryColumnId
    ) {
      let request: WidgetDataRequest = { widget: this.widget };
      const sub = this.widgetService.getWidgetDatas(request).subscribe((res) => {
        if (res?.chartData) {
          this.serieColRefId = res?.serieColRefId;
          this.categoryColRefId = res?.categoryColRefId;
          this.valueColRefId = res?.valueColRefId;
          res.chartData.datasets.forEach((ds) => {
            ds.data = ds.data.map((line) => {
              const prop = this.getValueAxe();
              if (line[prop] != undefined) {
                if (line[prop] != 0) line[prop] = this.parseAndRound(line[prop]);
              } else {
                if (line != 0) line = this.parseAndRound(line);
              }

              return line;
            });
          });
          this.multiDataset =
            this.chartType === ChartType.POLARAREA ||
            this.chartType === ChartType.RADAR ||
            this.chartType === ChartType.SCATTER ||
            this.chartType === ChartType.BUBBLE;
          if (this.multiDataset && res.chartData?.datasets?.length <= 1) {
            this.dataToDisplay = null;
          } else {
            this.dataToDisplay = res.chartData;
          }
        } else {
          this.dataToDisplay = null;
        }
        if (this.dataToDisplay) {
          if ([ChartType.AREA, ChartType.BAR, ChartType.COLUMN, ChartType.RADAR].includes(this.chartType)) {
            this.dataToDisplay.datasets.forEach((dataset) => (dataset['fill'] = true));
          } else {
            this.dataToDisplay.datasets.forEach((dataset) => (dataset['fill'] = false));
          }

          const palette: any[] = this.themeService.getThemeProperty('colorsPalette');
          if (this.widget.serieColumnId) {
            this.dataToDisplay.datasets.forEach((dataset: any, i: number) => {
              const color = i < palette.length ? palette[i] : this.getRandomColor();
              const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
              const backgroundColor = color + _opacity.toString(16).toUpperCase();
              dataset.borderColor = color;
              dataset.pointBorderColor = '#fff';
              dataset.pointBackgroundColor = color;
              dataset.backgroundColor = backgroundColor;
              dataset.hoverBackgroundColor = color;
            });
          } else if (this.dataToDisplay.datasets.length > 0) {
            this.dataToDisplay.labels.forEach((label: string, i: number) => {
              const color = i < palette.length ? palette[i] : this.getRandomColor();
              const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
              const backgroundColor = color + _opacity.toString(16).toUpperCase();
              if (!this.dataToDisplay.datasets[0].backgroundColor) {
                this.dataToDisplay.datasets[0].backgroundColor = [];
              }
              this.dataToDisplay.datasets[0].backgroundColor.push(backgroundColor);
              if (!this.dataToDisplay.datasets[0].hoverBackgroundColor) {
                this.dataToDisplay.datasets[0].hoverBackgroundColor = [];
              }
              this.dataToDisplay.datasets[0].hoverBackgroundColor.push(color);
              this.dataToDisplay.datasets[0].borderColor = '#fff';
              this.dataToDisplay.datasets[0].pointBorderColor = '#fff';
              this.dataToDisplay.datasets[0].pointBackgroundColor = color;
            });
          }

          this.options = {
            responsive: true,
            maintainAspectRatio: false,

            plugins: {
              title: {
                display: true,
                fontSize: 12,
                text: this.widget.description ?? '',
              },
              tooltip: {
                callbacks: {
                  title: (ctx, idx) => {
                    if (this.serieColRefId) {
                      return this.getFormatFromReference(ctx[0].label, this.serieColRefId);
                    } else {
                      return ctx[0].dataset.label;
                    }
                  },
                  label: (data) => {
                    let dataSetValue: any = null;
                    if ([ChartType.BUBBLE, ChartType.SCATTER].includes(this.chartType as ChartType)) {
                      dataSetValue = data.dataset.data[data.dataIndex].x;
                    } else {
                      dataSetValue = data.dataset.data[data.dataIndex];
                    }
                    if (this.serieColRefId) {
                      return `${this.getFormatFromReference(data?.dataset?.label, this.categoryColRefId)} : ${dataSetValue}`;
                    } else {
                      return `${this.getFormatFromReference(data?.label, this.categoryColRefId)} : ${dataSetValue}`;
                    }
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: this.getAxesScale(),
          };
          //#START CUSTO-SAMVAZ
          if ([ChartType.BAR, ChartType.LINE].includes(this.chartType)) {
            this.options.scales = {
            yAxes: [
                {
                    ticks: {
                        callback: function(label, index, labels) {
                          const dataSetValue = parseFloat(label);
                          return formatNumber(dataSetValue, this.chart.options.lang);
                        }
                    }
                }
              ]
          };
        }
        //#END CUSTO-SAMVAZ
          if (this.chartType === ChartType.BAR) {
            this.options.indexAxis = this.indexAxis;
          }
          setTimeout(() => {
            if (this.chart) this.chart.reinit();
          }, 10);
        }
        sub.unsubscribe();
      });
    } else {
      this.dataToDisplay = null;
      this.lastLegendDisplayState = false;
      this.chartType = null;
    }
  }
  private getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Permet d'afficher ou cacher la légende
  changeLegendView() {
    this.options.plugins.legend.display = !this.options.plugins.legend.display;
    this.lastLegendDisplayState = !this.lastLegendDisplayState;
    this.chart.reinit();
  }

  adaptOptionsWithScreen() {
    if (this.chart.el.nativeElement.children[0].clientWidth <= 400 || Global.getDeviceWidth() <= 640) {
      if (this.options.title) this.options.title.fontSize = 11;
      if (this.options.plugins.legend) this.options.plugins.legend.position = 'right';
    } else {
      if (this.options.title) this.options.title.fontSize = 12;
      if (this.options.plugins.legend) this.options.plugins.legend.position = 'top';
    }
    if (this.lastLegendDisplayState !== this.options.plugins.legend.display) {
      this.lastLegendDisplayState = !this.lastLegendDisplayState;
      this.chart.reinit();
    }
  }
  getValueAxe() {
    if (ChartType.RADAR === this.chartType) {
      return 'r';
    }

    if (
      (this.indexAxis === 'y' && [ChartType.BAR].includes(this.chartType)) ||
      [ChartType.SCATTER, ChartType.BUBBLE].includes(this.chartType)
    ) {
      return 'x';
    }

    if (
      (this.indexAxis === 'x' && this.chartType == ChartType.COLUMN) ||
      [ChartType.LINE, ChartType.SCATTER, ChartType.AREA, ChartType.BUBBLE].includes(this.chartType)
    ) {
      return 'y';
    }

    return 'r';
  }
  getAxesScale() {
    if (ChartType.RADAR === this.chartType) {
      return {};
    }

    const axesScale = {};
    // has x axe
    if (
      (this.indexAxis === 'y' && [ChartType.BAR].includes(this.chartType)) ||
      [ChartType.SCATTER, ChartType.BUBBLE].includes(this.chartType)
    ) {
      const allDataForXAxe: number[] = [];
      this.dataToDisplay.datasets.forEach((ds) => {
        ds.data.forEach((line) => {
          let value = line.x ?? line;
          if (!allDataForXAxe.includes(value)) {
            allDataForXAxe.push(value);
          }
        });
      });
      axesScale['x'] = this.getTicks(allDataForXAxe);
      axesScale['y'] = this.getTicks();
    }

    if (
      (this.indexAxis === 'x' && this.chartType == ChartType.COLUMN) ||
      [ChartType.LINE, ChartType.SCATTER, ChartType.AREA, ChartType.BUBBLE].includes(this.chartType)
    ) {
      // has y axe
      let allDataForYAxe = [];
      this.dataToDisplay.datasets.forEach((ds) => {
        ds.data.forEach((line) => {
          const value = line.y ?? line;
          if (!allDataForYAxe.includes(value)) {
            allDataForYAxe.push(value);
          }
        });
      });
      axesScale['y'] = this.getTicks(allDataForYAxe);
      axesScale['x'] = this.getTicks();
    }

    return axesScale;
  }
  getTicks(data?: number[]) {
    if (!data) {
      return {
        ticks: {
          callback: (value, index, values) => {
            let displayValue = this.getFormatFromReference(
              this.dataToDisplay.labels[index],
              this.serieColRefId ?? this.categoryColRefId
            );
            if (displayValue) {
              displayValue = displayValue.split(/[ _]/);
            }

            return displayValue;
          },
        },
      };
    }
    const max = Math.max(...data);
    const min = Math.min(...data);
    const [order, mag] = this.getOrderOfMagnitude(Math.abs(max) + Math.abs(min));
    let stepSize = mag;
    if (order > 0 && order < 3) {
      stepSize *= 2.5;
    } else if (order < 4) {
      stepSize /= 4;
    }
    return {
      min: min >= 0 ? 0 : min + (Math.abs(min) % stepSize) - stepSize * 2,
      max: max <= 0 ? 0 : max - (Math.abs(max) % stepSize) + stepSize * 2,
      ticks: {
        stepSize,
        callback: (value, index, values) => {
          return `${value} ${this.widget.measureUnit}`;
        },
      },
    };
  }
  getOrderOfMagnitude(n: number) {
    // From: https://stackoverflow.com/questions/23917074/javascript-flooring-number-to-order-of-magnitude
    const order = Math.floor(Math.log(Math.abs(n)) / Math.LN10 + 0.000000001);
    return [order, Math.pow(10, order)];
  }

  private getFormatFromReference(value: any, referenceId: number) {
    if (value !== null && value !== undefined && referenceId) {
      return referenceId === 20
        ? value.toLowerCase() === 'y'
          ? this.translateService.instant('widgetEditor.yes')
          : this.translateService.instant('widgetEditor.no')
        : referenceId === 15 || referenceId === 16 || referenceId === 24
          ? this.parseDate(value, referenceId)
          : referenceId === 12 || referenceId === 37 || referenceId === 22 || referenceId === 29 || referenceId === 11
            ? value
            : value;
    } else {
      return value;
    }
  }

  private parseDate(value: any, referenceId: number) {
    let date;
    try {
      if (referenceId === 15) {
        /* Display Type 15	Date	*/
        date = new Date(value).toLocaleDateString();
      } else if (referenceId === 16) {
        /* Display Type 16	DateTime	*/
        date = new Date(value).toLocaleString();
      } else if (referenceId === 24) {
        /* Display Type 24	Time	*/
        date = new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
    } catch (error) {
      date = value;
    }
    return date;
  }
}
