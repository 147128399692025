import { computed, inject, Injectable, signal } from '@angular/core';
import { environment } from 'environments/environment';
import { iif, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  SKWDataListParams,
  SKWLineStatus,
  SKWPalletPanelCtx,
  SKWStateActionType,
  SKWTransferHeaderData,
  SKWTransferLineData,
  SKWTransferLineFormData
} from '../models/storekeeper-window.model';
import { SKWContextService } from './storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from './storekeeper-window-message.service';
import { SKWNavigationService } from './storekeeper-window-navigation.service';
import { SKWDataService } from './strokeeper-window-data.service';

@Injectable()
export class SKWTransferService {
  readonly DEFAULT_TRANSFER_FETCH_LENGTH = environment.constant?.StorekeeperWindowTransferLinePageSize ?? 30;

  #SKWContextService = inject(SKWContextService);
  #SKWDataService = inject(SKWDataService);
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWMessageService = inject(SKWMessageService);

  isNewTransfer = computed(() => {
    const Record_ID = this.#SKWNavigationService?.transferActive()?.Record_ID;
    return !Record_ID || Record_ID === 0;
  });

  canAddLine = computed(() => {
    // We can use Storekeeper_ID here because transfer details (where line creation is accessible)
    // is only reachable when assigned, if this functioning is changed, this will need to be changed too.
    return this.#SKWNavigationService.userInfos.id === this.#SKWNavigationService.transferActive()?.Storekeeper_ID;
  });

  prevData: SKWTransferHeaderData = undefined;
  data = this.#SKWNavigationService.transferActive;

  selectedLine = this.#SKWNavigationService.detailSelectedLine;
  searchData = signal<Partial<SKWTransferLineFormData>>({});

  lines = signal<Record<SKWLineStatus, SKWTransferLineData[]>>({
    TL: [],
    UL: []
  });

  linesDisplayed = computed(() => this.#getLinesDisplayed());

  hasLines = computed(() => this.#getLinesDisplayed()?.length > 0);

  isPalette = computed(() => this.data()?.isPalette === 'Y');
  isConditioning = computed(() => this.data()?.isCond === 'Y');
  isComplete = computed(() => this.data()?.DocStatus === 'CO');
  isLinesComplete = computed(() => this.data()?.Record_ID < 1 && this.#getLinesDisplayed()?.length > 0);

  currentParams: Record<SKWLineStatus, SKWDataListParams> = undefined;

  palletPanelCtx = signal<SKWPalletPanelCtx>(undefined);
  isPalletPanelOpen = signal(false);

  fetchMoreData(force = false): void {
    if (!this.data() || (!force && this.#getCurrentParamsForLineTab(this.#SKWNavigationService.detailTabActive()).lastRow !== -1))
      return;

    if (force) {
      this.#setCurrentParamsForLineTab(this.#getInitCurrentParams());
    }

    this.#SKWContextService.newAction({
      type: SKWStateActionType.GET_MORE,
      isLoading: false,
      source: this.getSource(force, this.#SKWNavigationService.detailTabActive())
    });
  }

  fetchInitData() {
    this.#initCurrentParams();

    this.#SKWContextService.newAction({
      type: SKWStateActionType.GET,
      isLoading: true,
      source: this.getSource(true, SKWLineStatus.TL).pipe(switchMap(() => this.getSource(true, SKWLineStatus.UL)))
    });
  }

  getSource(force = false, lineTab?: SKWLineStatus) {
    return this.#SKWDataService.getTransferDetails(this.data(), this.#getCurrentParamsForLineTab(lineTab), lineTab).pipe(
      tap((response) => {
        this.lines.update((lines) => {
          lines[lineTab ?? this.#SKWNavigationService.detailTabActive()] = force
            ? <SKWTransferLineData[]>response.data
            : [...lines[lineTab ?? this.#SKWNavigationService.detailTabActive()], ...(<SKWTransferLineData[]>response.data)];
          return { ...lines };
        });

        this.#setCurrentParamsForLineTab(
          {
            startRow: response.compiereRequest.endRow,
            endRow: response.compiereRequest.endRow + this.DEFAULT_TRANSFER_FETCH_LENGTH + 1,
            lastRow: response.lastRow
          },
          lineTab
        );
      }),
      map(() => undefined)
    );
  }

  selectLine(line: SKWTransferLineData | undefined, data?: Partial<SKWTransferLineFormData>) {
    if (data) {
      this.searchData.set(data);
    }
    this.selectedLine.set(line);
  }

  newLine(newTransfer = false) {
    const data = !newTransfer
      ? this.#SKWNavigationService.transferActive()
      : <SKWTransferHeaderData>{
          Record_ID: 0,
          Storekeeper_ID: this.#SKWNavigationService.userInfos.id,
          Comment: undefined,
          Description: undefined,
          table_id: undefined,
          table_name: undefined
        };

    if (newTransfer) {
      this.#SKWNavigationService.transferActive.set(data);
    }

    this.selectLine({
      Record_ID: data?.Record_ID ?? 0,
      locator_source: undefined,
      locator_destination: undefined,
      M_Product_ID: undefined,
      Qty: null,
      table_id: data?.table_id,
      table_name: data?.table_name,
      tableline_id: 324,
      tableline_name: 'M_MovementLine',
      AD_Process_ID: { id: 122, displayValue: '' },
      DocStatus: SKWLineStatus.TL
    });
  }

  openPalletPanel(ctx: SKWPalletPanelCtx) {
    // TODO:
  }

  save(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData, isPalette: boolean) {
    isPalette ? this.savePalletTransfer(transfer, line) : this.saveTransferAndLine(transfer, line);
  }

  savePalletTransfer(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData) {
    return this.#save(
      this.#SKWDataService.savePalletTransfer({
        Description: transfer?.Description ?? '',
        M_Locator_ID: line?.palette?.id,
        Storekeeper_ID: this.#SKWNavigationService.userInfos.id,
        XX_Pallet_ID: transfer?.Record_ID ?? 0
      }),
      transfer
    );
  }

  saveTransferAndLine(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData) {
    return this.#save(this.#SKWDataService.saveTransfer(transfer, line), transfer);
  }

  #save(source: Observable<any>, transfer: SKWTransferHeaderData) {
    this.#SKWContextService.newAction({
      type: SKWStateActionType.SAVE,
      clearData: false,
      isLoading: true,
      source: source.pipe(
        switchMap(({ result, request }) =>
          iif(
            () => !result.success,
            of({ result, request, transfer: undefined }),
            this.#SKWDataService
              .getTransfer({
                ...transfer,
                Record_ID: result.Record_ID,
                table_id: undefined,
                table_name: undefined
              })
              .pipe(map((res) => ({ result, request, transfer: res })))
          )
        ),
        tap(({ result, transfer }) => {
          if (!result.success) {
            this.#SKWMessageService.addMessage({
              type: SKWMessageType.ERROR,
              key: 'saveTransferLineError',
              // TODO: translate
              title: 'SaveTransferLineError',
              content: result.message
            });
            return;
          }
          this.#SKWNavigationService.transferActive.set(transfer);
          this.selectLine(undefined);
        }),
        map(({ transfer }) => {
          return transfer
            ? {
                data: {
                  ...this.#SKWContextService.state.data(),
                  tasks: {
                    ...(this.#SKWContextService.state.data()?.tasks ?? {}),
                    AS: [...(this.#SKWContextService.state.data()?.tasks?.AS ?? []), transfer]
                  }
                }
              }
            : undefined;
        })
      )
    });
  }

  #getLinesDisplayed() {
    return this.lines()?.[this.#SKWNavigationService.detailTabActive()];
  }

  #setCurrentParamsForLineTab(data: SKWDataListParams, lineTab?: SKWLineStatus) {
    this.currentParams[lineTab ?? this.#SKWNavigationService.detailTabActive()] = {
      ...data
    };
  }

  #getCurrentParamsForLineTab(lineTab?: SKWLineStatus) {
    return this.currentParams[lineTab ?? this.#SKWNavigationService.detailTabActive()];
  }

  #initCurrentParams() {
    this.currentParams = {
      TL: this.#getInitCurrentParams(),
      UL: this.#getInitCurrentParams()
    };
  }

  #getInitCurrentParams(): SKWDataListParams {
    return {
      startRow: 0,
      endRow: this.DEFAULT_TRANSFER_FETCH_LENGTH,
      lastRow: -1
    };
  }
}
