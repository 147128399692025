//#START CUSTO-SAMVAZ
import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import  SpecificWindowUiComponent  from '../specific-window-ui/specific-window-ui.component';
import { NgClass } from '@angular/common';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressBarModule } from 'primeng/progressbar';
export interface CalculPropositionJson {
  key: string;
  DeliveryViaRule: string;
  M_Shipper_ID: string;
  DatePromised: string;
  XX_TransportInformation: string;
}

export interface PropositionChangedJson {
  key: string;
  old_DeliveryViaRule: string;
  new_DeliveryViaRule: string;
  old_M_Shipper_ID: Number;
  new_M_Shipper_ID: Number;
  old_DatePromised?: string;
  new_DatePromised: string;

  old_DeliveryViaRule_value: string;
  new_DeliveryViaRule_value: string;
  old_M_Shipper_value: string;
  new_M_Shipper_value: string;
}

@Component({
  selector: 'iu-delivery-mode-selection-ui',
  templateUrl: './delivery-mode-selection-ui.component.html',
  styleUrls: ['./delivery-mode-selection-ui.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export class DeliveryModeSelectionUIComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  @ViewChild('vcrTable', { read: ViewContainerRef, static: false }) vcrTable: ViewContainerRef;

  newMessage: CalculPropositionJson;
  statutProposition: PropositionChangedJson;
  msgText: PropositionChangedJson;
  constructor() {
    super();

    this.customDesignArray.push(
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_InOut_ID'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID'
      },
      {
        vcr: 'vcrTable',
        type: CustomDesignItemType.GRID,
        tableName: 'Bon de livraison en cours',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'DeliveryViaRule',
        cssClass: 'p-col-12 p-md-4 p-lg-4'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Shipper_ID',
        cssClass: 'p-col-12 p-md-4 p-lg-4'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'DatePromised',
        cssClass: 'p-col-12 p-md-4 p-lg-4'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Weight',
        cssClass: 'p-col-12 p-md-4 p-lg-4'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'XX_MaxWeightPost',
        cssClass: 'p-col-12 p-md-4 p-lg-4'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'XX_TransportInformation',
        cssClass: 'p-col-12 p-md-6 p-lg-6'
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'XX_CalculProposition',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'ValiderModeTransport',
        btnType: 'primary',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      }
    );

    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
    this.showSpecificWindow();

    this.statutProposition = {
      key: 'statutProposition',
      old_DeliveryViaRule: '',
      new_DeliveryViaRule: '',
      old_M_Shipper_ID: 0,
      new_M_Shipper_ID: 0,
      old_DatePromised: '',
      new_DatePromised: '',

      old_DeliveryViaRule_value: this.getFormStoreData('DeliveryViaRule')['displayValue'],
      new_DeliveryViaRule_value: this.getFormStoreData('DeliveryViaRule')['displayValue'],
      old_M_Shipper_value: this.getFormStoreData('M_Shipper_ID'),
      new_M_Shipper_value: this.getFormStoreData('M_Shipper_ID')
    };

    let datacontainer2 = this.dataContainers.find((elem) => elem.data.columnName === 'XX_TransportInformation');
    if (datacontainer2 !== undefined) {
      datacontainer2.updateStore(' ', []);
    }

    datacontainer2 = this.dataContainers.find((elem) => elem.data.columnName === 'ValiderModeTransport');
    if (datacontainer2 !== undefined) {
      datacontainer2.data.readOnlyLogic = '1=1';
    }

    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item && item.data && item.data.columnName === 'XX_CalculProposition') {
          this.executeProcess(item.processId);
          if (this.dataStore && !this.getFormStoreData('M_Shipper_ID')){
            const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'M_Shipper_ID');
            if (datacontainer !== undefined) {
              datacontainer.updateStore(this.dataStore.data['M_Shipper_ID']);
            }
          }     
        }
      });
    });
  }

  processValiderModeTransport(item) {
    this.executeProcess(item.processId, { MsgText: this.msgText });
  }
  notifyFromLogs(log) {
    super.notifyFromLogs(log);

    try {
      this.newMessage = JSON.parse(log);
    } catch (objError) {
      /*if (objError instanceof SyntaxError) {
          console.error(objError.name);
      } else {
          console.error(objError.message);
      }*/
    }

    try {
      if (this.newMessage.key === 'CalculProposition') {
        this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
          specificWindow.items.forEach((item) => {
            switch (item.data.columnName) {
              case 'DeliveryViaRule': {
                const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DeliveryViaRule');
                if (datacontainer !== undefined) {
                  datacontainer.updateStore(this.newMessage.DeliveryViaRule, []);
                }
                break;
              }
              case 'M_Shipper_ID': {
                const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'M_Shipper_ID');
                if (datacontainer !== undefined) {
                  datacontainer.updateStore(this.newMessage.M_Shipper_ID, []);
                }
                break;
              }
              case 'DatePromised': {
                const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DatePromised');
                if (datacontainer !== undefined) {
                  datacontainer.updateStore(this.newMessage.DatePromised, []);
                }
                break;
              }
              case 'XX_TransportInformation':
                {
                  const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'XX_TransportInformation');
                  if (datacontainer !== undefined) {
                    if (datacontainer.fieldValue !== undefined && datacontainer.fieldValue !== null) {
                      // var msg_changed = this.newMessage.XX_TransportInformation + ' # \n' + datacontainer.fieldValue;
                      const msg_changed = this.newMessage.XX_TransportInformation;
                      datacontainer.updateStore(msg_changed);
                    } else {
                      datacontainer.updateStore(this.newMessage.XX_TransportInformation, []);
                    }
                  }
                  break;
                }
                break;
            }
          });
        });
        if (this.newMessage.DatePromised != null && this.newMessage.DatePromised.length > 0) {
          this.statutProposition = {
            key: 'statutProposition',
            old_DeliveryViaRule: this.newMessage.DeliveryViaRule,
            new_DeliveryViaRule: this.newMessage.DeliveryViaRule,
            old_M_Shipper_ID: parseInt(this.newMessage.M_Shipper_ID, 10),
            new_M_Shipper_ID: parseInt(this.newMessage.M_Shipper_ID, 10),
            old_DatePromised: this.newMessage.DatePromised,
            new_DatePromised: this.newMessage.DatePromised,

            old_DeliveryViaRule_value: this.getFormStoreData('DeliveryViaRule')['displayValue'],
            new_DeliveryViaRule_value: this.getFormStoreData('DeliveryViaRule')['displayValue'],
            old_M_Shipper_value: this.dataStore.data['M_Shipper_ID'].displayValue,
            new_M_Shipper_value: this.dataStore.data['M_Shipper_ID'].displayValue
          };
        } else {
          this.statutProposition = {
            key: 'statutProposition',
            old_DeliveryViaRule: this.newMessage.DeliveryViaRule,
            new_DeliveryViaRule: this.newMessage.DeliveryViaRule,
            old_M_Shipper_ID: parseInt(this.newMessage.M_Shipper_ID, 10),
            new_M_Shipper_ID: parseInt(this.newMessage.M_Shipper_ID, 10),
            new_DatePromised: this.newMessage.DatePromised,

            old_DeliveryViaRule_value: this.getFormStoreData('DeliveryViaRule')['displayValue'],
            new_DeliveryViaRule_value: this.getFormStoreData('DeliveryViaRule')['displayValue'],
            old_M_Shipper_value: this.dataStore.data['M_Shipper_ID'].displayValue,
            new_M_Shipper_value: this.dataStore.data['M_Shipper_ID'].displayValue
          };
        }
        this.msgText = this.statutProposition;
        const datacontainer2 = this.dataContainers.find((elem) => elem.data.columnName === 'ValiderModeTransport');
        if (datacontainer2 !== undefined) {
          datacontainer2.data.readOnlyLogic = undefined;
        }
        this.newMessage = null;
      } else if (this.newMessage.key === 'TransportModeValidation') {
        const datacontainer2 = this.dataContainers.find((elem) => elem.data.columnName === 'XX_TransportInformation');
        if (datacontainer2 !== undefined) {
          datacontainer2.updateStore(this.newMessage.XX_TransportInformation, []);
        }
      }
    } catch (objError) {
      /*if (objError instanceof SyntaxError) {
        console.error(objError.name);
    } else {
        console.error(objError.message);
    }
    */
    }
  }
  ngOnDestroy(): void {}

  private setM_Shipper() {  
    setTimeout(() => {
    let  DeliveryViaRule ;
   
    if (this.getFormStoreData('DeliveryViaRule') !== undefined && this.getFormStoreData('DeliveryViaRule') !== null) {
          DeliveryViaRule = this.getFormStoreData('DeliveryViaRule')['id'];
    } else {
      DeliveryViaRule= '';
    }

  

     let  ShipperName = null ;

    switch  (DeliveryViaRule){
      case 'P'  :  {   

        ShipperName = 'STANDARD' ;
         break;
    }
      case 'Q'  :  {   
         
        ShipperName = 'POSTE'  ;
      break;
    }
      case 'R'  :  { 
        ShipperName =  'REPRESENTANT' ;
      break;
    }
      case 'S'  :  {   
        ShipperName = 'EXTERNE' ;
         break;
    }
    
    }
// account
const tableName = "( SELECT M_Shipper_ID FROM M_Shipper  where Name = '" + ShipperName + "' "
+ ") cpt"; 
    const request: DataStoreRequest = {
      windowId: null, 
      parent_constraint:  null,
      compiereRequest: {
        tableName: tableName,
        startRow: 0,
        endRow: 1,
        filterModel: { }
      }
    };
    console.log(new Date());
    
    this.dataStore.data['M_Shipper_ID'] = { id: null  , displayValue: '' };  
    this.dataStore.data['M_Shipper_ID'] = null;  
    this.store.syncDataChanges(this.dataStore, { M_Product_ID: null });
    
    this.dataContainers.forEach((datacontainer) => {
      if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'M_Shipper_ID') {
    datacontainer.updateStore(this.dataStore.data['M_Shipper_ID']);
      }
       });
      
     
      console.log(this.getFormStoreData('M_Shipper_ID'));
      console.log(new Date());
    this.store.getDataGrid(request).subscribe(response => {  
      if (response && response.data && response.data[0]) {
          
              this.dataStore.data['M_Shipper_ID'] = { id:response.data[0]['M_SHIPPER_ID'] , displayValue:ShipperName };
              this.store.syncDataChanges(this.dataStore, { M_Shipper_ID: response.data[0]['M_SHIPPER_ID']  });
              
       
    }  else {
          this.dataStore.data['M_Shipper_ID'] = null;
        }
      
    
       this.dataContainers.forEach((datacontainer) => {
        if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'M_Shipper_ID') {
      datacontainer.updateStore(this.dataStore.data['M_Shipper_ID']);
        }
         });
         console.log(new Date());
  }); 

}, 500);
    
  }
  notifyFromDataChange(item: any, vcrIndex?: number) {
    
    let msg_changed = '';
    const editedStatutProposition = cloneDeep(this.msgText);
    switch (item.data.columnName) {
      case 'DeliveryViaRule': {
        this.setM_Shipper();
       if( this.statutProposition !== undefined && editedStatutProposition !== undefined  && editedStatutProposition !== null  &&  this.statutProposition !== null  ){
        if (this.getFormStoreData('DeliveryViaRule') !== undefined && this.getFormStoreData('DeliveryViaRule') !== null) {
          this.statutProposition.new_DeliveryViaRule = this.getFormStoreData('DeliveryViaRule')['id'];
          editedStatutProposition.new_DeliveryViaRule = this.getFormStoreData('DeliveryViaRule')['id'];
          this.statutProposition.new_DeliveryViaRule_value = this.getFormStoreData('DeliveryViaRule')['displayValue'];
          editedStatutProposition.new_DeliveryViaRule_value = this.getFormStoreData('DeliveryViaRule')['displayValue'];
        } else {
          this.statutProposition.new_DeliveryViaRule = '';
          editedStatutProposition.new_DeliveryViaRule = '';
          this.statutProposition.new_DeliveryViaRule_value = '';
          editedStatutProposition.new_DeliveryViaRule_value = '';
        }
        if (this.statutProposition.new_DeliveryViaRule != this.statutProposition.old_DeliveryViaRule) {
          if (
            this.statutProposition.old_DeliveryViaRule_value == null ||
            this.statutProposition.old_DeliveryViaRule_value == 'Disponible'
          ) {
            msg_changed += ' DeliveryViaRule changed to ' + this.statutProposition.new_DeliveryViaRule_value;
          } else {
            msg_changed +=
              '\n DeliveryViaRule changed from ' +
              this.statutProposition.old_DeliveryViaRule_value +
              ' to ' +
              this.statutProposition.new_DeliveryViaRule_value;
          }
        }
     }
      
        break;
      }
      case 'M_Shipper_ID': {
        if( this.statutProposition !== undefined && editedStatutProposition !== null  && editedStatutProposition !== undefined  && this.statutProposition !== null  ){
        if (this.getFormStoreData('M_Shipper_ID') !== undefined && this.getFormStoreData('M_Shipper_ID') !== null) {
          this.statutProposition.new_M_Shipper_ID = parseInt(this.getFormStoreData('M_Shipper_ID')['id'], 10);
          editedStatutProposition.new_M_Shipper_ID = parseInt(this.getFormStoreData('M_Shipper_ID')['id'], 10);
          this.statutProposition.new_M_Shipper_value = this.getFormStoreData('M_Shipper_ID')['displayValue'];
          editedStatutProposition.new_M_Shipper_value = this.getFormStoreData('M_Shipper_ID')['displayValue'];
        } else {
          this.statutProposition.new_M_Shipper_ID = 0;
          editedStatutProposition.new_M_Shipper_ID = 0;
          this.statutProposition.new_M_Shipper_value = '';
       //   editedStatutProposition.new_M_Shipper_value = '';
        }
        if (this.statutProposition.new_M_Shipper_ID != this.statutProposition.old_M_Shipper_ID) {
          if (this.statutProposition.old_M_Shipper_value == null) {
            msg_changed += ' Shipper changed  to ' + this.statutProposition.new_M_Shipper_value;
          } else {
            if (typeof this.statutProposition.old_M_Shipper_value === 'object') {
              msg_changed +=
                '\n Shipper changed from ' +
                this.statutProposition.old_M_Shipper_value!['displayValue'] +
                ' to ' +
                this.statutProposition.new_M_Shipper_value;
            } else {
              msg_changed +=
                '\n Shipper changed from ' +
                this.statutProposition.old_M_Shipper_value +
                ' to ' +
                this.statutProposition.new_M_Shipper_value;
            }
          }
        }}
        break;
      }
      case 'DatePromised': {
        const date = new Date(this.getFormStoreData('DatePromised')); // .toLocaleDateString();
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        const dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);

        this.statutProposition.new_DatePromised = dateString;
        editedStatutProposition.new_DatePromised = dateString;

        if (this.statutProposition.new_DatePromised != this.statutProposition.old_DatePromised) {
          if (this.statutProposition.old_DatePromised == null || this.statutProposition.old_DatePromised.length == 0) {
            msg_changed += ' DatePromised changed to ' + this.statutProposition.new_DatePromised;
          } else {
            msg_changed +=
              '\n DatePromised changed from ' +
              this.statutProposition.old_DatePromised +
              ' to ' +
              this.statutProposition.new_DatePromised;
          }
        }
        break;
      }
    }

    this.msgText = cloneDeep(editedStatutProposition);
    if( this.statutProposition !== undefined  &&  this.statutProposition !== null  ){ 
    if (this.statutProposition.new_DatePromised != this.statutProposition.old_DatePromised) {
      this.statutProposition.old_DatePromised = this.statutProposition.new_DatePromised;
    }
    if (this.statutProposition.new_M_Shipper_ID != this.statutProposition.old_M_Shipper_ID) {
      this.statutProposition.old_M_Shipper_ID = this.statutProposition.new_M_Shipper_ID;
      this.statutProposition.old_M_Shipper_value = this.statutProposition.new_M_Shipper_value;
    }
    if (this.statutProposition.new_DeliveryViaRule != this.statutProposition.old_DeliveryViaRule) {
      this.statutProposition.old_DeliveryViaRule = this.statutProposition.new_DeliveryViaRule;
      this.statutProposition.old_DeliveryViaRule_value = this.statutProposition.new_DeliveryViaRule_value;
    }
  }
    const datacontainer2 = this.dataContainers.find((elem) => elem.data.columnName === 'XX_TransportInformation');
    if (
      datacontainer2 !== undefined
      // && datacontainer2.fieldValue !== null
    ) {
      if (datacontainer2.fieldValue !== undefined && datacontainer2.fieldValue !== null) {
        // msg_changed = msg_changed + " # \n" +(datacontainer2.fieldValue);
        msg_changed = datacontainer2.fieldValue + ' \n' + msg_changed + ' #';
      }

      datacontainer2.updateStore(msg_changed, []);
    }
    super.notifyFromDataChange(item);
  }

  
  
}
//#END CUSTO-SAMVAZ