<div class="content" cdkVirtualScrollingElement>
  <iu-storekeeper-transfer-details class="details" />
  @if (selectedLine()) {
    <iu-storekeeper-transfer-line-details />
  } @else {
    <iu-storekeeper-transfer-lines [parentContainer]="container()" />
  }
</div>

@if (!isComplete() && !selectedLine()) {
  @if (isLinesComplete()) {
    <iu-storekeeper-navbar-button
      [icon]="'icon-circle-check'"
      [label]="'ConfirmTransfer' | SKWTranslate"
      [type]="'confirm'"
      (onClick)="save($event)"
    />
  } @else if (!isNewTransfer() || hasLines()) {
    <iu-storekeeper-navbar class="navbar" [canExpand]="false">
      <iu-storekeeper-navbar-button
        [icon]="'icon-dolly'"
        [label]="'ToLoad' | SKWTranslate"
        [active]="tabActive() === 'TL'"
        [count]="lines()?.TL?.length"
        [hideCount]="!state.loaded() || lines()?.TL?.length === 0"
        (onClick)="changeActiveTab($event, 'TL')"
      />
      <iu-storekeeper-navbar-button
        [icon]="'icon-truck-ramp-box'"
        [label]="'ToUnload' | SKWTranslate"
        [active]="tabActive() === 'UL'"
        [count]="isConditioning() ? 1 : lines()?.UL?.length"
        [hideCount]="!state.loaded() || lines()?.UL?.length === 0"
        (onClick)="changeActiveTab($event, 'UL')"
      />
    </iu-storekeeper-navbar>
  }
}

<iu-storekeeper-window-pallet-panel />
