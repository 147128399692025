import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataStore, DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { CompiereNotification, CompiereNotificationPriority } from '@compiere-ws/models/compiere-notification-json';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { CacheManagerService, TableName } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { FakeArrayPipe } from '@iupics-util/pipes/fake-array/fake-array.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of,zip } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import JoinFileUiComponent from '../join-file-ui/join-file-ui.component';
import ProcessUiComponent from '../process-ui/process-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import {
  InputInfo,
  InputInfoSource,
  getBankRequest,
  getChargeDescRequest,
  getCountryDataRequest,
  getCountryDataRequestByIBAN,
  getCurrencyRequest,
  getHeaderCustomDesignItems,
  getInvoiceLineRequest,
  getInvoiceRequest,
  getInvoiceTaxRequest,
  getInvoiceZPayComRequest,
  getLineCustomDesignItems,
  getLogMessageRequest,
  getNullValueInData,
  getOrderRequest,
  getPartnerLocationRequest,
  getPartnerRequest,
  getProductDescRequest,
  getTabDataRequest,
  getUserRoleRequest,
} from './fast-create-invoice-vendor-utils';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
@Component({
  selector: 'iu-fast-create-invoice-vendor',
  templateUrl: './fast-create-invoice-vendor.component.html',
  styleUrls: ['./fast-create-invoice-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ToolbarModule,
    ButtonModule,
    TooltipModule,
    FormsModule,
    InputNumberUiComponent,
    ScrollPanelModule,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    ProcessUiComponent,
    JoinFileUiComponent,
    TranslateModule,
    FakeArrayPipe,
  ],
})
// TODO flex du deuxieme table
export default class FastCreateInvoiceVendorComponent
  extends SpecificWindowUiComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('amountToPayInput', { read: InputNumberUiComponent, static: true })
  amountToPayInput: InputNumberUiComponent;
  @ViewChild('linesContainer', { read: ElementRef, static: true })
  linesContainer: ElementRef;
  @ViewChild('vcrBVR', { read: ViewContainerRef, static: true })
  vcrBVR: ViewContainerRef;
  @ViewChild('vcrBPartner', { read: ViewContainerRef, static: true })
  vcrBPartner: ViewContainerRef;
  @ViewChild('vcrBPBankAccount', { read: ViewContainerRef, static: true })
  vcrBPBankAccount: ViewContainerRef;
  @ViewChild('vcrProcessInvoice', { read: ViewContainerRef, static: true })
  vcrProcessInvoice: ViewContainerRef;
  @ViewChild('vcrDeliveryRule', { read: ViewContainerRef, static: true })
  vcrDeliveryRule: ViewContainerRef;
  @ViewChild('vcrDeliveryViaRule', { read: ViewContainerRef, static: true })
  vcrDeliveryViaRule: ViewContainerRef;
  //#START CUSTO-SAMVAZ
  @ViewChild('vcrValidator', { read: ViewContainerRef, static: true })
  vcrValidator: ViewContainerRef;
  //#END CUSTO-SAMVAZ
  @ViewChild('vcrCreateFrom', { read: ViewContainerRef, static: true })
  vcrCreateFrom: ViewContainerRef;

  @ViewChild('vcrBPartnerLocation', { read: ViewContainerRef, static: true })
  vcrBPartnerLocation: ViewContainerRef;

  @ViewChild('vcrOrder', { read: ViewContainerRef, static: true })
  vcrOrder: ViewContainerRef;

  @ViewChild('vcrZ_PaymentCommunication', { read: ViewContainerRef, static: true })
  vcrZ_PaymentCommunication: ViewContainerRef;
  @ViewChild('vcrDateInvoiced', { read: ViewContainerRef, static: true })
  vcrDateInvoiced: ViewContainerRef;
  @ViewChild('vcrPaymentTerm', { read: ViewContainerRef, static: true })
  vcrPaymentTerm: ViewContainerRef;
  @ViewChild('vcrPriceListHeader', { read: ViewContainerRef, static: true })
  vcrPriceListHeader: ViewContainerRef;

  @ViewChildren('vcrCharge', { read: ViewContainerRef })
  vcrsCharge: QueryList<ViewContainerRef>;
  @ViewChildren('vcrProduct', { read: ViewContainerRef })
  vcrsProduct: QueryList<ViewContainerRef>;

  @ViewChildren('vcrQty', { read: ViewContainerRef })
  vcrsQty: QueryList<ViewContainerRef>;

  @ViewChildren('vcrDescription', { read: ViewContainerRef })
  vcrsDescription: QueryList<ViewContainerRef>;

  @ViewChildren('vcrPriceEntered', { read: ViewContainerRef })
  vcrsPriceEntered: QueryList<ViewContainerRef>;

  @ViewChildren('vcrPriceActual', { read: ViewContainerRef })
  vcrsPriceActual: QueryList<ViewContainerRef>;
  @ViewChildren('vcrPriceList', { read: ViewContainerRef })
  vcrsPriceList: QueryList<ViewContainerRef>;

  @ViewChildren('vcrLineTotalAmt', { read: ViewContainerRef })
  vcrsLineTotalAmt: QueryList<ViewContainerRef>;
  @ViewChildren('vcrLineNetAmt', { read: ViewContainerRef })
  vcrsLineNetAmt: QueryList<ViewContainerRef>;
  @ViewChildren('vcrOthers', { read: ViewContainerRef })
  vcrsOthers: QueryList<ViewContainerRef>;

  @ViewChildren('vcrTax', { read: ViewContainerRef })
  vcrsTax: QueryList<ViewContainerRef>;
  @ViewChildren('vcrTaxAmt', { read: ViewContainerRef })
  vcrsTaxAmt: QueryList<ViewContainerRef>;

  @ViewChild('vcrGrandTotal', { read: ViewContainerRef, static: true })
  vcrGrandTotal: ViewContainerRef;

  @ViewChild('vcrSettings', { read: ViewContainerRef, static: true })
  vcrSettings: ViewContainerRef;
  @ViewChild('logsContainer', { read: ElementRef, static: true })
  logsContainer: ElementRef;
  @ViewChild('inputInfoArea', { read: ElementRef, static: true })
  inputInfoArea: ElementRef;
  logs: QuickInvoiceLog[] = [];
  isSidebarOpen = false;
  subscription;
  docStatus = '*';
  inputInfoString = '';
  inputInfoStringLast = '';
  inputInfo: InputInfo = new InputInfo([]);
  amountToPay = 0;
  // AD
  messages: ADMessage[];
  tableID;
  invoiceTabID;
  invoiceLineTabID;
  invoiceTaxTabID;
  windowID;
  marginError = 0.05;
  displayJoinFilesPanel = false;
  nbUploadedFiles = 0;
  dsKey: DataStoreKey;
  placeHolder = '';
  currency = '';
  lineMetaData: CustomDesignItem[] = [];

  dataLines: DataStore[] = [];
  dataContainersLine: AbstractDataContainer[][] = [];
  tabindex = 1;
  grandTotal = 0;
  grandVatTotal = 0;
  grandNetTotal = 0;
  grandTotalData = { numberType: NumberType.AMOUNT };
  ibanValue = '';
  ad_language = 'fr_FR';
  isProcessed = false;
  displayProcessUI = false;
  processId = null;
  MANDATORY_HEADER_COLUMNS = [
    'C_BPartner_ID',
    'C_BPartner_Location_ID',
    'DateInvoiced',
    'AD_Org_ID',
    'SalesRep_ID',
    'DateAcct',
    'XX_BVR',
    'Z_PaymentCommunication',
    'M_PriceList_ID',
    'C_DocTypeTarget_ID',
    'AD_Client_ID',
    'C_PaymentTerm_ID',
    'PaymentRule',
    'C_Currency_ID',
    'IsReturnTrx',
    'C_BP_BankAccount_ID',
    //#START CUSTO-SAMVAZ
    'XX_Validator_ID'
    //#END CUSTO-SAMVAZ
  ];
  isInputInfoLoading = false;
  translations: any;
  nbLines = 1;
  //#START CUSTO-SAMVAZ
  vendorValidation = null;
  vendorDataContainer: AbstractDataContainer = null;
  vendorFromModal = false;
  checkVendorFromModal = false;
  isSelected = false;
  //#END CUSTO-SAMVAZ

  private dataService = inject(CompiereDataService);
  private poService = inject(PoService);
  private cacheService = inject(CacheManagerService);
  private notificationService = inject(CompiereNotificationService);


  constructor() {
    super();
    this.docStatus = this.translateService.instant('specificWindow.quickInvoiceVendor.new');
    this.isModal = false;
    this.customDesignArray.push(...getHeaderCustomDesignItems());
  }

  ngOnInit() {
    super.ngOnInit();
    this.initTranslations();
    this.setPlaceHolder();
    this.ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
    this.lineMetaData = getLineCustomDesignItems();
    // INIT
    this.initAD_Data();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: '',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }

  initContainerForThisForm() {
    // Reference and BVR not mandatory without QRCode
    const comp = this.getDatacontainer('SalesRep_ID');
    if (comp) {
      comp.label = this.translateService.instant('specificWindow.quickInvoiceVendor.salesRep');
      comp.data.label = this.translateService.instant('specificWindow.quickInvoiceVendor.salesRep');
      comp.data.poLabel = this.translateService.instant('specificWindow.quickInvoiceVendor.salesRep');
      comp.setFieldMandatory();
    }
  }
  updateMandatory(isFromQR = false, isSCOR = false) {
    // Reference and BVR not mandatory without QRCode
    let comp = this.getDatacontainer('Z_PaymentCommunication');
    if (comp) {
      comp.data.isMandatory = isFromQR;
      comp.setFieldMandatory();
    }
    comp = this.getDatacontainer('XX_BVR');
    if (comp) {
      // 133832 - comp.data.isMandatory = (isFromQR && !isSCOR);
      comp.data.isMandatory = !isFromQR && !isSCOR;
      comp.setFieldMandatory();
    }
  }
  ngAfterViewInit() {
    this.initContainerForThisForm();
    this.updateMandatory();
    this.dataStore.dataChange.subscribe((dataChanged) => {
      Object.keys(dataChanged.dataModified).forEach((columnName) => {
        if (columnName === 'C_Currency_ID') {
          this.notifyFromDataChange({
            data: { columnName: columnName, id: dataChanged.dataModified['C_Currency_ID'].id },
          });
        }
      });
    });
    // setFocused for scan
    this.inputInfoArea.nativeElement.focus();
    const ctx = this.getCurrentContext();
    if (ctx['C_Currency_ID']) {
      this.setCurrencySymbol(ctx['C_Currency_ID']);
    } else if (ctx['$C_Currency_ID']) {
      this.setCurrencySymbol(ctx['$C_Currency_ID']);
    }
    //#START CUSTO-SAMVAZ
    this.vendorDataContainer = this.getDatacontainer('C_BPartner_ID');
    this.vendorValidation = this.vendorDataContainer && this.vendorDataContainer.data && this.vendorDataContainer.data.validationCode ? this.vendorDataContainer.data.validationCode : null;
    //#END CUSTO-SAMVAZ
  }
  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   */
  getCurrentContext(dataStore?: DataStore, dataStoreParent?: DataStore) {
    let specificParent;
    let editViewParent;
    if (this.parentComponent && this.parentComponent.editTabs && this.parentComponent.editTabs[0]) {
      editViewParent = this.parentComponent;
    } else if (this.parentComponent instanceof SpecificWindowUiComponent) {
      specificParent = this.parentComponent;
    }
    /*merge avec le contexte du parent */
    if (dataStoreParent) {
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore.data : this.dataStore.data),
        cloneDeep(dataStoreParent ? dataStoreParent.data : this.dataStore.data)
      );
    }
    if (specificParent) {
      const specificParentContext = specificParent.getCurrentContext();
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore : this.dataStore.data),
        specificParentContext
      );
    } else {
      return EditViewUtils.getCurrentContext(
        editViewParent,
        dataStore ? dataStore : this.dataStore,
        this.connectorService.getIupicsUserContext()
      );
    }
  }
  updateDisplayLogicOnButton(columnName: string, isDisplayed = true) {
    const buttonComponent = <ButtonUiComponent>(
      this.dataContainers.find((datacontainer) => datacontainer.data && datacontainer.data.columnName === columnName)
    );
    if (buttonComponent) {
      buttonComponent.data.displayLogic = isDisplayed ? '1=1' : '1=2';
    }
  }
  initTranslations() {
    this.translations = {
      product: this.translateService.instant('specificWindow.quickInvoiceVendor.product'),
      charge: this.translateService.instant('specificWindow.quickInvoiceVendor.charge'),
      quantity: this.translateService.instant('specificWindow.quickInvoiceVendor.quantity'),
      priceEntered: this.translateService.instant('specificWindow.quickInvoiceVendor.priceEntered'),
      priceList: this.translateService.instant('specificWindow.quickInvoiceVendor.priceList'),
      taxAmt: this.translateService.instant('specificWindow.quickInvoiceVendor.taxAmt'),
      tax: this.translateService.instant('specificWindow.quickInvoiceVendor.tax'),
      totalLine: this.translateService.instant('specificWindow.quickInvoiceVendor.totalLine'),
    };
  }
  setPlaceHolder(isEmpty = false) {
    this.placeHolder = isEmpty
      ? ''
      : this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfoPlaceHolder');
  }

  updateHeader() {
    this.grandTotal = 0;
    this.grandNetTotal = 0;
    this.grandVatTotal = 0;
    this.dataLines.forEach((line) => {
      this.grandTotal =
        this.grandTotal +
        (line.data['LineTotalAmt'] != null && line.data['LineTotalAmt'] != undefined ? line.data['LineTotalAmt'] : 0);
      this.grandNetTotal =
        this.grandNetTotal +
        (line.data['LineNetAmt'] != null && line.data['LineNetAmt'] != undefined ? line.data['LineNetAmt'] : 0);
      this.grandVatTotal =
        this.grandVatTotal +
        (line.data['TaxAmt'] != null && line.data['TaxAmt'] != undefined ? line.data['TaxAmt'] : 0);
    });
  }
  resetlines(nbLines = 1) {
    // Reset lines
    this.dataLines.forEach((dataLine, index) => {
      this.lineMetaData.forEach((metaData) => {
        this[metaData.vcr].toArray()[index].clear();
      });
    });
    this.dataLines = [];
    this.nbLines = nbLines;
    this.dataContainersLine = [];
  }
  resetInvoice() {
    this.isProcessed = false;
    this.updateDisplayLogicOnButton('CreateFrom', true);
    this.resetlines();
    // this.dataStore.dataChange.unsubscribe();
    this.dataStore = this.store.newSpecificWindowData(this.formId);
    // this.dataStore.dataChange.subscribe((dataChanged) => {
    //   Object.keys(dataChanged.dataModified).forEach((columnName) => {
    //     this.notifyFromDataChange({ data: { columnName: columnName } });
    //   });
    // });
    this.dataContainers.forEach((dataContainer) => {
      dataContainer.setNewData(this.dataStore);
    });

    this.amountToPay = 0;
    this.amountToPayInput.inputRef.nativeElement.value = 0;
    this.grandTotal = 0;
    this.grandVatTotal = 0;
    this.grandNetTotal = 0;
    this.nbUploadedFiles = 0;
    this.ibanValue = '';
    const ctx = this.getCurrentContext();
    if (ctx['C_Currency_ID']) {
      this.setCurrencySymbol(ctx['C_Currency_ID']);
    } else if (ctx['$C_Currency_ID']) {
      this.setCurrencySymbol(ctx['$C_Currency_ID']);
    }
    this.clearLogs();
    this.resetInputInfo();
    this.docStatus = this.translateService.instant('specificWindow.quickOrder.new');
  }
  //#region notification
  getUserRoleObs(ad_user_id: number) {
    const ad_client_id =
      this.dataStore.data['AD_Client_ID'] instanceof Object
        ? this.dataStore.data['AD_Client_ID'].id
        : this.dataStore.data['AD_Client_ID'];
    return this.store.getDataGrid(getUserRoleRequest(ad_user_id, ad_client_id, this.ad_language));
  }
  sendNotification() {
    this.updateLoading(true);
    const ctx = this.getCurrentContext();
    //#START CUSTO-SAMVAZ
    const validatorId = ctx['XX_Validator_ID'] instanceof Object ? ctx['XX_Validator_ID'].id : ctx['XX_Validator_ID'];
    const invoiceId = ctx['C_Invoice_ID'] instanceof Object ? ctx['C_Invoice_ID'].id : ctx['C_Invoice_ID'];
    const notifTemplate: CompiereNotification = {
      created: null,
      target: null,
      title: this.getMessage('SZ_VendorInvoiceMsg24'),
      summary: this.getMessage('SZ_VendorInvoiceMsg23'),
      description: '',
      processChannelId: null,
      request_id: null,
      closed: false,
      isError: false,
      priority: CompiereNotificationPriority.MEDIUM,
      type: 'user',
      additionalProperties: { AD_Role_ID: 0, AD_User_ID: validatorId, AD_Org_ID: 0 },
      sourceType: IupicsMenuType.WINDOW,
      sourceTypeId: this.windowID,
      sourceRecordId: invoiceId,
      sourceTableNameTargeted: 'C_Invoice',
      processParams: null,
      zoomInfo: null,
      fileLinks: null,
      data: null,
    };
    this.getUserRoleObs(validatorId)
      .pipe(
        switchMap((userRoleResponse) => {
          const obss = [];
          if (userRoleResponse && userRoleResponse.data) {
            userRoleResponse.data.forEach((roleData) => {
              const roleId = roleData['AD_Role_ID'] instanceof Object ? roleData['AD_Role_ID'].id : roleData['AD_Role_ID'];
              const notifCopied = cloneDeep(notifTemplate);
              notifCopied.additionalProperties.AD_Role_ID = roleId;
              obss.push(this.notificationService.sendNotification(notifCopied));
            });
            if (obss.length === 0) {
              return of([]);
            } else {
              return zip(...obss);
            }
          } else {
            return of([]);
          }
        }),
        switchMap((responses) => {
          return of(responses.length > 0);
        }),
        catchError(() => {
          return of(false);
        })
      )
      .subscribe((success) => {
        // reset loading
        this.updateLoading(false);
        if (success) {
          this.isProcessed = true;
          this.updateDisplayLogicOnButton('CreateFrom', false);
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.getMessage('SZ_VendorInvoiceMsg25'),
              'success'
            )
          );
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('generic.error'), this.getMessage('SZ_VendorInvoiceMsg18'), 'error')
          );
        }
      });
         //#END CUSTO-SAMVAZ
  }
  //#endregion notification
  //#region override
  public updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      if (display.key === 'displayFormUI' && display.value === false && this['isProcessLaunch'] !== false) {
        this.refreshInvoice();
      }
      // to avoid refreshline when we open productview
      this['isProcessLaunch'] = true;
      this.customFormModalBodyCss = undefined;
    }
  }
  processCreateFrom(item: any) {
    // to override button process behaviour
    this.checkMissingField();
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Invoice_ID'];
  }
  /**
   * Overrided to avoid std behaviour of datacontainer in specific window
   */
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {
    //#START CUSTO-SAMVAZ
    if (this.vendorFromModal && dataStore && dataStore.data['C_BPartner_ID'] && this.isSelected && this.checkVendorFromModal) {
      this.isSelected = false;
      this.checkVendorFromModal = false;
      this.setInvoiceInfoFromInputInfo();
    }
    //#END CUSTO-SAMVAZ
  }

  notifyFromDataChange(item: any, vcrIndex?: number) {
    let lineDataStore = null;
    if (vcrIndex !== undefined) {
      lineDataStore = this.dataLines[vcrIndex];
    }

    if (
      ['C_DocTypeTarget_ID', 'C_BPartner_ID', 'M_PriceList_ID', 'DateInvoiced'].includes(item.data['columnName']) &&
      this.dataStore &&
      this.dataStore.data[item.data['columnName']]
    ) {
      this.calloutColumn(item.data['columnName']);
      this.dataContainers.forEach((datacontainer) => {
        if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'DateAcct') {
          datacontainer.updateStore(this.dataStore.data['DateAcct']);
        }
      });
    } else if (item.data['columnName'] === 'M_Product_ID' && lineDataStore && lineDataStore.data['M_Product_ID']) {
      this.calloutProduct(lineDataStore);
    } else if (item.data['columnName'] === 'C_Charge_ID' && lineDataStore && lineDataStore.data['C_Charge_ID']) {
      this.calloutCharge(lineDataStore);
    } else if (item.data['columnName'] === 'QtyEntered' && lineDataStore) {
      this.calloutColumnLine('QtyEntered', lineDataStore);
    } else if (item.data['columnName'] === 'C_Tax_ID' && lineDataStore && lineDataStore.data['C_Tax_ID']) {
      this.calloutColumnLine('C_Tax_ID', lineDataStore);
    } else if (item.data['columnName'] === 'PriceActual' && lineDataStore && lineDataStore.data['PriceActual']) {
      this.calloutColumnLine('PriceActual', lineDataStore);
    } else if (item.data['columnName'] === 'PriceList' && lineDataStore && lineDataStore.data['PriceList']) {
      this.calloutColumnLine('PriceList', lineDataStore);
    } else if (item.data['columnName'] === 'PriceEntered' && lineDataStore && lineDataStore.data['PriceEntered']) {
      this.calloutColumnLine('PriceEntered', lineDataStore);
    } else if (item.data['columnName'] === 'C_Currency_ID') {
      if (item.data.id && item.data.id > 0) {
        this.setCurrencySymbol(item.data.id);
      }
    }
    if (item.data['columnName'] === 'C_Invoice_ID' && this.dataStore.data['C_Invoice_ID'] !== null) {
      this.docStatus = this.translateService.instant('specificWindow.quickOrder.draft');
      // this.newLine(true);
    }

    // if (item.data['columnName'] === 'DateInvoiced' ) {
    this.dataContainers.forEach((datacontainer) => {
      if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'DateAcct') {
        datacontainer.updateStore(this.dataStore.data['DateAcct']);
      }
    });
    // }
    // autosave header
    if (this.MANDATORY_HEADER_COLUMNS.includes(item.data['columnName'])) {
      this.saveInvoice(true);
    }
  }

  //#endregion override
  //#region CALLOUT
  calloutColumn(columnName: string) {
    const value = this.dataStore.data[columnName].id
      ? this.dataStore.data[columnName].id
      : this.dataStore.data[columnName];
    this.store.calloutData(
      '/Field/Callout/C_Invoice/' + columnName,
      {
        columnName: columnName,
        newValue: value,
        windowCtx: this.getCurrentContext(),
      },
      this.dataStore,
      null
    );
  }
  calloutColumnLine(columnName: string, lineDataStore: DataStore) {
    const value =
      lineDataStore.data[columnName] && lineDataStore.data[columnName].id
        ? lineDataStore.data[columnName].id
        : lineDataStore.data[columnName];
    this.store.calloutData(
      '/Field/Callout/C_InvoiceLine/' + columnName,
      {
        columnName: columnName,
        newValue: value,
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore),
      },
      lineDataStore,
      null,
      () => {
        this.saveInvoiceLine(lineDataStore);
      }
    );
  }

  calloutCharge(lineDataStore: DataStore) {
    lineDataStore.data['M_Product_ID'] = null;
    this.store.syncDataChanges(lineDataStore, { M_Product_ID: null });
    this.store.calloutData(
      '/Field/Callout/C_InvoiceLine/C_Charge_ID',
      {
        columnName: 'C_Charge_ID',
        newValue:
          lineDataStore.data['C_Charge_ID'] instanceof Object
            ? lineDataStore.data['C_Charge_ID'].id
            : lineDataStore.data['C_Charge_ID'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore),
      },
      lineDataStore,
      null,
      () => {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }
        const chargeId =
          lineDataStore.data['C_Charge_ID'] && lineDataStore.data['C_Charge_ID'].id
            ? lineDataStore.data['C_Charge_ID'].id
            : lineDataStore.data['C_Charge_ID'];
        this.subscription = this.store.getDataGrid(getChargeDescRequest(chargeId), true).subscribe(({ data }) => {
          this.store.syncDataChanges(lineDataStore, { Description: data[0]['DESCRIPTION'] });
          this.saveInvoiceLine(lineDataStore);
          this.subscription.unsubscribe();
        });
      }
    );
  }
  calloutProduct(lineDataStore: DataStore) {
    lineDataStore.data['C_Charge_ID'] = null;
    this.store.syncDataChanges(lineDataStore, { C_Charge_ID: null });
    const productId =
      lineDataStore.data['M_Product_ID'] && lineDataStore.data['M_Product_ID'].id
        ? lineDataStore.data['M_Product_ID'].id
        : lineDataStore.data['M_Product_ID'];
    this.store.calloutData(
      '/Field/Callout/C_InvoiceLine/M_Product_ID',
      {
        columnName: 'M_Product_ID',
        newValue: productId,
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore),
      },
      lineDataStore,
      null,
      () => {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }
        this.subscription = this.store.getDataGrid(getProductDescRequest(productId), true).subscribe(({ data }) => {
          this.store.syncDataChanges(lineDataStore, { Description: data[0]['DESCRIPTION'] });
          this.saveInvoiceLine(lineDataStore);
          this.subscription.unsubscribe();
        });
      }
    );
  }
  //#endregion CALLOUT
  //#region CRUD
  //#region DocManagement
  cancelDoc() {
    if (this.dataStore && this.dataStore.data && this.dataStore.data['C_Invoice_ID']) {
      this.updateLoading(true);
      const sub$ = this.deleteDoc().subscribe((deleted) => {
        this.updateLoading(false);
        if (deleted) {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.getMessage('SZ_VendorInvoiceMsg19'),
              'success'
            )
          );
          this.resetInvoice();
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.error'),
              this.getMessage('SZ_VendorInvoiceMsg20'),
              'error'
            )
          );
        }
        sub$.unsubscribe();
      });
    } else {
      this.resetInvoice();
    }
  }
  saveDoc() {
    if (
      this.inputInfo.isCorrect &&
      (this.amountToPay + this.marginError < this.grandTotal || this.amountToPay - this.marginError > this.grandTotal)
    ) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.getMessage('SZ_VendorInvoiceMsg21'),
          'warning'
        )
      );
    } 
     //#START CUSTO-SAMVAZ
     else if (!this.getFormStoreData('XX_Validator_ID')) {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('generic.warning'), this.getMessage('SZ_VendorInvoiceMsg22'), 'warning')
      );
    }
    //#END CUSTO-SAMVAZ
   else {
      this.sendNotification();
      return true;
    }
    return false;
  }
  deleteDoc() {
    this.updateLoading(true);
    const invoiceId =
      this.dataStore.data['C_Invoice_ID'] instanceof Object
        ? this.dataStore.data['C_Invoice_ID'].id
        : this.dataStore.data['C_Invoice_ID'];
    const data_UUID_list = [];
    // DELETE INVOICELINE
    this.dataLines.forEach((lineDataStore) => {
      const invoiceLineId =
        lineDataStore.data['C_InvoiceLine_ID'] instanceof Object
          ? lineDataStore.data['C_InvoiceLine_ID'].id
          : lineDataStore.data['C_InvoiceLine_ID'];
      if (invoiceLineId) {
        data_UUID_list.push({ C_InvoiceLine_ID: invoiceLineId });
      }
    });
    let obs = of([]);
    if (data_UUID_list.length > 0) {
      obs = this.dataService.deleteData(this.invoiceLineTabID, data_UUID_list);
    }
    return obs.pipe(
      switchMap((resultLines) => {
        // CHECK ERROR DURING INVOICELINE DELETE
        let hasInvoiceLineError = false;
        if (resultLines) {
          hasInvoiceLineError =
            resultLines.find((resultLine) => resultLine.messages.find((msg) => msg.type !== 'SUCCESS')) !== undefined;
        } else {
          hasInvoiceLineError = true;
        }
        if (resultLines) {
          // CHECK REMOVE DELETED LINES ON UI
          resultLines.forEach((line) => {
            if (line.messages.find((msg) => msg.type === 'SUCCESS')) {
              const index = this.dataLines.findIndex(
                (dataLine) => dataLine.data['C_InvoiceLine_ID'] == line['C_InvoiceLine_ID']
              );
              this.lineMetaData.forEach((metaData) => {
                this[metaData.vcr].toArray()[index].clear();
              });
              for (let i = index + 1; i < this.dataLines.length; i++) {
                this.lineMetaData.forEach((metaData) => {
                  const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
                  (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
                });
              }
              this.dataLines.splice(index, 1);
              this.dataContainersLine.splice(index, 1);
            }
          });
        }
        this.updateHeader();
        if (hasInvoiceLineError) {
          return of(null);
        }
        // GET INVOICETAX
        return this.store.getDataGrid(getInvoiceTaxRequest(invoiceId, this.ad_language)).pipe(
          catchError(() => {
            return of(null);
          })
        );
      }),
      switchMap((results) => {
        // DELETE INVOICETAX
        const data_UUID_list = [];
        results.data.forEach((taxData) => {
          const taxId = taxData['C_Tax_ID'] instanceof Object ? taxData['C_Tax_ID'].id : taxData['C_Tax_ID'];
          data_UUID_list.push({ C_Invoice_ID: invoiceId, C_Tax_ID: taxId });
        });
        if (data_UUID_list.length > 0) {
          return this.dataService.deleteData(this.invoiceTaxTabID, data_UUID_list).pipe(
            catchError(() => {
              return of(null);
            })
          );
        } else {
          return of([]);
        }
      }),
      switchMap((results) => {
        // CHECK ERROR DURING INVOICETAX DELETE
        let hasInvoiceTaxError = false;
        if (results) {
          if (results.length > 0) {
            hasInvoiceTaxError =
              results.find((result) => result.messages.find((msg) => msg.type === 'ERROR')) !== undefined;
          }
        } else {
          hasInvoiceTaxError = true;
        }
        return of(!hasInvoiceTaxError);
      }),
      switchMap((isSuccess) => {
        if (isSuccess) {
          // DELETE INVOICE
          return this.dataService.deleteData(this.invoiceTabID, [{ C_Invoice_ID: invoiceId }]).pipe(
            map((results) => {
              let hasInvoiceError = false;
              if (results) {
                if (results.length > 0) {
                  hasInvoiceError =
                    results.find((result) => result.messages.find((msg) => msg.type !== 'SUCCESS')) !== undefined;
                }
              } else {
                hasInvoiceError = true;
              }
              return of(!hasInvoiceError);
            }),
            catchError(() => {
              return of(false);
            })
          );
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  //#endregion DocManagement
  saveInvoice(isAutoSave = false) {
    if (!this.isInputInfoLoading) {
      const nullValue = getNullValueInData(
        this.dataStore.data,
        this.MANDATORY_HEADER_COLUMNS.filter(
          (col) =>
            (this.inputInfo.isCorrect &&
              this.inputInfo.infoSource === InputInfoSource.QR &&
              col != 'XX_BVR' &&
              (col != 'XX_BVR' || !this.inputInfo.isSCOR)) ||
            (this.inputInfo.isCorrect &&
              this.inputInfo.infoSource === InputInfoSource.BVR &&
              col !== 'Z_PaymentCommunication') ||
            !['XX_BVR', 'Z_PaymentCommunication'].includes(col)
        )
      );
      if (nullValue == null) {
        if (this.dataStore.data['Z_PaymentCommunication']) {
          this.store
            .getDataGrid(
              getInvoiceZPayComRequest(
                this.dataStore.data['C_BPartner_ID'].id,
                this.dataStore.data['Z_PaymentCommunication'],
                this.dataStore.data['C_Invoice_ID'] ? this.dataStore.data['C_Invoice_ID'].id : 0
              ),
              true
            )
            .subscribe((res) => {
              if (res && res.data && res.data.length > 0) {
                const ndate = moment(res.data[0]['DateInvoiced']).format('DD/MM/YYYY');
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translateService.instant('generic.warning'),
                    this.getMessage('SZ_VendorInvoiceMsg31') + ' ' + res.data[0]['DocumentNo'] + ' - ' + ndate,
                    'warning'
                  )
                );
              }
            });
        }

        this.dataStore.data['GrandTotal'] = this.grandTotal;
        this.dataStore.data['TotalLines'] = this.grandTotal;
        if (this.dataStore.data['C_DocType_ID'] !== this.dataStore.data['C_DocTypeTarget_ID']) {
          this.dataStore.data['C_DocType_ID'] = this.dataStore.data['C_DocTypeTarget_ID'];
        }
        const invoiceId =
          this.dataStore.data['C_Invoice_ID'] instanceof Object
            ? this.dataStore.data['C_Invoice_ID'].id
            : this.dataStore.data['C_Invoice_ID'];
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = undefined;
        }
        this.subscription = this.saveInvoiceInStore(this.dataStore, invoiceId).subscribe((value) => {
          if (value && !isAutoSave) {
            if (this.saveDoc()) {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('generic.success'),
                  this.translateService.instant('specificWindow.quickInvoiceVendor.saveSuccess'),
                  'success'
                )
              );
            }
          }
        });
      } else {
        this.checkMissingField(isAutoSave);
      }
    }
  }
  checkMissingField(isAutoSave = false) {
    const nullValue = getNullValueInData(
      this.dataStore.data,
      this.MANDATORY_HEADER_COLUMNS.filter(
        (col) =>
          (this.inputInfo.isCorrect &&
            this.inputInfo.infoSource === InputInfoSource.QR &&
            col != 'XX_BVR' &&
            (col != 'XX_BVR' || !this.inputInfo.isSCOR)) ||
          (this.inputInfo.isCorrect &&
            this.inputInfo.infoSource === InputInfoSource.BVR &&
            !['Z_PaymentCommunication'].includes(col)) ||
          !['XX_BVR', 'Z_PaymentCommunication'].includes(col)
      )
    );
    if (
      [
        'AD_Org_ID',
        'DateInvoiced',
        'M_PriceList_ID',
        'C_DocTypeTarget_ID',
        'C_PaymentTerm_ID',
        'PaymentRule',
        'C_Currency_ID',
        'IsReturnTrx',
        'SalesRep_ID',
      ].includes(nullValue)
    ) {
      this.isSidebarOpen = true;
    }
    if (nullValue && !isAutoSave) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('specificWindow.quickInvoiceVendor.missingParams'),
          'warning'
        )
      );
    }
  }
  saveInvoiceInStore(datastore: DataStore, id?: number, sync = true) {
    const dataReformat = Object.assign({}, datastore.data);
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_Invoice', dataReformat, id).pipe(
      switchMap((result) => {
        // joinfile need dskey to get back oldcurrentstore info during taggedcolumns generation
        const invoiceId = result['C_Invoice_ID'] instanceof Object ? result['C_Invoice_ID'].id : result['C_Invoice_ID'];
        this.dsKey = {
          parentId: 'quickCreateInvoice',
          recordId: `C_Invoice_ID,${invoiceId}`,
          tabId: this.invoiceTabID,
          windowId: this.windowID,
        };
        datastore.key = this.dsKey;
        // Set data_uuid needed for joinfile
        datastore.data['Data_UUID'] = `C_Invoice_ID,${invoiceId}`;
        result['Data_UUID'] = `C_Invoice_ID,${invoiceId}`;
        datastore.key = null;
        if (sync) {
          this.store.syncDataChanges(datastore, result, false, true);
        } else {
          datastore.data = EditViewUtils.mergeCurrentDataDeepCopy(datastore.data, result);
        }
        datastore.key = this.dsKey;
        this.store.copyWindowDataToOldStore(datastore);
        datastore.key = null;
        return of(true);
      }),
      catchError((err) => {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('generic.error'), err.error.message, 'error')
        );
        return of(false);
      })
    );
  }

  getCreatedInvoiceLines() {
    const invoiceId =
      this.dataStore.data['C_Invoice_ID'] instanceof Object
        ? this.dataStore.data['C_Invoice_ID'].id
        : this.dataStore.data['C_Invoice_ID'];
    return this.store.getDataGrid(getInvoiceLineRequest(invoiceId, this.ad_language), true).pipe(
      switchMap((response) => {
        if (response && response.data) {
          this.resetlines(response.data.length + 1);
          setTimeout(() => {
            response.data.forEach((lineData) => {
              const lineDataStore = this.store.newSpecificWindowData(this.formId, this.dataStore);
              lineDataStore.data = lineData;
              this.dataLines.push(lineDataStore);
              this.lineMetaData.forEach((metaData) => {
                const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1, lineDataStore);
                // make autocomplete overlay visible outside overflow
                compRef.instance['isInsideOverflow'] = true;
                if (compRef) {
                  if (metaData.columnName !== 'Description') {
                    compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
                  } else {
                    compRef.location.nativeElement.setAttribute('tabindex', -1);
                  }
                  this.dataContainers.pop();
                  (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
                  (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
                    const item = this.specificData.items.find((specificItem) => {
                      return metaData.type === CustomDesignItemType.FIELD
                        ? metaData.columnName === specificItem.data.columnName
                        : metaData.tableName === specificItem.name;
                    });
                    this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
                  });
                  if (!this.dataContainersLine[this.dataLines.length - 1]) {
                    this.dataContainersLine[this.dataLines.length - 1] = [];
                  }
                  this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

                  (<AbstractDataContainer>compRef.instance).isSetDefaultValue = true;
                  (<AbstractDataContainer>compRef.instance).setNewData(lineDataStore);
                  this.componentRefs.push(compRef);
                }
              });
              this.store.syncDataChanges(lineDataStore, lineData);
            });
            this.updateHeader();
          }, 1000);
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }
  refreshInvoice(onlyHeader = false) {
    this.updateLoading(true);
    const invoiceId =
      this.dataStore.data['C_Invoice_ID'] instanceof Object
        ? this.dataStore.data['C_Invoice_ID'].id
        : this.dataStore.data['C_Invoice_ID'];
    let obs = of(true);
    if (!onlyHeader) {
      obs = this.getCreatedInvoiceLines();
    }
    obs
      .pipe(
        switchMap((getLineSuccess) => {
          if (getLineSuccess) {
            return this.store.getDataGrid(getInvoiceRequest(invoiceId, this.ad_language));
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.error'),
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.invoiceLineNotFound'),
                'error'
              )
            );
            return of(null);
          }
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((invoiceData) => {
        this.updateLoading(false);
        if (invoiceData) {
          this.dataStore.data = invoiceData.data[0];
          this.store.syncDataChanges(this.dataStore, invoiceData.data[0], false, true);
          if (onlyHeader) {
            this.updateHeader();
          }
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.error'),
              this.translateService.instant('specificWindow.quickInvoiceVendor.log.invoiceNotFound'),
              'error'
            )
          );
        }
      });
  }
  saveInvoiceLineInStore(datastore: DataStore, id?: number) {
    const tmpStore = new DataStore();
    tmpStore.data = this.getCurrentContext();
    const dataReformat = Object.assign({}, this.getCurrentContext(datastore, tmpStore));
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_InvoiceLine', dataReformat, id);
  }
  saveInvoiceLine(lineDataStore: DataStore) {
    const isNew = lineDataStore.data['C_InvoiceLine_ID'] == null;
    if (
      (lineDataStore.data['M_Product_ID'] instanceof Object || lineDataStore.data['C_Charge_ID'] instanceof Object) &&
      lineDataStore.data['QtyEntered'] != null &&
      (isNew || (lineDataStore.data['PriceEntered'] != null && lineDataStore.data['C_Tax_ID'] != null))
    ) {
      const id =
        lineDataStore.data['C_InvoiceLine_ID'] instanceof Object
          ? lineDataStore.data['C_InvoiceLine_ID'].id
          : lineDataStore.data['C_InvoiceLine_ID'];
      const sub$ = this.saveInvoiceLineInStore(lineDataStore, id).subscribe((result) => {
        // if new we should use callout again because record_id is needed
        lineDataStore.data['C_InvoiceLine_ID'] = result['C_InvoiceLine_ID'];
        if (isNew) {
          if (lineDataStore.data['M_Product_ID'] instanceof Object) {
            this.calloutProduct(lineDataStore);
          } else {
            this.calloutCharge(lineDataStore);
          }
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.success'),
              this.translateService.instant('specificWindow.quickInvoiceVendor.saveLineSuccess'),
              'success'
            )
          );
        } else {
          this.store.syncDataChanges(lineDataStore, result, false, true);
        }
        this.updateHeader();
        sub$.unsubscribe();
      });
    }
  }
  /**
   * Permet d'ajouter visuellement une nouvelle ligne de commande + création d'un nouveau datastore
   */
  newLine() {
    if (
      this.dataStore &&
      this.dataStore.data &&
      this.dataStore.data['C_Invoice_ID'] &&
      this.dataStore.data['DocStatus'] &&
      this.dataStore.data['DocStatus'] !== 'CO' &&
      this.dataStore.data['DocStatus'].id !== 'CO' &&
      !this.isProcessed &&
      !this.dataLines.find((line) => !line.data['C_InvoiceLine_ID'])
    ) {
      this.nbLines++;
      this.dataLines.push(this.store.newSpecificWindowData(this.formId, this.dataStore));
      this.dataLines[this.dataLines.length - 1].data['Line'] = this.dataLines.length * 10;

      this.lineMetaData.forEach((metaData) => {
        const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1);
        // make autocomplete overlay visible outside overflow
        compRef.instance['isInsideOverflow'] = true;
        if (compRef) {
          if (metaData.columnName !== 'Description') {
            compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
          } else {
            compRef.location.nativeElement.setAttribute('tabindex', -1);
          }
          this.dataContainers.pop();
          (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
          (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
            const item = this.specificData.items.find((specificItem) => {
              return metaData.type === CustomDesignItemType.FIELD
                ? metaData.columnName === specificItem.data.columnName
                : metaData.tableName === specificItem.name;
            });
            this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
          });
          if (!this.dataContainersLine[this.dataLines.length - 1]) {
            this.dataContainersLine[this.dataLines.length - 1] = [];
          }
          this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

          (<AbstractDataContainer>compRef.instance).setNewData(this.dataLines[this.dataLines.length - 1]);
          this.componentRefs.push(compRef);
        }
      });
      this.gotoBottomOfLines();
    } else {
      this.checkMissingField();
    }
  }
  private gotoBottomOfLines() {
    setTimeout(() => {
      this.linesContainer.nativeElement.scrollTop =
        this.linesContainer.nativeElement.scrollHeight - this.linesContainer.nativeElement.clientHeight;
    }, 100);
  }
  removeLine(lineDataStore: DataStore, index: number) {
    if (this.dataStore && this.dataStore.data['DocStatus'] && !this.isProcessed) {
      const id =
        lineDataStore.data['C_InvoiceLine_ID'] instanceof Object
          ? lineDataStore.data['C_InvoiceLine_ID'].id
          : lineDataStore.data['C_InvoiceLine_ID'];
      let obs = of(null);
      if (id) {
        obs = this.store.deleteWindowSpecificData('C_InvoiceLine', lineDataStore, id);
      }
      this.subscriptions.push(
        obs.subscribe((result) => {
          this.lineMetaData.forEach((metaData) => {
            this[metaData.vcr].toArray()[index].clear();
          });
          for (let i = index + 1; i < this.dataLines.length; i++) {
            this.lineMetaData.forEach((metaData) => {
              const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
              (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
            });
          }
          this.dataLines.splice(index, 1);
          this.dataContainersLine.splice(index, 1);
          this.nbLines--;
          if (result) {
            this.updateHeader();
          }
        })
      );
    }
  }

  createPartnerLocation(locationData: any): Observable<boolean> {
    if (!locationData) {
      this.pushQuickInvoiceLog(
        this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
        false
      );
      return of(false);
    }
    const partnerLocationReformat = {
      AD_Client_ID: this.dataStore.data['AD_Client_ID'],
      AD_Org_ID: this.dataStore.data['AD_Org_ID'],
      C_BPartner_ID: this.dataStore.data['C_BPartner_ID'],
      Z_TransportMode: null,
      Z_INCOTERMS_ID: null,
      IsRemitTo: 'Y',
      C_SalesRegion_ID: null,
      C_Location_ID: locationData.C_Location_ID,
      IsActive: 'Y',
      IsPayFrom: 'Y',
      IsShipTo: 'Y',
      Name: this.inputInfo.getCompleteAddress(),
      IsBillTo: 'Y',
      Phone2: null,
      Phone: null,
      ISDN: null,
      Fax: null,
      Z_UnloadingPlace: null,
    };
    Object.keys(partnerLocationReformat).forEach((key) => {
      if (partnerLocationReformat[key] instanceof Object) {
        partnerLocationReformat[key] = partnerLocationReformat[key].id;
      }
    });
    return this.poService.save('C_BPartner_Location', partnerLocationReformat).pipe(
      tap({
        next: (result) => {
          const component = this.getDatacontainer('C_BPartner_Location_ID');
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg02');
          component.dataChange(result['C_BPartner_Location_ID']);
          return of(true);
        },
        error: () => {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
            false
          );
          return of(false);
        },
      })
    );
  }
  createAddress(): Observable<boolean> {
    return this.getCountryInfoObs().pipe(
      switchMap((result) => {
        if (result) {
          if (result.NAME) {
            this.inputInfo.countryName = result.NAME;
          }
          const locationReformat = {
            IsActive: 'Y',
            Address2: this.inputInfo.address2,
            C_Country_ID: result.C_COUNTRY_ID,
            Address1: this.inputInfo.address1,
            City: this.inputInfo.city,
            Postal_Add: null,
            C_City_ID: null,
            RegionName: null,
            C_Region_ID: null,
            AD_Client_ID: this.dataStore.data['AD_Client_ID'],
            AD_Org_ID: this.dataStore.data['AD_Org_ID'],
            Postal: this.inputInfo.postalCode,
          };
          Object.keys(locationReformat).forEach((key) => {
            if (locationReformat[key] instanceof Object) {
              locationReformat[key] = locationReformat[key].id;
            }
          });
          return this.poService.save('C_Location', locationReformat).pipe(
            switchMap((result) => {
              return this.createPartnerLocation(result);
            }),
            catchError(() => {
              this.pushQuickInvoiceLog(
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
                false
              );
              return of(false);
            })
          );
        } else {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.log.createAddressError'),
            false
          );
          return of(false);
        }
      })
    );
  }
  createBankAccount(partnerId): Observable<boolean> {
    const dataReformat = {
      AD_Client_ID: this.dataStore.data['AD_Client_ID'],
      AD_Org_ID: this.dataStore.data['AD_Org_ID'],
      C_BPartner_ID: partnerId,
      IsActive: 'Y',
      IsACH: 'Y',
      BPBankAcctUse: 'B',
      A_Name: this.inputInfo.vendorName,
      A_Zip: this.inputInfo.postalCode,
      A_CountryCode: this.inputInfo.countryCode,
      A_Country: this.inputInfo.countryCode,
      A_City: this.dataStore.data['AD_Client_ID'],
      ISO_Code: this.dataStore.data['C_Currency_ID']
        ? this.dataStore.data['C_Currency_ID'].displayValue
          ? this.dataStore.data['C_Currency_ID'].displayValue
          : this.dataStore.data['C_Currency_ID']
        : this.currency,
      Z_OEurope: 'N',
    };
    dataReformat[this.inputInfo.QRIBAN !== null && this.inputInfo.QRIBAN !== undefined ? 'QR_IBAN' : 'IBAN'] =
      this.ibanValue;
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.store.getDataGrid(getCountryDataRequestByIBAN(this.ibanValue.substring(0, 2), this.ad_language)).pipe(
      switchMap((resCountry) => {
        if (resCountry && resCountry.data && resCountry.data[0]) {
          dataReformat['C_Country_ID'] = resCountry.data[0]['C_COUNTRY_ID'];
        }
        return this.poService.save('C_BP_BankAccount', dataReformat).pipe(
          tap({
            next: (result) => {
              this.setDatacontainerValue('C_BP_BankAccount_ID', result['C_BP_BankAccount_ID']);
              if (this.inputInfo.QRIBAN !== null && this.inputInfo.QRIBAN !== undefined) {
                this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg28', false);
              } else {
                this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg06');
              }
              return of(true);
            },
            error: () => {
              this.pushQuickInvoiceLog(
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.createBankError'),
                false
              );
              return of(false);
            },
          })
        );
      })
    );
  }

  //#endregion CRUD
  //#region LOGS

  private gotoBottomOfLogs() {
    setTimeout(() => {
      this.logsContainer.nativeElement.scrollTop =
        this.logsContainer.nativeElement.scrollHeight - this.logsContainer.nativeElement.clientHeight;
    }, 100);
  }
  clearLogs() {
    this.logs = [];
  }

  getMessage(value: string) {
    const msgFound = this.messages.find((msg) => msg.Value === value);
    let msg = value;
    if (msgFound) {
      msg = msgFound.MsgText;
    }
    return msg;
  }
  pushQuickInvoiceLog(value: string, isSuccess = true, type: 'msg' | 'title' = 'msg') {
    if (this.inputInfo && type === 'msg' && !isSuccess) {
      this.inputInfo.logicalWarnings++;
    }
    const msgFound = this.getMessage(value);
    this.logs.push(new QuickInvoiceLog(msgFound, type, isSuccess));
    this.gotoBottomOfLogs();
  }
  //#endregion LOGS
  //#region InputInfo
  resetInputInfo() {
    this.inputInfoString = '';
    this.inputInfoStringLast = '';
    this.inputInfo = new InputInfo([]);
    this.updateMandatory();
    //#START CUSTO-SAMVAZ
    this.vendorFromModal = false;
    //#END CUSTO-SAMVAZ
  }
  checkInputInfo(): boolean {
    if (!this.inputInfo.isCorrect || this.inputInfo.errors.length > 0) {
      this.pushQuickInvoiceLog(
        this.translateService.instant('specificWindow.quickInvoiceVendor.log.missingMandatoryParams'),
        false,
        'title'
      );

      let errorMessage = '';
      this.inputInfo.errors.forEach(
        (err) =>
          (errorMessage +=
            this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.buildError.' + err) + ', ')
      );
      this.pushQuickInvoiceLog(errorMessage.substring(0, errorMessage.length - 2), false, 'msg');
      return false;
    }
    return true;
  }
  setInputInfo() {
    if (this.inputInfoString != null && this.inputInfoString.trim().length > 0) {
      const inputInfoStringBackup = this.inputInfoString;
      this.resetInvoice();
      this.inputInfoString = inputInfoStringBackup;
      this.inputInfo = new InputInfo(this.inputInfoString.split('\n'));
      if (this.checkInputInfo()) {
        this.updateMandatory(this.inputInfo.infoSource === InputInfoSource.QR, this.inputInfo.isSCOR);
        this.setInvoiceInfoFromInputInfo();
        this.inputInfoStringLast = this.inputInfoString;
      } else {
        this.inputInfo.logicalErrors++;
      }
    } else {
      this.inputInfoString = this.inputInfoStringLast;
    }
  }
  clearInputArea() {
    this.clearLogs();
    this.resetInputInfo();
  }
  setInvoiceInfoFromInputInfo() {
    // remove validation tmp
    const component = this.getDatacontainer('C_BP_BankAccount_ID');
    const validation = component.data.validationCode;
    component.data.validationCode = null;
    this.isInputInfoLoading = true;
    //#START CUSTO-SAMVAZ
    let obsToStart = !this.vendorFromModal ? this.setPartnerObs.bind(this) : null;
    if (this.inputInfo.infoSource === InputInfoSource.BVR || this.vendorFromModal) {
      obsToStart = this.setPartnerFromBVRObs.bind(this);
    }
    this.subscriptions.push(
      // SEARCH IBAN AND VENDOR
      obsToStart()
        .pipe(
          switchMap((canContinue: boolean) => {
            if (canContinue) {
              if (this.inputInfo.infoSource === InputInfoSource.QR && !this.vendorFromModal) {
                // SEARCH ADDRESS
                //#END CUSTO-SAMVAZ
                this.pushQuickInvoiceLog(
                  this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchAddress'),
                  true,
                  'title'
                );
                return this.searchAddressObs();
              } else {
                return of(true);
              }
            } else {
              this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg03', false);
              return of(false);
            }
          }),
          switchMap((addressFound: boolean) => {
            if (!addressFound) {
              this.setDatacontainerValue('C_BPartner_Location_ID', null);
            }
            // SEARCH CURRENCY
            return this.setCurrencyObs();
          })
        )
        .subscribe((canContinue) => {
          component.data.validationCode = validation;
          if (canContinue) {
            let obs = of(null);
            // SEARCH AMOUNTOPAY
            if (this.setAmountToPay()) {
              // SEARCH ORDER
              obs = this.setOrderObs();
            }
            this.subscriptions.push(
              obs.subscribe(() => {
                // Check QR BRV/Vendor ref
                if (this.inputInfo.warningQRR) {
                  this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg30', false);
                } else {
                  // SEARCH BVR
                  this.setBVR();
                  // SEARCH REF
                  this.setRef();
                }
                this.isInputInfoLoading = false;
                this.saveInvoice();
              })
            );
          } else {
            this.isInputInfoLoading = false;
            this.saveInvoice();
          }
        })
    );
  }
  //#endregion InputInfo
  //#region QR INFO CALL
  getCountryInfoObs() {
    return this.store.getDataGrid(getCountryDataRequest(this.inputInfo, this.ad_language), true).pipe(
      switchMap((response: any) => {
        // SEARCH COUNTRY USING ISO
        if (response && response.data && response.data[0]) {
          return of(response.data[0]);
        } else {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.log.countryNotExist'),
            false
          );
          return of(null);
        }
      }),
      catchError((err) => {
        this.pushQuickInvoiceLog(
          this.translateService.instant('specificWindow.quickInvoiceVendor.log.countryNotExist'),
          false
        );
        return of(null);
      })
    );
  }
  setPartnerFromBVRObs(): Observable<boolean> {
    const component = this.getDatacontainer('C_BPartner_ID');
    let obs;
    // SEARCH IBAN
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchIban'),
      true,
      'title'
    );
    if (this.inputInfo.bankAccountId && this.inputInfo.bankAccountId.length > 0) {
      // SEARCH VENDOR USING IBAN
      obs = this.store.getDataGrid(getBankRequest(this.inputInfo, this.ad_language), true);
    } else {
      return of(false);
    }

    return obs.pipe(
      switchMap((response: any) => {//#START CUSTO-SAMVAZ
        // SEARCH VENDOR USING IBAN
        let bankDataToUse;
        if (response && response.data && response.data[0] && response.data[0]['C_BPARTNER_ID']) {
          if (response.data.length > 1) {
            const rep = [];
            response.data.forEach(data => {
              if (data['C_BPARTNER_ID'] === this.dataStore.data['C_BPartner_ID'].id) {
                rep.push(data);
              }
            });
            if (rep.length > 1 && this.inputInfo.xxReference) {
              bankDataToUse = this.findBankUsingRef(rep);
            } else {
              bankDataToUse = rep[0];
            }
          } else {
            bankDataToUse = response.data[0];
          }
        }
        if (component && bankDataToUse) {
          this.ibanValue = bankDataToUse['IBAN'] ? bankDataToUse['IBAN'] : bankDataToUse['QR_IBAN'];
          this.pushQuickInvoiceLog(this.ibanValue);
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg04');
          setTimeout(() => {
            if (this.dataStore && this.dataStore.data) {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchVendor'),
            true,
            'title'
          );
          if (this.dataStore.data['C_BPartner_ID'] ) {
                this.pushQuickInvoiceLog(this.dataStore.data['C_BPartner_ID'].displayValue);
                this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
              }
              this.pushQuickInvoiceLog(
                this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchAddress'),
                true,
                'title'
              );
              if (this.dataStore.data['C_BPartner_Location_ID'] ) {
                this.pushQuickInvoiceLog(this.dataStore.data['C_BPartner_Location_ID'].displayValue);
                this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg01');
              }
            }
          }, 2000);
          this.setDatacontainerValue('C_BP_BankAccount_ID', bankDataToUse['C_BP_BANKACCOUNT_ID']);
          component.dataChange(bankDataToUse['C_BPARTNER_ID']);
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
        } else {
          if (response.data.length > 1) {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg27', false);
          } else {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg26', false);
          }
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchVendor'),
            true,
            'title'
          );
        }
        return of(bankDataToUse != undefined);
      })
      //#END CUSTO-SAMVAZ
    );
  }
  findBankUsingRef(accountList: any[]) {
    let bankDataToUse;
    const refToSearch = this.inputInfo.xxReference ? this.inputInfo.xxReference : null;
    const fullRefToSearch = this.inputInfo.bvr ? this.inputInfo.bvr : null;
    if (refToSearch != null) {
      // recherche via le bvr complet en commencant par la référence de base càd les 7 premiers caractères
      let i = 5;
      while (!bankDataToUse && i <= 27) {
        const filteredList = accountList.filter((cbData) => cbData['XX_REFERENCE'] === fullRefToSearch.substring(0, i));
        if (filteredList.length === 1) {
          bankDataToUse = filteredList[0];
        }
        i++;
      }
    }
    return bankDataToUse;
  }
  setPartnerObs(): Observable<boolean> {
    const component = this.getDatacontainer('C_BPartner_ID');
    let obs;
    // SEARCH IBAN
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchIban'),
      true,
      'title'
    );
    this.pushQuickInvoiceLog(this.inputInfo.IBAN);
    if (
      (this.inputInfo.IBAN && this.inputInfo.IBAN.length > 0) ||
      (this.inputInfo.QRIBAN && this.inputInfo.QRIBAN.length > 0)
    ) {
      this.ibanValue = this.inputInfo.IBAN ? this.inputInfo.IBAN : this.inputInfo.QRIBAN;
      // SEARCH VENDOR USING IBAN
      obs = this.store.getDataGrid(getBankRequest(this.inputInfo, this.ad_language), true);
    } else {
      this.pushQuickInvoiceLog(
        this.translateService.instant('specificWindow.quickInvoiceVendor.log.ibanNotFound'),
        false,
        'msg'
      );
      return of(false);
    }

    return obs.pipe(
      switchMap((response: any) => {
        // SEARCH VENDOR USING IBAN
        if (response && response.data && response.data[0] && response.data[0]['C_BPARTNER_ID']) {
          if (response.data.length > 1) {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg27', false);
          } else {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg04');
          }
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchVendor'),
            true,
            'title'
          );
          this.pushQuickInvoiceLog(this.inputInfo.vendorName);
          if (response.data.length > 1) {
            return of(false);
          }
          if (component) {
            this.ibanValue = response.data[0]['IBAN'];
            this.setDatacontainerValue('C_BP_BankAccount_ID', response.data[0]['C_BP_BANKACCOUNT_ID']);
            component.dataChange(response.data[0]['C_BPARTNER_ID']);
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
          }
          return of(true);
        } else {
          return this.store.getDataGrid(getPartnerRequest(this.inputInfo, this.ad_language), true).pipe(
            switchMap((response2: any) => {
              // SEARCH VENDOR USING HIS NAME
              if (response2 && response2.data && response2.data[0] && response2.data[0]['C_BPartner_ID']) {
                component.dataChange(response2.data[0]['C_BPartner_ID']);
                this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
                // CREATE BANKACCOUNT
                return this.createBankAccount(response2.data[0]['C_BPartner_ID']);
              } else {
                this.pushQuickInvoiceLog(
                  this.translateService.instant('specificWindow.quickInvoiceVendor.log.vendorNotFound'),
                  false
                );
                return of(false);
              }
            }),
            catchError((err) => {
              this.pushQuickInvoiceLog(
                this.translateService.instant('specificWindow.quickInvoiceVendor.log.vendorNotFound'),
                false
              );
              return of(false);
            })
          );
        }
      })
    );
  }
  searchAddressObs(): Observable<boolean> {
    // check whether or not callout from partner is done
    this.pushQuickInvoiceLog(this.inputInfo.getCompleteAddress());
    if (this.dataStore.calloutStates.size > 0) {
      return this.dataStore.calloutStackEmptied.pipe(
        switchMap(() => {
          return this.setAddressObs();
        })
      );
    } else {
      return this.setAddressObs();
    }
  }
  setAddressObs(): Observable<any> {
    return this.store.getDataGrid(getPartnerLocationRequest(this.inputInfo), true).pipe(
      switchMap((response: any) => {
        if (response && response.data && response.data[0] && response.data[0]['C_BPARTNER_LOCATION_ID']) {
          this.setDatacontainerValue('C_BPartner_Location_ID', response.data[0]['C_BPARTNER_LOCATION_ID']);
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg01');
        } else {
          return this.createAddress();
        }
        return of(true);
      })
    );
  }
  setCurrencyObs(): Observable<any> {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchCurrency'),
      true,
      'title'
    );
    if (this.inputInfo.currency && this.inputInfo.currency.length > 0) {
      this.pushQuickInvoiceLog(this.inputInfo.currency);
      return this.store.getDataGrid(getCurrencyRequest(this.inputInfo, this.ad_language), true).pipe(
        switchMap((response: any) => {
          if (response && response.data && response.data[0] && response.data[0]['C_Currency_ID']) {
            this.setDatacontainerValue('C_Currency_ID', response.data[0]['C_Currency_ID']);
            this.setCurrencySymbol(response.data[0]['C_Currency_ID']);
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg07');
            return of(true);
          } else {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg08', false);
            return of(false);
          }
        })
      );
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg08', false);
      return of(false);
    }
  }
  setOrderObs(): Observable<any> {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchOrder'),
      true,
      'title'
    );
    return this.store
      .getDataGrid(getOrderRequest(this.inputInfo, this.dataStore.data, this.ad_language, this.marginError), true)
      .pipe(
        switchMap((response: any) => {
          if (response && response.data && response.data[0] && response.data[0]['C_Order_ID']) {
            const component = this.getDatacontainer('C_Order_ID');
            if (component) {
              component.dataChange(response.data[0]['C_Order_ID']);
              if (response.data[0]['DocumentNo']) {
                this.pushQuickInvoiceLog(response.data[0]['DocumentNo']);
              }
              this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg11');
            }
            return of(true);
          } else {
            this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg12', false);
            return of(false);
          }
        })
      );
  }
  setCurrencySymbol(currencyId: number) {
    const component = this.getDatacontainer('C_Currency_ID');
    if (component) {
      // if (this.cacheService.getCacheValueById(CacheName.CURRENCY, currencyId)) {
      //   this.currency = this.cacheService.getCacheValueById(CacheName.CURRENCY, currencyId)['CurSymbol'];
      // } else {
      this.cacheService.getCurrencySymbol({ tableName: TableName.CURRENCY, id: currencyId }).subscribe((res: any) => {
        if (res) {
          this.currency = res;
        }
      });
      // }
    }
  }
  setAmountToPay(): boolean {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchAmountToPay'),
      true,
      'title'
    );
    if (this.inputInfo.amountToPay != null && this.inputInfo.amountToPay !== undefined) {
      this.amountToPay = this.inputInfo.amountToPay;
      this.amountToPayInput.inputRef.nativeElement.value = this.inputInfo.amountToPay;
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg09');
      this.pushQuickInvoiceLog(this.inputInfo.amountToPay + '');
      return true;
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg10', false);
      return false;
    }
  }
  setBVR(): boolean {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchBVR'),
      true,
      'title'
    );
    if (this.inputInfo.bvr && this.inputInfo.bvr.length > 0) {
      this.pushQuickInvoiceLog(this.inputInfo.bvr);
      this.setDatacontainerValue('XX_BVR', this.inputInfo.bvr);
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg13');
      return true;
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg14', false);
      return false;
    }
  }
  setRef(): boolean {
    this.pushQuickInvoiceLog(
      this.translateService.instant('specificWindow.quickInvoiceVendor.inputInfo.searchVendorRef'),
      true,
      'title'
    );
    if (this.inputInfo.vendorRef && this.inputInfo.vendorRef.length > 0) {
      this.pushQuickInvoiceLog(this.inputInfo.vendorRef);
      this.setDatacontainerValue('Z_PaymentCommunication', this.inputInfo.vendorRef);
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg15');
      return true;
    }
    if (this.inputInfo.warningvendorRefQr) {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg29', false);
    } else {
      this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg16', false);
      return false;
    }
  }
  //#endregion SETTING QR INFO CALL
  //#region AD DATA
  initAD_Data() {
    this.tableID = 318;
    this.invoiceTabID = 290;
    this.invoiceLineTabID = 291;
    this.invoiceTaxTabID = 292;
    this.windowID = 183;
    this.subscriptions.push(
      this.store.getDataGrid(getLogMessageRequest(this.ad_language), true).subscribe((results) => {
        this.messages = results.data as any[];
        const msg = this.getMessage('SZ_VendorInvoiceSetting01');
        this.marginError = msg ? (msg.replace ? parseFloat(msg.replace(',', '.')) / 100 : 0.05) : 0.05;
      })
    );
    this.subscriptions.push(
      this.store.getDataGrid(getTabDataRequest(), true).subscribe((response) => {
        if (!this.data) {
          this.data = {};
        }
        if (response && response.data) {
          const invoiceLineTab = response.data.find((tab) => tab['TABLENAME'] === 'C_InvoiceLine');
          const invoiceTab = response.data.find((tab) => tab['TABLENAME'] === 'C_Invoice');
          const invoiceTaxTab = response.data.find((tab) => tab['TABLENAME'] === 'C_InvoiceTax');
          if (invoiceLineTab) {
            this.invoiceLineTabID = invoiceLineTab['AD_TAB_ID'];
          }
          if (invoiceTaxTab) {
            this.invoiceTaxTabID = invoiceTaxTab['AD_TAB_ID'];
          }
          if (invoiceTab) {
            this.data.docFilters = invoiceTab['DOCFILTERS'];
            this.data.taggedColumns = invoiceTab['TAGGEDCOLUMNS'];
            this.tableID = invoiceTab['AD_TABLE_ID'];
            this.invoiceTabID = invoiceTab['AD_TAB_ID'];
            this.windowID = invoiceTab['AD_WINDOW_ID'];
          }
        }
      })
    );
  }
  //#endregion AD DATA
  // #START CUSTO-SAMVAZ
  searchVendorUsingName(component: any): Observable<boolean> {
    return this.store.getDataGrid(getPartnerRequest(this.inputInfo, this.ad_language), true).pipe(
      switchMap((response2: any) => {
        // SEARCH VENDOR USING HIS NAME
        if (response2 && response2.data && response2.data[0] && response2.data[0]['C_BPartner_ID']) {
          component.dataChange(response2.data[0]['C_BPartner_ID']);
          this.pushQuickInvoiceLog('SZ_VendorInvoiceMsg05');
          // CREATE BANKACCOUNT
          return this.createBankAccount(response2.data[0]['C_BPartner_ID']);
        } else {
          this.pushQuickInvoiceLog(
            this.translateService.instant('specificWindow.quickInvoiceVendor.log.vendorNotFound'),
            false
          );
          return of(false);
        }
      }),
      catchError((err) => {
        this.pushQuickInvoiceLog(
          this.translateService.instant('specificWindow.quickInvoiceVendor.log.vendorNotFound'),
          false
        );
        return of(false);
      })
    );
  }
  vendorSearchPanel (response: any) {
    this.vendorFromModal = true;
    if (this.vendorDataContainer && this.vendorDataContainer.data) {
      this.checkVendorFromModal = true;
      let ibanVendorVal = this.vendorDataContainer.data.validationCode ? ' AND C_BPartner.C_BPartner_ID IN ( ' : ' C_BPartner.C_BPartner_ID IN ( ';
      for (let i = 0; i < response.data.length; i++) {
        ibanVendorVal = ibanVendorVal.concat(
        response.data[i]['C_BPARTNER_ID'],
        i < (response.data.length - 1) ? ' , ' :  ' ) '
        );
      }
      if (ibanVendorVal) {
        this.vendorDataContainer.data.validationCode  = this.vendorDataContainer.data.validationCode ? this.vendorDataContainer.data.validationCode.concat(ibanVendorVal) : ibanVendorVal;
      }
      (<AutocompleteUiComponent>this.vendorDataContainer).showSearchPanel();
    }
  }

  closeSearchModal() {
    if (this.vendorDataContainer && this.vendorDataContainer.data) {
      this.vendorDataContainer.data.validationCode = this.vendorValidation;
    }

    if (this.vendorFromModal && this.checkVendorFromModal) {
      this.isSelected = true;
    }
 }
 // #END CUSTO-SAMVAZ

}
//#region Model
class QuickInvoiceLog {
  value: string;
  type: 'title' | 'msg';
  isSuccess: boolean;
  constructor(value: string, type: 'title' | 'msg' = 'msg', isSuccess = true) {
    this.value = value;
    this.type = type;
    this.isSuccess = isSuccess;
  }
}
class ADMessage {
  Value: string;
  MsgText: string;
  constructor(value: string, msgText: string) {
    this.Value = value;
    this.MsgText = msgText;
  }
  //#endregion Model
}
