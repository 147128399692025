<!-- #START CUSTO-SAMVAZ -->
<div
[ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
[attr.data-cy-specific-window-name]="name">
@if (!isModal && isLoading) {
  <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
}

<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="fields">
    <iu-prime-fieldset
    legend="{{ 'generic.infos' | translate }}"
    [toggleable]="true"
    [collapsed]="true"
  >
  <ng-template #comments></ng-template>
  </iu-prime-fieldset>
    <div class="top-container-InvoiceMatching">
      <div class="p-grid p-col-12"><ng-template #top /></div>
    </div>
    <div class="bot-container-InvoiceMatching">
      <div class="p-grid p-col-12"><ng-template #matchFrom /></div>
      <div
        [ngClass]="[
          !dataStore.data['MatchFrom'] || !dataStore.data['MatchTo'] || !dataStore.data['MatchMode']
            ? 'isHidden'
            : '',
          'p-grid p-col-12'
        ]">
        <ng-template #matchTest />
      </div>
      <div class="p-grid p-col-12"><ng-template #matchTo /></div>
      <div class="p-grid p-col-12"><ng-template #bot /></div>
      <div class="p-grid p-col-12"><ng-template #arrayTmp /></div>
      <div class="p-grid p-col-12">
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="toReconcile"
          [isReadOnly]="true"
          label="{{ toReconcileLabel | translate }}" />
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="lettering"
          [isReadOnly]="true"
          label="{{ letteringLabel | translate }}" />
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="difference"
          [isReadOnly]="true"
          label="{{ differenceLabel | translate }}" />
      </div>
      <ng-template iuDynamicContainer />
    </div>
  </div>
  <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
    <ng-template #vcrButtons />

    @if (isModal) {
      <iu-button-ui
        class="p-col-12 p-md-3 p-lg-1"
        btnType="alert"
        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban" />
    }
  </div>
</p-scrollPanel>
</div>
<ng-container *ngIf="displaySearch">
<iu-modal-ui
  data-cy="search-panel"
  [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
  [title]="this.searchLinkedComponent.autoComplete.label"
  [hasCloseBtn]="true"
  (closeModalEmitter)="displaySearch = false">
  <div class="autocomplete-universal-filter">
    <iu-universal-filter-ui
      [tabId]="-1"
      [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
      [gridTab]="gridTab"></iu-universal-filter-ui>
  </div>
  <iu-grid-tab-infinity-scroll-ui
    #gridTab
    (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
    [data]="this.searchLinkedComponent.autoComplete.data"
    [container]="this"
    [isSearch]="true"
    [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"></iu-grid-tab-infinity-scroll-ui>
</iu-modal-ui>
</ng-container>
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
<iu-modal-ui
  [title]="specificWindowTitle"
  [isModalDisplay]="displayFormUI"
  (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
  <ng-template #vcrSpecific />
</iu-modal-ui>
</div>
