import { Component, inject } from '@angular/core';
import { SKWTranslatePipe } from '@iupics-components/specific/window/storekeeper-window-ui/pipes/storekeeper-window-translate.pipe';
import { SKWTransferService } from '@iupics-components/specific/window/storekeeper-window-ui/services/storekeeper-window-transfer.service';
import { StorekeeperWindowPanelComponent } from '../../storekeeper-window-panel.component';

@Component({
  selector: 'iu-storekeeper-window-pallet-panel',
  standalone: true,
  imports: [StorekeeperWindowPanelComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-window-pallet-panel.component.html',
  styleUrl: './storekeeper-window-pallet-panel.component.scss'
})
export class StorekeeperWindowPalletPanelComponent {
  #SKWTransferService = inject(SKWTransferService);

  context = this.#SKWTransferService.palletPanelCtx;
  isPalletPanelOpen = this.#SKWTransferService.isPalletPanelOpen;

  cancelNewPallet(event: MouseEvent) {
    this.context.set(undefined);
    this.isPalletPanelOpen.set(false);
  }

  createPallet(event: MouseEvent) {
    // TODO: implement
    console.warn('Not implemented yet !');
  }
}
