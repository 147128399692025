import { Component, ElementRef, HostBinding, inject, model, signal, viewChild } from '@angular/core';
import CheckboxUiComponent from '@iupics-components/standard/fields/checkbox-ui/checkbox-ui.component';
import { SKWFilter } from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { StorekeeperWindowPanelComponent } from '../storekeeper-window-panel/storekeeper-window-panel.component';
import { SKWIsFilterActivePipe } from './storekeeper-filter-active.pipe';

@Component({
  selector: 'iu-storekeeper-filter-panel',
  standalone: true,
  imports: [StorekeeperWindowPanelComponent, CheckboxUiComponent, SKWIsFilterActivePipe, SKWTranslatePipe],
  templateUrl: './storekeeper-filter-panel.component.html',
  styleUrl: './storekeeper-filter-panel.component.scss'
})
export class StorekeeperFilterPanelComponent {
  #SKWService = inject(SKWContextService);

  panel = viewChild<ElementRef>('panel');

  @HostBinding('style.--_panel-height')
  get _panelHeight() {
    return `${this.panel()?.nativeElement?.getBoundingClientRect()?.height}px`;
  }

  isOpen = model(false);

  availableFilters = signal<SKWFilter[]>([
    { label: 'FabricationOrder', value: "isOF='Y'" },
    { label: 'PurchaseReceipt', value: "isRA='Y'" },
    { label: 'TransferPallet', value: "isPalette='Y'" },
    { label: 'Shipping', value: "isEX='Y'" },
    { label: 'Manual', value: "isManual='Y'" },
    { label: 'Conditioning', value: "isCond='Y'" }
  ]);
  filters = signal<SKWFilter[] | undefined>(this.#SKWService.filters());

  filterChange(filter: SKWFilter, add: boolean) {
    this.filters.update((filters) => {
      if (add) {
        return [...(filters ?? []), filter];
      } else {
        const data = filters?.filter((f) => f !== filter);
        return data?.length > 0 ? [...data] : undefined;
      }
    });
  }

  apply(event: MouseEvent) {
    this.#SKWService.updateFilters(this.filters());
    this.toggle(event, false);
  }

  erase(event: MouseEvent) {
    this.filters.set([]);
  }

  toggle(event: MouseEvent, isOpen?: boolean) {
    if (this.isOpen() === isOpen) return;
    this.isOpen.update((v) => isOpen ?? !v);
  }
}
