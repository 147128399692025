//#START CUSTO-SAMVAZ
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NgClass } from '@angular/common';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { SidebarModule } from 'primeng/sidebar';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';

@Component({
selector: 'iu-print-zone',
templateUrl: './print-zone.component.html',
styleUrls: ['./print-zone.component.scss'],
standalone: true,
imports: [
  ProgressBarModule,
  ScrollPanelModule,
  AutocompleteUiComponent,
  SidebarModule,
  ModalUiComponent,
  // UniversalFilterUiComponent,
  // GridTabInfinityScrollUiComponent,
  NgClass,
  TranslateModule,
],
})
export class PrintZoneComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {
@ViewChild('top', { read: ViewContainerRef, static: true })
vcrTop: ViewContainerRef;

/* Constructor */
constructor() {
  super();
}
ngOnInit(): void {
  this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
    specificWindow.items.forEach((item) => {
      if (item.data.columnName === 'XX_PrintZone_ID') {
        const fieldSize = 'p-col-12  p-md-6 p-md-offset-3';
        this.customDesignArray.push({
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'XX_PrintZone_ID',
          cssClass: fieldSize
        });
      } else if (item.data.columnName === 'Processing') {
        const fieldSize = 'p-col-12 p-md-3 p-lg-2';
        this.customDesignArray.push({
          vcr: 'vcrButtons',
          type: CustomDesignItemType.FIELD,
          columnName: 'Processing',
          cssClass: fieldSize,
          btnType: 'primary'
        });
      }
    });
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  });
  this.componentRef.location.nativeElement.parentElement.style.overflow = 'unset';
}

processProcessing(item) {
  this.sourceComponent.runWFPrintZone(this.dataStore.data['XX_PrintZone_ID']);
  this.closeModalEmitter.emit();
}

close() {
  this.closeModalEmitter.emit();
}
}
