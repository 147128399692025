import { Component, inject, signal } from '@angular/core';
import { SKWTransferHeaderData } from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { SKWTransferService } from '../../../services/storekeeper-window-transfer.service';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';

@Component({
  selector: 'iu-storekeeper-transfer-details',
  standalone: true,
  imports: [StorekeeperInputComponent, StorekeeperButtonComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-transfer-details.component.html',
  styleUrl: './storekeeper-transfer-details.component.scss'
})
export class StorekeeperTransferDetailsComponent {
  #SKWNavigationService = inject(SKWNavigationService);
  SKWTransferService = inject(SKWTransferService);

  canAddPalette = signal(false);

  data = this.SKWTransferService.data;

  lines = this.SKWTransferService.linesDisplayed;

  isConditioning = this.SKWTransferService.isConditioning;

  isNewTransfer = this.SKWTransferService.isNewTransfer;

  addPalette = signal(false);

  changeValue(key: keyof SKWTransferHeaderData, newValue: unknown) {
    if (this.isNewTransfer()) {
      this.data.update((d) => ({ ...d, [key]: newValue }));
    }
  }

  toggleAddPalette(event: MouseEvent) {
    this.addPalette.update((v) => !v);
  }
}
