<!-- #region header -->
<div class="iu-email-editor-header">
  <!-- #START CUSTO-SAMVAZ -->
  <iu-input-text-ui
  [cssClass]="'p-col-12 iu-email-editor-header-field'"
  [label]="'email.sender' | translate"
  [(fieldValue)]="fromUnit"
  [columnName]="'email-from'"
  [isReadOnly]="true"
></iu-input-text-ui>
<!-- #END CUSTO-SAMVAZ -->
<iu-input-chips-ui
  [cssClass]="'p-col-12 iu-email-editor-header-field'"
  [label]="'email.destination' | translate"
  [addOnSpace]="true"
  [addOnBlur]="true"
  [editOnBackspace]="true"
  [(fieldValue)]="to"
  [matchPattern]="regexMail"
  [columnName]="'email-destination'"
  [inputStyle]="{ minWidth: '200px', background: 'var(--email-editor-bg-color-header-field)' }"
  [inContainer]="true" />
@if (!displayCC || !displayCCI) {
  <div class="iu-email-editor-header-display-cc-cci">
    @if (!displayCC) {
      <a href="#" (click)="$event.preventDefault(); displayCC = true" data-cy="email-add-cc">
        {{ 'email.addCC' | translate }}
      </a>
    } @else {
      <a href="#" (click)="$event.preventDefault(); displayCCI = true" data-cy="email-add-cci">
        {{ 'email.addCCI' | translate }}
      </a>
    }
  </div>
}

@if (displayCC) {
  <iu-input-chips-ui
    [cssClass]="'p-col-12 iu-email-editor-header-field'"
    [label]="'CC'"
    [addOnSpace]="true"
    [addOnBlur]="true"
    [editOnBackspace]="true"
    [(fieldValue)]="cc"
    [matchPattern]="regexMail"
    [columnName]="'email-cc'"
    [inputStyle]="{ minWidth: '200px', background: 'var(--email-editor-bg-color-header-field)' }"
    [inContainer]="true" />
}

@if (displayCCI) {
  <iu-input-chips-ui
    [cssClass]="'p-col-12 iu-email-editor-header-field'"
    [label]="'CCI'"
    [addOnSpace]="true"
    [addOnBlur]="true"
    [editOnBackspace]="true"
    [(fieldValue)]="cci"
    [matchPattern]="regexMail"
    [columnName]="'email-cci'"
    [inputStyle]="{ minWidth: '200px', background: 'var(--email-editor-bg-color-header-field)' }"
    [inContainer]="true" />
}
<iu-input-text-ui
  [cssClass]="'p-col-12 iu-email-editor-header-field'"
  [label]="'email.subject' | translate"
  [(fieldValue)]="subject"
  [columnName]="'email-subject'" />
</div>
<!-- #endregion -->
<!-- #region body -->
<div class="iu-email-editor-body">
<div #quillHeader>
  @if (!isMobile) {
    <!-- desktop -->
    <div class="iu-email-editor-body-header">
      <ng-container [ngTemplateOutlet]="headerAndFont" />

      <ng-container [ngTemplateOutlet]="others" />
    </div>
  } @else {
    <!-- mobile -->
    <div class="iu-email-editor-mobile">
      <div class="iu-email-editor-mobile-menu-display" (click)="displayMobileMenu = !displayMobileMenu">
        Menu<span
          [ngClass]="['fa', displayMobileMenu ? 'fa-chevron-down' : 'fa-chevron-right']"
          style="margin-left: 5px"></span>
      </div>
      <div [ngStyle]="{ display: displayMobileMenu ? 'flex' : 'none' }" class="iu-email-editor-mobile-menu">
        <div class="iu-email-editor-mobile-menu-fieldset">
          <ng-container [ngTemplateOutlet]="headerAndFont" />
        </div>
        <div class="iu-email-editor-mobile-menu-fieldset">
          <ng-container [ngTemplateOutlet]="others" />
        </div>
      </div>
    </div>
  }
</div>
<div #quillEditor [ngStyle]="style" data-cy="email-body"></div>
</div>
<!-- #endregion -->
<!-- #region footer -->
<div class="iu-email-editor-footer">
<div class="iu-email-editor-footer-attachement">
  <iu-input-file-ui
    [data]="{ isParam: false, columnName: 'email-fileupload' }"
    [styleClass]="'iu-email-editor-fileupload'"
    [multiple]="true"
    [displayButtons]="false"
    [displayFileUploaded]="true"
    [auto]="true"
    [type]="'EMAIL_ATTACHEMENT'"
    [mustGet]="false"
    [isLoaderActive]="isLoaderActive"
    [uploadedFiles]="uploadedFiles"
    [uploadedFileList_title]="'email.attachedFilesTitle'"
    [uploadedFileList_noFiles]="'email.noAttachedFiles'"
    [uploadingFileList_title]="'email.attachFiles'" />
</div>
<div class="iu-email-editor-footer-template">
  @if (templates$ | async; as templates) {
    <ng-container>
      <iu-autocomplete-ui
        #emailModelAutocomplete
        [cssClass]="'p-col-12 iu-email-editor-header-field'"
        tooltip="{{ 'email.emailModels' | translate }}"
        label="{{ 'email.emailModels' | translate }}"
        limitSuggestions="3"
        (autoCompleteEmitter)="selectTemplate($event.model)"
        [data]="templates"
        [columnName]="'email-templates'"
        [(fieldValue)]="defaultEmailModel" />
    </ng-container>
  }
</div>
<div class="iu-email-editor-footer-buttons">
  <button class="p-button p-button-secondary" (click)="cancelEmailEmitter.emit()">
    {{ 'email.cancel' | translate }}
  </button>
  <button
    class="p-button p-button-primary"
    [disabled]="isLoaderActive"
    (click)="sendEmail($event)"
    data-cy="email-send-button">
    {{ 'email.sendEmail' | translate }}
  </button>
</div>
</div>
<!-- #endregion -->

<!-- #region Templates -->
<ng-template #headerAndFont>
<select class="ql-header">
  <option value="1">{{ 'email.header.heading' | translate }}</option>
  <option value="2">{{ 'email.header.subheading' | translate }}</option>
  <option selected>{{ 'email.header.normal' | translate }}</option>
</select>
<select class="ql-font">
  <option selected>Sans Serif</option>
  <option value="serif">Serif</option>
  <option value="monospace">Monospace</option>
  <option value="calibri">Calibri</option>
  <option value="arial">Arial</option>
  <option value="times-new-roman">Times new roman</option>
</select>
</ng-template>

<ng-template #others>
<button
  class="ql-bold"
  [attr.aria-label]="'email.font.weight.bold' | translate"
  [title]="'email.font.weight.bold' | translate">
  {{ 'email.font.weight.bold' | translate }}
</button>
<button
  class="ql-italic"
  [attr.aria-label]="'email.font.weight.italic' | translate"
  [title]="'email.font.weight.italic' | translate">
  {{ 'email.font.weight.italic' | translate }}
</button>
<button
  class="ql-underline"
  [attr.aria-label]="'email.font.weight.underline' | translate"
  [title]="'email.font.weight.underline' | translate">
  {{ 'email.font.weight.underline' | translate }}
</button>
<select
  class="ql-color"
  [attr.aria-label]="'email.color.foreground' | translate"
  [title]="'email.color.foreground' | translate"></select>
<select
  class="ql-background"
  [attr.aria-label]="'email.color.background' | translate"
  [title]="'email.color.background' | translate"></select>
<button
  class="ql-list"
  value="ordered"
  [attr.aria-label]="'email.list.ordered' | translate"
  [title]="'email.list.ordered' | translate"></button>
<button
  class="ql-list"
  value="bullet"
  [attr.aria-label]="'email.list.unordered' | translate"
  [title]="'email.list.unordered' | translate"></button>
<select class="ql-align" [attr.aria-label]="'email.alignment' | translate" [title]="'email.alignment' | translate">
  <option selected></option>
  <option value="center"></option>
  <option value="right"></option>
  <option value="justify"></option>
</select>
<button
  class="ql-link"
  [attr.aria-label]="'email.insert.link' | translate"
  [title]="'email.insert.link' | translate"></button>
<button
  class="ql-image"
  [attr.aria-label]="'email.insert.image' | translate"
  [title]="'email.insert.image' | translate"></button>
<button
  class="ql-code-block"
  [attr.aria-label]="'email.insert.code' | translate"
  [title]="'email.insert.code' | translate"></button>
<button
  class="ql-clean"
  [attr.aria-label]="'email.removeStyles' | translate"
  [title]="'email.removeStyles' | translate"></button>
</ng-template>

<!-- #endregion -->
