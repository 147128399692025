@if (tabActive() === 'tasks') {
  <div class="tabs-bar">
    <div class="tab" [attr.data-active]="statusTabActive() === 'AS'" (click)="changeListDisplayTasksDone($event, 'AS')">
      {{ 'tasksInProgress' | SKWTranslate }}
    </div>
    <div class="tab" [attr.data-active]="statusTabActive() === 'CO'" (click)="changeListDisplayTasksDone($event, 'CO')">
      {{ 'tasksFinished' | SKWTranslate }}
    </div>
  </div>
}

<div class="list">
  @if (state.data() | SKWListFilter: tabActive() : statusTabActive(); as list) {
    <cdk-virtual-scroll-viewport itemSize="112" [minBufferPx]="900" [maxBufferPx]="900" class="list-content">
      <iu-storekeeper-window-card *cdkVirtualFor="let entry of list; trackBy: trackByFn.bind(this)" [data]="entry" />
      @if (state.pending() === 'get_more') {
        <iu-storekeeper-loading
          [text]="(page() === 'tasks' ? 'LoadingAdditionalTasks' : 'LoadingAdditionalTransfers') | SKWTranslate"
        />
      }
    </cdk-virtual-scroll-viewport>
  } @else if (!state.loaded()) {
    <iu-storekeeper-loading
      [text]="(page() === 'tasks' ? 'LoadingYourCurrentTasks' : 'LoadingTransfersInProgress') | SKWTranslate"
    />
  } @else {
    <iu-storekeeper-empty-data
      [icon]="
        tabActive() === 'tasks'
          ? statusTabActive() === 'AS'
            ? 'icon-clipboard-check'
            : 'fa-solid fa-ghost'
          : 'icon-snooze-zzz-icon'
      "
      [text]="
        (tabActive() === 'tasks' ? (statusTabActive() === 'AS' ? 'NoTaskInProgress' : 'NoTaskFinished') : 'NoTransferAvailable')
          | SKWTranslate
      "
    />
  }
</div>

<iu-storekeeper-navbar [canExpand]="true">
  <iu-storekeeper-navbar-button
    expanded
    [icon]="'icon-warehouse'"
    [label]="'XX_Inventory' | SKWTranslate"
    [active]="page() === 'inventory'"
    (onClick)="changePage('inventory')"
  />
  <iu-storekeeper-navbar-button
    expanded
    [icon]="'icon-truck-fast'"
    [label]="'Transfer' | SKWTranslate"
    (onClick)="goToNewTransfer()"
  />
  <iu-storekeeper-navbar-button
    [icon]="'icon-clipboard-check'"
    [label]="'Tasks' | SKWTranslate"
    [active]="tabActive() === 'tasks'"
    [count]="state.data()?.tasks?.AS?.length"
    [hideCount]="!state.loaded() || !state?.data()?.tasks?.AS || state?.data()?.tasks?.AS?.length === 0"
    (onClick)="changeTabActive($event, 'tasks')"
  />
  <iu-storekeeper-navbar-button
    [icon]="'icon-right-left'"
    [label]="'Transfers' | SKWTranslate"
    [active]="tabActive() === 'transfers'"
    [count]="state.data()?.transfers?.length"
    [hideCount]="!state.loaded() || !state.data()?.transfers || state.data()?.transfers?.length === 0"
    (onClick)="changeTabActive($event, 'transfers')"
  />
</iu-storekeeper-navbar>
