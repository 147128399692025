<iu-storekeeper-window-panel
  [(isOpen)]="isPalletPanelOpen"
  [cancelButtonLabel]="'Cancel' | SKWTranslate"
  [confirmButtonLabel]="'GeneratePallet' | SKWTranslate"
  (cancel)="cancelNewPallet($event)"
  (confirm)="createPallet($event)"
>
  <span title>{{ 'GeneratePallet' | SKWTranslate }}</span>
  <span content>{{ 'GeneratePalletMsg' | SKWTranslate }}</span>
</iu-storekeeper-window-panel>
