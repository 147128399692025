import { DecimalPipe, NgClass, NgIf, formatNumber } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { CompierePrecisionData } from '@compiere-ws/models/compiere-precision-json';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { TableName } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsDataField, NumberType } from '@iupics-manager/models/iupics-data';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { zip } from 'rxjs';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';
import InputChipsUiComponent from '../input-chips-ui/input-chips-ui.component';

@Component({
  selector: 'iu-input-number-ui',
  templateUrl: './input-number-ui.component.html',
  styleUrls: ['./input-number-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TooltipModule,
    NgIf,
    NgClass,
    FormsModule,
    InputChipsUiComponent,
    PrimeOverlayComponent,
    forwardRef(() => ValuePreferencePanelComponent),
    DecimalPipe,
    TextLimitPipe,
  ],
})
export default class InputNumberUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  #translateService = inject(TranslateService);

  @ViewChild('inputChips', { static: false, read: ElementRef }) inputChipsRef: ElementRef;
  @ViewChild('input', { static: false, read: ElementRef }) inputRef: ElementRef;
  @ViewChild('currencySpan', { static: true }) currencySpanRef: ElementRef;
  @ViewChild('opConflict', { static: true }) opConflict: OverlayPanel;

  @Input() data: IupicsDataField;
  @Input()
  columnName: string;
  @Input() fieldStep: string;
  @Input() hasBtn = true;
  @Input() multiple = false;

  @Output() enterKey = new EventEmitter<KeyboardEvent>();
  @Output() inputBlur = new EventEmitter<FocusEvent>();
  regexNB: RegExp = /^-?[0-9]*(\,{1}|\.{1})?[0-9]*$/;
  btn1Disabled = false;
  btn2Disabled = false;

  dataContainers: AbstractDataContainerCallout;
  min: number;
  max: number;
  isCurrencyDisplay = false;
  lang;
  numberFormat = '1.0-7';
  @Input()
  _amountBasePrecision = -1;
  set amountBasePrecision(value: any) {
    this._amountBasePrecision = value;
    if (value >= 0) {
      if (this.isMoneyField || this.data.numberType === NumberType.QUANTITY) {
        if (value > -1 && this.precision > value) {
          this.numberFormat = `1.${value}-${this.precision}`; //149908
        } else {
          this.numberFormat = `1.${this.precision}-${this.precision}`;
        }
      }
      this.fieldStep = 1 / Math.pow(10, this.precision) + '';
    }
  }

  get amountBasePrecision() {
    return this._amountBasePrecision;
  }
  @Input()
  _precision = -1;
  set precision(value: any) {
    this._precision = value;
    if (value >= 0) {
      if (this.isMoneyField || this.data.numberType === NumberType.QUANTITY) {
        if (this.amountBasePrecision > -1 && this.amountBasePrecision < value) {
          this.numberFormat = `1.${this.amountBasePrecision}-${value}`; //149908
        } else {
          this.numberFormat = `1.${value}-${value}`;
        }
      }
      this.fieldStep = 1 / Math.pow(10, this.precision) + '';
    }
  }

  get precision() {
    return this._precision;
  }
  @Input() overridedCSS: OverridedCSS;
  @Input() placeHolder: string;
  @Input() currency: string;
  @Input()
  set fieldValue(value: any) {
    this._fieldValue = this._parseFloat(value, true);
  }

  get fieldValue() {
    return this._fieldValue;
  }
  decimalPattern;

  ngOnInit() {
    if (this.data && this.data.numberType) {
      if (this.data.numberType === NumberType.AMOUNT) {
        this.isCurrencyDisplay = true;
        this.isMoneyField = true;
      } else if (this.data.numberType === NumberType.INTEGER) {
        this.precision = 0;
      }
    }

    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();

    if (this.label?.endsWith('_From')) {
      this.label = this.#translateService.instant('ranged-value.number.from') + this.label.replace('_From', '');
    } else if (this.label?.endsWith('_To')) {
      this.label = this.#translateService.instant('ranged-value.number.to') + this.label.replace('_To', '');
    }

    this.min = this.data && this.data.min !== null && this.data.min !== undefined ? this.data.min : '';
    this.max = this.data && this.data.max !== null && this.data.max !== undefined ? this.data.max : '';
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
    // #START CUSTO-SAMVAZ
    if (this.lang === 'fr-FR') {
      this.lang = 'de-CH';
    }
    switch (this.lang) {
      case 'fr-FR':
        this.decimalPattern = [',', '.'];
        break;
      case 'en-US':
        this.decimalPattern = ['.'];
        break;
      case 'de-CH':
  this.decimalPattern = ['.'];
        break;
      case 'fr-CH':
        this.decimalPattern = ['.'];
        break;
      default:
        this.decimalPattern = [',', '.'];
        break;
    }
    // #END CUSTO-SAMVAZ
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
  }

  readOnlyNumberComponent() {
    this.btn1Disabled = true;
    this.btn2Disabled = true;
  }
  getNumberPattern(reverse = false) {
    let pattern = reverse ? /[^0-9-.,\s]/g : /[0-9-.,\s]/g;
    switch (this.lang) {
      case 'fr-FR':
        pattern = reverse ? /[^0-9-.,\s]/g : /[0-9-.,\s]/g;
        break;
      case 'en-US':
        pattern = reverse ? /[^0-9-.,]/g : /[0-9-.,]/g;
        break;
      case 'de-CH':
        pattern = reverse ? /[^0-9-.]/g : /[0-9-.']/g;
        break;
        // #START CUSTO-SAMVAZ
      case 'fr-CH':
        pattern = reverse ? /[^0-9-.]/g : /[0-9-.']/g;
        break;
        // #END CUSTO-SAMVAZ
      default:
        pattern = reverse ? /[^0-9-.,\s]/g : /[0-9-.,\s]/g;
        break;
    }
    return pattern;
  }
  isNumber(event: KeyboardEvent) {
    if (this.decimalPattern.includes(event.key)) {
      let decimalok = true;
      this.decimalPattern.forEach((pattern) => {
        if (this.inputRef.nativeElement.value.includes(pattern)) {
          decimalok = false;
        }
      });
      if (!decimalok) {
        return false;
      }
    }
    return event.key.match(this.getNumberPattern()) ? true : false;
  }
  _parseFloat(value: any, fromFieldValue: boolean = false): number {
    if (!this.multiple) {
      if (typeof value == 'string') {
        value = value.replace(/\s+/g, '');
        value = value.replace(this.getNumberPattern(true), '');
      }
      value = value ? new NumberParser(this.lang).parse(value + '') : value;
    }

    let floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : value;
    if (floatValue === null || floatValue === undefined || isNaN(parseFloat(floatValue))) {
      floatValue = this.fieldValue;
    } else {
      if (!this.isStandalone && this.data && this.data.numberType === NumberType.INTEGER) {
        floatValue = parseFloat(floatValue.toFixed());
      } else if (
        typeof floatValue === 'number' &&
        this.data &&
        this.data.numberType !== NumberType.INTEGER &&
        this.data.numberType !== NumberType.FLOAT &&
        this.precision >= 0
      ) {
        floatValue = parseFloat(floatValue.toFixed(this.precision));
      }
      if (!fromFieldValue) {
        this.fieldValue = value;
      }
      if (!this.multiple && this.inputRef != undefined) {
        this.inputRef.nativeElement.value = formatNumber(value, this.lang, this.numberFormat);
      }
      return floatValue;
    }
  }
  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
  decrease() {
    if (this.inputRef) {
      this.inputRef.nativeElement.stepUp();
      this.dataChange(parseFloat(this.inputRef.nativeElement.value));
    }
  }
  increase() {
    if (this.inputRef) {
      this.inputRef.nativeElement.stepDown();
      this.dataChange(parseFloat(this.inputRef.nativeElement.value));
    }
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus();
    }
  }
  dataChange(value) {
    super.dataChange(value);
  }
  OnWheel(e) {
    this.inputRef.nativeElement.blur();
  }
  updateDisplay(dataStored: DataStore, changedColumns?: any) {
    super.updateDisplay(dataStored, changedColumns);
    this.updatePrecision();
  }
  updatePrecision() {
    const result = this.getTableNameId();
    const resultAmountBasePrecision = this.getTableNameId(true); //149908
    if (result.tableName && result.id > 0) {
      const subZip = [];
      subZip.push(this.cacheService.getPrecision(result));
      if (
        this.data.numberType === NumberType.AMOUNT &&
        resultAmountBasePrecision.tableName &&
        resultAmountBasePrecision.id > 0
      ) {
        subZip.push(this.cacheService.getPrecision(resultAmountBasePrecision));
      }
      this.subscriptions.push(
        zip(...subZip).subscribe((precisions: number[]) => {
          this.amountBasePrecision = precisions[1];
          this.precision = precisions[0];
        })
      );
    } else if (this.data.numberType === NumberType.AMOUNT) {
      const currentCtx = this.getCurrentContext();
      if (currentCtx['#StdPrecision']) {
        this.precision = currentCtx['#StdPrecision'];
      }
    }
  }
  getTableNameId(basePrecision: boolean = false): CompierePrecisionData {
    let tableName = null;
    let id = null;
    const currentCtx = this.getCurrentContext();
    if (this.data.numberType === NumberType.QUANTITY && this.dataStored.data['C_UOM_ID']) {
      tableName = TableName.UOM;
      id = this.extractId(this.dataStored.data['C_UOM_ID']);
    } else if (this.data.numberType === NumberType.AMOUNT) {
      if (currentCtx['M_PriceList_Version_ID'] && !basePrecision) {
        tableName = TableName.PRICE_LIST_VERSION;
        id = this.extractId(currentCtx['M_PriceList_Version_ID']);
      } else if (currentCtx['M_PriceList_ID'] && !basePrecision) {
        tableName = TableName.PRICE_LIST;
        id = this.extractId(currentCtx['M_PriceList_ID']);
      } else if (currentCtx['C_Currency_ID']) {
        tableName = TableName.CURRENCY;
        id = this.extractId(currentCtx['C_Currency_ID']);
      } else if (currentCtx['C_AcctSchema_ID']) {
        tableName = TableName.ACCT_SCHEMA;
        id = this.extractId(currentCtx['C_AcctSchema_ID']);
      } else if (currentCtx['$C_Currency_ID']) {
        tableName = TableName.CURRENCY;
        id = this.extractId(currentCtx['$C_Currency_ID']);
      } else if (currentCtx['$C_AcctSchema_ID']) {
        tableName = TableName.ACCT_SCHEMA;
        id = this.extractId(currentCtx['$C_AcctSchema_ID']);
      }
    }

    return { tableName, id };
  }
  updateCurrency(dataToUse?: any) {
    const data = this.getCurrentContext();
    let id;
    let tableName = 'C_Currency';
    const defaultCurrency = this.connectorService.getIupicsUserContext()['$C_Currency_ID'];
    if (
      data.hasOwnProperty('C_Currency_ID') ||
      data.hasOwnProperty('C_AcctSchema_ID') ||
      data.hasOwnProperty('M_PriceList_ID') ||
      (dataToUse &&
        (dataToUse.hasOwnProperty('C_Currency_ID') ||
          dataToUse.hasOwnProperty('C_AcctSchema_ID') ||
          dataToUse.hasOwnProperty('M_PriceList_ID')))
    ) {
      if (data.C_Currency_ID || dataToUse.C_Currency_ID) {
        id = data.C_Currency_ID ? data.C_Currency_ID : dataToUse.C_Currency_ID;
      } else if (data.M_PriceList_ID || dataToUse.M_PriceList_ID) {
        tableName = 'M_PriceList';
        id = data.M_PriceList_ID ? data.M_PriceList_ID : dataToUse.M_PriceList_ID;
      } else if (data.C_AcctSchema_ID || dataToUse.C_AcctSchema_ID) {
        tableName = 'C_AcctSchema';
        id = data.C_AcctSchema_ID ? data.C_AcctSchema_ID : dataToUse.C_AcctSchema_ID;
      }
    } else if (defaultCurrency) {
      id = defaultCurrency;
    }
    if (id) {
      this.subscriptions.push(
        this.cacheService.getCurrencySymbol({ tableName, id: this.extractId(id) }).subscribe((res: any) => {
          if (res) {
            this.currency = cloneDeep(res);
          }
        })
      );
    }
  }
}
class NumberParser {
  private _group;
  private _decimal;
  private _numeral;
  private _index;
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find((d) => d.type === 'group').value}]`, 'g');
    this._decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal').value}]`);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = (d) => index.get(d);
  }
  parse(string) {
    return (string = string
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index))
      ? +string
      : NaN;
  }
}
