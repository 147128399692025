import { ChangeDetectionStrategy, Component, HostBinding, inject, input, signal } from '@angular/core';
import { SKWLineStatus, SKWTransferHeaderData } from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../../services/storekeeper-window-context.service';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';

@Component({
  selector: 'iu-storekeeper-window-card',
  standalone: true,
  imports: [StorekeeperButtonComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-window-card.component.html',
  styleUrl: './storekeeper-window-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorekeeperWindowCardComponent {
  #SKWNavigationService = inject(SKWNavigationService);
  #SKWContextService = inject(SKWContextService);

  state = this.#SKWContextService.state;
  page = this.#SKWNavigationService.page;
  tabActive = this.#SKWNavigationService.listTabActive;
  listStatusTabActive = this.#SKWNavigationService.listStatusTabActive;

  data = input<SKWTransferHeaderData>();

  isAssigning = signal(false);
  isRemoving = signal(false);

  @HostBinding('class.important')
  get isImportant() {
    return this.data().Priority === 'Y';
  }

  goToDetail(event: MouseEvent, tabType: SKWLineStatus) {
    this.#SKWNavigationService.goToDetail(this.data(), tabType);
  }

  assign(event: MouseEvent) {
    if (this.isAssigning()) return;

    this.isAssigning.set(true);
    this.#SKWContextService.assignTransfer(this.data(), (result) => {
      this.isAssigning.set(false);
    });
  }

  remove(event: MouseEvent) {
    if (this.isRemoving()) return;

    this.isRemoving.set(true);
    this.#SKWContextService.removeTask(this.data(), (result) => {
      this.isRemoving.set(false);
    });
  }
}
