//#START CUSTO-SAMVAZ
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import  GridViewUiComponent  from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';  
import { NgClass } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';

@Component({
  selector: 'iu-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export class RelatedProductsComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit  {
  constructor() {
    super();
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Product_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'RelatedProduct Table',
        cssClass: 'p-col-12',
        shouldSelectFirst: true
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Replace',
        btnType: 'primary',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      }
    );
  }
  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  notifyFromGridRefresh(gridView: GridViewUiComponent) {
    if (this.gridViews[0] && this.gridViews[0].GridTabInfinityScrollUiComponent) {
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getRowAtIndex(0).setDataValue("Quantity",this.dataStore.data.QtyEntered);
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.refreshCells() ;
    }
  }
}
