import { NgClass } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import InputSwitchUiComponent from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { AppliedItem } from '@iupics/apiz-grid';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TabViewModule } from 'primeng/tabview';
import AutocompleteEditorComponent from '../editor/autocomplete-editor.component';
import ButtonEditorComponent from '../editor/button-editor.component';
import CalendarEditorComponent from '../editor/calendar-editor.component';
import NumberEditorComponent from '../editor/number-editor.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';

@Component({
  selector: 'iu-accounting-viewer-window-ui',
  templateUrl: './accounting-viewer-window-ui.component.html',
  styleUrls: ['./accounting-viewer-window-ui.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    TabViewModule,
    InputSwitchUiComponent,
    DynamicContainerDirective,
    ModalUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export default class AccountingViewerWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('sortByAutoComplete', { read: AutocompleteUiComponent, static: true })
  sortByAutoComplete: AutocompleteUiComponent;
  @ViewChild('sortByAutoComplete2', { read: AutocompleteUiComponent, static: true })
  sortByAutoComplete2: AutocompleteUiComponent;
  @ViewChild('isExpandedInput', { read: InputSwitchUiComponent }) isExpandedInput: InputSwitchUiComponent;
  @ViewChild('isTotalInput', { read: InputSwitchUiComponent }) isTotalInput: InputSwitchUiComponent;
  @ViewChild('top', { read: ViewContainerRef, static: true }) vcrTop: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true }) vcrBot: ViewContainerRef;
  @ViewChild('search', { read: ViewContainerRef, static: true }) vcrSearch: ViewContainerRef;

  columnNames;
  groupBySelected;
  groupBySelected2;
  frameworkComponents: any;

  constructor() {
    super();
    this.isModal = false;
    // on veut le total de ces colonnes
    this.pinnedColKeys = ['AmtAcctDr', 'AmtAcctCr'];
    this.frameworkComponents = {
      buttonEditor: ButtonEditorComponent,
      autocompleteEditor: AutocompleteEditorComponent,
      calendarEditor: CalendarEditorComponent,
      numberEditor: NumberEditorComponent,
    };
  }

  ngOnInit() {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    let recordId = null;
    let documentType = null;
    let adTableId = null;
    let adOrgId = null;
    let adTableValue = null;
    if (this.parentComponent instanceof EditViewUiComponent) {
      recordId = this.parentComponent.editTabs[0].dataStored.data['Data_UUID'].split(',')[1];
      adOrgId = this.parentComponent.editTabs[0].dataStored.data['AD_Org_ID'];
      adTableId = {
        id: this.parentComponent.editTabs[0].data.AD_Table_ID,
        displayValue: this.parentComponent.editTabs[0].data.label,
      };
      adTableValue = this.parentComponent.editTabs[0].data['label'];
      documentType = { displayValue: adTableValue, id: adTableId };

      this.dataStore.data['AD_Org_ID'] = adOrgId;
      this.dataStore.data['Record_ID'] = recordId;
      this.dataStore.data['DocumentType'] = documentType;
      this.dataStore.data['AD_Table_ID'] = adTableId;
    }
    this.dataStore.data['SelectDocument'] = 'Y';
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      for (const item of specificWindow?.items ?? []) {
        switch (item.component) {
          case 'GridViewUiComponent':
            this.customDesignArray.push({
              vcr: 'vcrBot',
              type: CustomDesignItemType.GRID,
              tableName: item.name,
              cssClass: item.cssClass,
              frameworkComponents: this.frameworkComponents,
            });
            // #START CUSTO-SAMVAZ
          for (let i = 0; i < item.data.columnsTableHeader.length; i++) {
            const header: IupicsTableDataHeader = item.data.columnsTableHeader[i];
            if (header.field === 'DocumentNo') {
              header.cellRenderer = 'zoomCellRenderer';
              header.cellRendererParams = { IsSOTrx: (params: any) => params.data['TYPETRX'] === 'AR'};
            }
          }
          // #END CUSTO-SAMVAZ
            break;
          case 'ButtonUiComponent':
            this.customDesignArray.push({
              vcr: item.data.columnName === 'Search' ? 'vcrSearch' : 'vcrBot',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: 'p-col-12 p-md-4 p-lg-2',
            });
            break;
          default: {
            let vcr = 'vcrTop';
            if (item.data.isOptional) {
              vcr = 'vcrOptional';
            }
            this.customDesignArray.push({
              vcr: vcr,
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: 'p-col-12 p-md-4 p-lg-2',
            });
          }
        }
      }
      this.showSpecificWindow();
    });

    for (const field of this.fields) {
      if (field.data.columnName === 'Account_ID') {
        field.data.label = this.translateService.instant('specificWindow.accountViewer.accountFrom');
      } else if (field.data.columnName === 'Account_To_ID') {
        field.data.label = this.translateService.instant('specificWindow.accountViewer.accountTo');
      }
    }

    for (const col of this.dataContainers) {
      if (col.data.columnName === 'Account_ID') {
        col.data.label = this.translateService.instant('specificWindow.accountViewer.accountFrom');
      } else if (col.data.columnName === 'Account_To_ID') {
        col.data.label = this.translateService.instant('specificWindow.accountViewer.accountTo');
      }
    }
  }

  applySubTotalDebitCredit(event: string) {
    if (!this.gridViews?.[0]) return;

    let valueCols: AppliedItem<'aggregate'>[] = [];
    if (event === 'Y') {
      valueCols = [
        { type: 'aggregate', colId: 'AmtAcctCr', aggFunc: 'sum', field: 'AmtAcctCr', id: 'AmtAcctCr' },
        { type: 'aggregate', colId: 'AmtAcctDr', aggFunc: 'sum', field: 'AmtAcctDr', id: 'AmtAcctDr' },
      ];
    }

    this.gridViews[0].api.updateAppliedItems(valueCols, 'aggregate', true);
  }

  expandAll(event: string) {
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
      if (node.isExpandable) {
        node.setExpanded(event === 'Y');
      }
    });
  }

  notifyFromGridRefresh(_: GridViewUiComponent) {
    if (this.gridViews?.[0]?.GridTabInfinityScrollUiComponent?.grid.api.groups().length === 0) {
      this.isExpandedInput.fieldValue = 'N';
      this.isTotalInput.fieldValue = 'N';
    }
  }
}
