import { Pipe, PipeTransform } from '@angular/core';
import { SKWListData, SKWListTabType, SKWTaskStatus } from '../../models/storekeeper-window.model';

@Pipe({ name: 'SKWListFilter', standalone: true })
export class SKWListFilterPipe implements PipeTransform {
  transform(data: SKWListData, tab: SKWListTabType, taskStatus: SKWTaskStatus) {
    if (tab === SKWListTabType.TRANSFERS) {
      return data?.transfers?.length > 0 ? data.transfers : undefined;
    }

    return data?.tasks?.[taskStatus]?.length > 0 ? data?.tasks?.[taskStatus] : undefined;
  }
}
