<!-- #START CUSTO-SAMVAZ -->
<p-progressBar
  *ngIf="isLoading"
  [mode]="'indeterminate'"
  [value]="0"
  [showValue]="false"
  [styleClass]="'loading-progressbar'"
></p-progressBar>

<div class="iu-export-data-footer">
  <div class="p-col-12 p-md-6">
    <iu-autocomplete-ui
      #printerNameField
      label="{{ 'profile.printerName' | translate }}"
      [isStandalone]="true"
      columnName="Profile-AD_Language"
      [fieldValue]="this.printerName"
      [data]="printerNames"
      [isContextMenuDisabled]="true"
    ></iu-autocomplete-ui>
  </div>
</div>
<div class="iu-export-data-footer-buttons p-col-12 apiz-p-grid">
  <button
        pButton
        class="p-button p-button-alert"
        (click)="cancelPrintDataEmitter.emit()"
        [label]="'print.cancel' | translate"></button>
      <button
        pButton
        class="p-button p-button-primary"
        (click)="print()"
        [label]="'print.exportData' | translate"></button>
</div>
