import { Component, inject, input, Renderer2, signal } from '@angular/core';

@Component({
  selector: 'iu-storekeeper-navbar',
  standalone: true,
  imports: [],
  templateUrl: './storekeeper-navbar.component.html',
  styleUrl: './storekeeper-navbar.component.scss'
})
export class StorekeeperNavbarComponent {
  #renderer = inject(Renderer2);
  #listener!: () => void | undefined;

  canExpand = input.required<boolean>();

  isMenuOpen = signal(false);

  toggleMenu(event: MouseEvent) {
    event.stopPropagation();
    if (!this.isMenuOpen()) {
      this.#listener = this.#renderer.listen('document', 'click', (_) => {
        this.isMenuOpen.set(false);
        this.#listener?.();
      });
    } else {
      this.#listener?.();
    }

    this.isMenuOpen.update((v) => !v);
  }
}
