import { Injectable } from '@angular/core';
import { buffer, debounceTime, fromEvent, map, tap } from 'rxjs';

@Injectable()
export class SKWScannerService {
  private readonly CHAR_FLAG = '~';
  private readonly SCAN_COMPLETE_TIMEOUT = 10; // milliseconds

  #source = fromEvent<KeyboardEvent>(document, 'keypress');

  scanner = this.#source.pipe(
    tap((event) => console.log(event)),
    buffer(this.#source.pipe(debounceTime(this.SCAN_COMPLETE_TIMEOUT))),
    map((events) => {
      let inBetweenFlags = false;
      let currentText = '';
      for (const { key } of events) {
        if (key === this.CHAR_FLAG) {
          inBetweenFlags = !inBetweenFlags;
        } else if (inBetweenFlags) {
          currentText += key;
        }
      }
      return currentText;
    })
    // filter((value) => value.trim().length === 0)
  );

  constructor() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'F9') {
        for (const key of '~A1236003~'.split('')) {
          document.dispatchEvent(
            new KeyboardEvent('keypress', {
              key
            })
          );
        }
      }
    });
  }
}
