//TODO REMOVE AFTER APIZ 6.0.0
import {CdkDropListGroup} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
    CompiereDataGridRequestJSON,
    CompiereDataGridType,
    DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import {KanbanGroup} from '@iupics-components/models/kanban-interface';
import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import {AppConfig} from '@iupics-config/app.config';
import {DataStoreService} from '@iupics-manager/managers/data-store/data-store.service';
import {AbstractDynamicComponent} from '@iupics-manager/models/abstract-dynamic-component';
import {IupicsColumnKanban} from '@iupics-manager/models/iupics-data';
import {IupicsEvent} from '@iupics-manager/models/iupics-event';
import {ApizGridUtils} from '@iupics-util/tools/apiz-grid.utils';
import {ApizGridEvent, ColDataRequest, GridOptionsAppliedItems, injectGridApiService} from '@iupics/apiz-grid';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';
import {Subject} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import GridViewUiComponent from '../../grid-view-ui/grid-view-ui.component';
import {injectViewColumnsService} from '../../services/view-columns.service';
import KanbanBoardUiComponent from '../kanban-board-ui/kanban-board-ui.component';
import {KanbanUtils} from '../utils/kanban.utils';

@Component({
    selector: 'iu-kanban-ui',
    templateUrl: './kanban-ui.component.html',
    styleUrls: ['./kanban-ui.component.scss'],
    standalone: true,
    imports: [CdkDropListGroup, KanbanBoardUiComponent],
})
export default class KanbanUiComponent extends AbstractDynamicComponent implements OnInit {
    private dataStore = inject(DataStoreService);
    private config = inject(AppConfig);
    private translateService = inject(TranslateService);

    #gridApi = injectGridApiService();
    #viewColumnsService = injectViewColumnsService();

    @Input()
    tableName: string;

    @Input()
    datas: any[];

    get request() {
        return ApizGridUtils.cleanDataRequestForCompiereRequest(
            this.#gridApi.getRequest());
    }

    @Input()
    columnsDisplayAD: IupicsColumnKanban[];

    @Input()
    kanbanImageColumn: string;

    @Input()
    initRequest: CompiereDataGridRequestJSON;

    draggedElement: any;
    kanbanGroups: KanbanGroup[] = [];
    prevKanbanGroups: KanbanGroup[] = [];
    prevRowGroupCols: Omit<ColDataRequest, 'colId'>[] = [];

    // #isGetDatagridInProgress = false;

    #dataStoreRequest$ = new Subject<DataStoreRequest>();

    #updateAppliedItems$ = this.#gridApi.appliedItemsUpdated
        .asObservable()
        .pipe(tap((event) => this.#onAppliedItemsUpdated(event)));

    @Output()
    clickEmitter = new EventEmitter<any>();

    constructor() {
        super();
        this.#gridApi.resetDatasource();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.#viewColumnsService.colDefs$.subscribe(),
            this.#getDataObs().subscribe(),
            this.#updateAppliedItems$.subscribe()
        );
        this.#viewColumnsService.setView({
            tabId: this.tabId,
            data: this.data,
            windowType: this.windowType,
            ctx: this.#getCurrentContext(),
            shouldSetColDefs: true,
        });

        if (Object.keys(this.initRequest).length > 0) {
            this.#gridApi.updateAppliedItems(
                ApizGridUtils.appliedItemsFromCompiereRequest(this.initRequest, this.#gridApi, this.#gridApi.columnApi),
                undefined
            );
        } else {
            this.queryDataStore(this.request);
        }
    }

    #onAppliedItemsUpdated(event: ApizGridEvent<GridOptionsAppliedItems>) {
        if (!event.data || (!event.data.filters && !event.data.groups && !event.data.aggregates)) {
            return;
        }

        this.queryDataStore(this.request);
    }

    #getDataObs() {
        return this.#dataStoreRequest$.asObservable().pipe(
            // tap(() => (this.#isGetDatagridInProgress = true)),
            switchMap((request) =>
                this.dataStore.getDataGrid(request, true).pipe(map((response) => ({response, request})))
            ),
            map(({response, request}) => {
                const data = response.data ?? [];
                return {
                    response: {
                        data,
                        isMoreData: response.lastRow <= -1,
                        kanbanGroups: this.#convertDataToDataKanban(
                            data,
                            request,
                            response.lastRow <= -1,
                            request.compiereRequest?.rowGroupCols?.[0]
                        ),
                    },
                    request,
                };
            }),
            tap(({request, response}) => {
                this.datas = response.data;
                this.kanbanGroups = response.kanbanGroups;

                if (
                    this.kanbanGroups.length > 0 &&
                    request.compiereRequest?.rowGroupCols?.[0]?.id &&
                    this.prevRowGroupCols?.[0]?.id &&
                    request.compiereRequest.rowGroupCols[0].id === this.prevRowGroupCols[0].id
                ) {
                    this.kanbanGroups = [
                        ...this.prevKanbanGroups.filter(
                            (prevGroup) => !this.kanbanGroups.find((group) => prevGroup.groupValue.id === group.groupValue.id)
                        ),
                        ...this.kanbanGroups,
                    ];
                }

                this.prevRowGroupCols = this.request.rowGroupCols;
                this.prevKanbanGroups = this.kanbanGroups?.map((group) => ({
                    ...group,
                    datas: [],
                    isMoreData: false
                })) ?? [];
            }),
            tap(({response}) => {
                // this.#isGetDatagridInProgress = false;
                if (response.data) {
                    this.#notifyUrlChange();
                }
            })
        );
    }

    queryDataStore(request?: CompiereDataGridRequestJSON) {
        const dataStoreRequest: DataStoreRequest = {
            windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
            compiereRequest: {
                windowType: CompiereDataGridType.WINDOW,
                entityId: this.tabId,
                startRow: 0,
                endRow: this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize'),
                windowCtx: this.#getCurrentContext(),
                validation: this.#getTabWhereClause(),
            },
        };

        if (request) {
            dataStoreRequest.compiereRequest.filterModel = request?.filterModel;
            dataStoreRequest.compiereRequest.rowGroupCols = request?.rowGroupCols;
            dataStoreRequest.compiereRequest.sortModel = request?.sortModel;
        }

        this.#dataStoreRequest$.next(dataStoreRequest);
    }

    refresh() {
        this.queryDataStore(this.request);
    }

    #convertDataToDataKanban(
        datas: any[],
        dataStoreRequest: DataStoreRequest,
        isMoreData: boolean,
        columnGroup?: {}
    ): KanbanGroup[] {
        let columnGroupName: string;
        if (columnGroup) {
            columnGroupName = columnGroup['field'].replace(/"/g, '');
        }

        const groupsObj = {};
        for (const data of datas) {
            let key = 'default';
            const groupDataStoreRequest = cloneDeep(dataStoreRequest);
            const groupId = data[columnGroupName]?.['id'] ?? data[columnGroupName] ?? undefined;
            if (groupId) {
                groupDataStoreRequest.compiereRequest.groupKeys = [groupId];
            }

            if (data[columnGroupName] !== undefined && data[columnGroupName] !== null) {
                key = data[columnGroupName]?.['id'] ?? data[columnGroupName] ?? 'null';
                if (!groupsObj[key]) {
                    groupsObj[key] = <KanbanGroup>{
                        title: data[columnGroupName]?.hasOwnProperty('id')
                            ? data[columnGroupName]['displayValue']
                            : data[columnGroupName],
                        columnName: columnGroup,
                        groupValue: data[columnGroupName],
                        datas: [],
                        isMoreData,
                        dataStoreRequest: groupDataStoreRequest,
                    };
                }
            } else if (!groupsObj[key] && !columnGroup) {
                groupsObj[key] = <KanbanGroup>{
                    title: this.translateService.instant('kanban.all'),
                    datas: [],
                    isMoreData,
                    dataStoreRequest: groupDataStoreRequest,
                };
            }

            if (!columnGroup) {
                groupsObj[key].datas.push(
                    KanbanUtils.transformDataForKanbanView(data, this.columnsDisplayAD, this.kanbanImageColumn)
                );
            }
        }

        return Object.values(groupsObj);
    }

    /**
     * récupération du contexte complet du composant
     * @param dataStore nouveau datastore à prendre en compte
     */
    #getCurrentContext() {
        return (<GridViewUiComponent>this.DOMParentComponent)?.getCurrentContext() ?? null;
    }

    #getTabWhereClause() {
        return (<GridViewUiComponent>this.DOMParentComponent)?.getTabWhereclause() ?? null;
    }

    #notifyUrlChange() {
        // TODO - AFTER_MIGRATION_APIZ_GRID: je pense qu'on pourrait gérer ça autrement
        if (
            this.DOMParentComponent instanceof GridViewUiComponent &&
            !(this.DOMParentComponent.DOMParentComponent instanceof EditTabUiComponent)
        ) {
            (this.DOMParentComponent.DOMParentComponent as BladeUiComponent).notifyUrlChange();
        }
    }

    onChildUpdate(event): void {
    }

    onSiblingUpdate(event: IupicsEvent) {
    }

    onRemoveComponent(event: IupicsEvent) {
    }
}
