<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="p-col-12 apiz-p-grid">
      <ng-template #vcrTable></ng-template>
    </div>
    <div class="p-col-12 apiz-p-grid">
      <ng-template iuDynamicContainer></ng-template>
    </div>
    <div class="p-col-12 apiz-p-grid">
      <ng-template #vcrButtons></ng-template>
    </div>
    <div class="iu-specific-btn-container apiz-p-grid" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
      <iu-button-ui
        *ngIf="isModal"
        class="p-col-12 p-md-3 p-lg-1"
        btnType="alert"
        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
        label="{{ 'process.cancel' | translate }}"
        icon="fa fa-ban"
      >
      </iu-button-ui>
    </div>
  </p-scrollPanel>
</div>
