//TODO REMOVE AFTER APIZ 6.0.0
import { Component, Input, OnInit, ViewChild, forwardRef, inject } from '@angular/core';
import {
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { ChartType } from '@iupics-components/models/view-type.enum';
import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { ApizGridUtils } from '@iupics-util/tools/apiz-grid.utils';
import {
  AggregateFunction,
  ApizGridEvent,
  AppliedItem,
  ColDataRequest,
  ColDef,
  DataRequest,
  GridOptionsAppliedItems,
  injectGridApiService,
} from '@iupics/apiz-grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { ButtonModule } from 'primeng/button';
import { ChartModule, UIChart } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import AutocompleteUiComponent from '../../fields/autocomplete-ui/autocomplete-ui.component';
import GridViewUiComponent from '../grid-view-ui/grid-view-ui.component';
import { injectViewColumnsService } from '../services/view-columns.service';

@Component({
  selector: 'iu-chart-ui',
  templateUrl: './chart-ui.component.html',
  styleUrls: ['./chart-ui.component.scss'],
  standalone: true,
  imports: [ButtonModule, TooltipModule, forwardRef(() => AutocompleteUiComponent), ChartModule, TranslateModule],
})
export default class ChartUiComponent extends AbstractDynamicComponent implements OnInit {
  #gridApi = injectGridApiService();
  #viewColumnsService = injectViewColumnsService();

  #translateService = inject(TranslateService);
  #store = inject(DataStoreService);
  #themeService = inject(ThemeService);

  #dataStoreRequest$ = new Subject<DataStoreRequest>();

  #updateAppliedItems$ = this.#gridApi.appliedItemsUpdated
    .asObservable()
    .pipe(tap((event) => this.#onAppliedItemsUpdated(event)));

  #updateColumnsDefs$ = this.#gridApi.columnDefsUpdated
    .asObservable()
    .pipe(tap((event) => this.#onColumnsDefsUpdated(event)));
  #updateColumnsDefsSub!: Subscription;

  options;
  chartType: string;
  datas = [];
  dataChart: any;
  @ViewChild('chart', { static: true })
  chart: UIChart;
  columns: ColumnFilter[] = [];
  selectedColumns;
  valueCols: ColDataRequest[];
  operations: { id: string; displayValue: string }[];
  operation = {
    id: 'sum',
    displayValue: 'Somme',
  };
  defaultColumn: ColumnFilter = null;
  tooltipLabels: any;

  @Input()
  initRequest: CompiereDataGridRequestJSON;

  // private isGetDatagridInProgress = false;

  get request() {
    return ApizGridUtils.cleanDataRequestForCompiereRequest(
        this.#gridApi.getRequest());
  }

  constructor() {
    super();
    this.#gridApi.resetDatasource();
    this.#updateColumnsDefsSub = this.#updateColumnsDefs$.subscribe();
    this.subscriptions.push(
      this.#updateColumnsDefsSub,
      this.#updateAppliedItems$.subscribe(),
      this.#newDataStoreRequestObs().subscribe(),
      this.#colsObs().subscribe()
    );
  }

  ngOnInit() {
    this.tooltipLabels = {
      pieChart: this.#translateService.instant('chartUi.pieChart'),
      doughnutChart: this.#translateService.instant('chartUi.doughnutChart'),
      polarChart: this.#translateService.instant('chartUi.polarChart'),
      lineChart: this.#translateService.instant('chartUi.lineChart'),
      areaChart: this.#translateService.instant('chartUi.areaChart'),
      barChart: this.#translateService.instant('chartUi.barChart'),
      columnChart: this.#translateService.instant('chartUi.columnChart'),
      radarChart: this.#translateService.instant('chartUi.radarChart'),
    };

    this.operations = [
      { id: 'sum', displayValue: this.#translateService.instant('gridTools.sum') },
      { id: 'count', displayValue: this.#translateService.instant('gridTools.count') },
    ];

    this.#viewColumnsService.setView({ tabId: this.tabId, data: this.data });
    this.chartType = ChartType.PIE;
  }

  #colsObs() {
    return this.#viewColumnsService.colDefs$.pipe(
      tap(({ columnsData, columns, columnsTableHeader }) => {
        const cols = [];
        for (const colHeader of columnsTableHeader) {
          const field = columns.find(
            (col) => col.fieldEntity.field.ColumnName === (colHeader?.colId ?? colHeader?.field)
          )?.fieldEntity.field;
          if (!field) continue;
          switch (field.AD_Reference_ID) {
            case 11:
            case 12:
            case 22:
            case 29:
            case 37: {
              cols.push({
                displayValue: colHeader.headerName,
                id: field.ColumnName,
                type: field.AD_Reference_ID,
              });
              break;
            }
            default:
              colHeader.aggregate = false;
              break;
          }
        }

        this.#viewColumnsService.data = {
          columns,
          columnsTableHeader: columnsTableHeader,
          columnsData,
          shouldSetColDefs: false,
        };

        this.columns = cols;
        this.#gridApi.updateOptions('columnDefs', columnsTableHeader);
      })
    );
  }

  #newDataStoreRequestObs() {
    return this.#dataStoreRequest$.asObservable().pipe(
      // tap((_) => (this.isGetDatagridInProgress = true)),
      switchMap((request) => this.#store.getDataGrid(request)),
      tap(({ data }) => {
        // this.isGetDatagridInProgress = false;
        this.datas = data;
        this.selectedColumns = this.request.rowGroupCols?.map((rgc) => rgc.field) ?? [];
        this.#transformData();
        this.#notifyUrlChange();
      })
    );
  }

  #onAppliedItemsUpdated(event: ApizGridEvent<GridOptionsAppliedItems>) {
    if (!event.data?.aggregates) {
      return;
    }

    this.#queryDataStore(this.request);
  }

  #onColumnsDefsUpdated(event: ApizGridEvent<ColDef[]>) {
    if (this.columns.length === 0) return;

    this.#updateColumnsDefsSub?.unsubscribe();

    const request = Object.keys(this.initRequest).length === 0 ? this.request : this.initRequest;
    const appliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(request, this.#gridApi, this.#gridApi.columnApi);
    if (appliedItems.aggregates) {
      // We check in existing aggregates if there is one that match the columns and operations available
      // If we find one, we set it as the default column and operation
      // And we update the appliedItems with this aggregate
      const aggregates = [...appliedItems.aggregates];
      appliedItems.aggregates = undefined;
      for (const item of aggregates) {
        const defaultColumn = this.columns.find((column) => column.id === item.colId);
        const operation = this.operations.find((operation) => operation.id === item.aggFunc);
        if (defaultColumn && operation) {
          this.defaultColumn = defaultColumn;
          this.operation = operation;
          appliedItems.aggregates = [item];
          break;
        }
      }
    }
    // No aggregates found, we set the default column and operation
    this.defaultColumn = this.columns.find((c) => c.id === appliedItems.aggregates?.[0].colId) ?? this.columns[0];
    this.operation = this.operations[0];
    appliedItems.aggregates ||= this.#getAggregate();
    this.#gridApi.updateAppliedItems(appliedItems, undefined);
  }

  #queryDataStore(request?: CompiereDataGridRequestJSON) {
    const dataStoreRequest: DataStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent
        ? (<BladeUiComponent>this.container).infoComponent.windowId
        : this.container.windowId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 25,
        windowCtx: this.getCurrentContext(),
        validation: this.getTabWhereClause(),
      },
    };

    if (request) {
      dataStoreRequest.compiereRequest.filterModel = request.filterModel;
      dataStoreRequest.compiereRequest.rowGroupCols = request.rowGroupCols;
      dataStoreRequest.compiereRequest.sortModel = request.sortModel;
      dataStoreRequest.compiereRequest.valueCols = request.valueCols.map((vc) => {
        // TODO - AFTER_MIGRATION_APIZ_GRID: modifier en backend pour juste avoir colId
        delete vc.colId;
        return vc;
      });
    }

    this.#dataStoreRequest$.next(dataStoreRequest);
  }

  refresh() {
    this.#queryDataStore(this.request);
  }

  changeOperationToCompute(operation: { id: string; displayValue: string }) {
    this.operation = operation;
    this.#changeComputation();
  }

  changeColumnToCompute(columnFilter: ColumnFilter) {
    this.defaultColumn = this.columns.find((column) => column.id === columnFilter.id) ?? null;
    this.#changeComputation();
  }

  #changeComputation() {
    if (!this.defaultColumn || !this.operation) return;

    this.#gridApi.updateAppliedItems(this.#getAggregate(), 'aggregate');
  }

  #getAggregate(): AppliedItem<'aggregate'>[] {
    return [
      {
        type: 'aggregate',
        colId: this.defaultColumn.id,
        aggFunc: this.operation.id as AggregateFunction,
        id: this.defaultColumn.id,
      },
    ];
  }

  //#region Chart
  #transformData() {
    const dataset = {};
    if (this.defaultColumn) {
      for (const data of this.datas) {
        if (this.selectedColumns && this.selectedColumns.length > 0) {
          let keys = '';
          for (const columnGroup of this.selectedColumns) {
            if (keys) {
              keys += '&|';
            }
            keys += data[columnGroup] instanceof Object ? data[columnGroup].displayValue : data[columnGroup];
          }

          if (data[this.defaultColumn.id] !== undefined && data[this.defaultColumn.id] !== null) {
            dataset[keys] = data[this.defaultColumn.id];
          }
        } else {
          const key = this.operation.id + '(' + this.defaultColumn.displayValue + ')';
          if (this.operation.id === 'sum') {
            if (!dataset[key]) {
              dataset[key] = data[this.defaultColumn.id];
            } else {
              dataset[key] += data[this.defaultColumn.id];
            }
          } else if (!dataset[key]) {
            dataset[key] = 1;
          } else {
            dataset[key]++;
          }
        }
      }
    }
    this.#createDataChart(dataset);
  }

  #createDataChart(dataset: {}) {
    const globalLabels = [];
    const globalLabelKeys = {};
    const datasets = [];
    const datasetsKey = {};
    const data = [];
    if (
      this.chartType === ChartType.LINE ||
      this.chartType === ChartType.BAR ||
      this.chartType === ChartType.COLUMN ||
      this.chartType === ChartType.AREA ||
      this.chartType === ChartType.RADAR
    ) {
      for (const labels in dataset) {
        const groupLevel = labels.split(/\&\|/g);
        if (groupLevel.length > 0) {
          if (globalLabelKeys[groupLevel[0]] === undefined) {
            const index = globalLabels.push(groupLevel[0]);
            globalLabelKeys[groupLevel[0]] = index - 1;
          }

          if (groupLevel.length > 1) {
            let dataSetLabel = '';
            for (let j = 1; j < groupLevel.length; j++) {
              if (j > 1) {
                dataSetLabel += ' - ';
              }
              dataSetLabel += groupLevel[j];
            }

            if (datasetsKey[dataSetLabel] === undefined) {
              const globalLabelsPresent = {};
              globalLabelsPresent[groupLevel[0]] = dataset[labels];
              const index = datasets.push({
                label: dataSetLabel,
                globalLabelsPresent: globalLabelsPresent,
                data: [],
                fill: this.chartType === ChartType.LINE,
              });
              datasetsKey[dataSetLabel] = index - 1;
            } else {
              datasets[datasetsKey[dataSetLabel]].globalLabelsPresent[groupLevel[0]] = dataset[labels];
            }
          } else if (datasets.length > 0) {
            // datasets[0].data.push(dataset[groupLevel[0]]);
            datasets[0].globalLabelsPresent[groupLevel[0]] = dataset[groupLevel[0]];
          } else {
            const globalLabelsPresent = {};
            globalLabelsPresent[groupLevel[0]] = dataset[groupLevel[0]];
            datasets.push({
              label: this.operation.id + '(' + this.defaultColumn.displayValue + ')',
              globalLabelsPresent: globalLabelsPresent,
              data: [],
              fill: this.chartType === ChartType.LINE,
            });
          }
        }
      }

      for (const ds of datasets) {
        for (const label of globalLabels) {
          if (ds.globalLabelsPresent[label] === undefined) {
            ds.data.push(undefined);
          } else {
            const value = ds.globalLabelsPresent[label];
            ds.data.push(value);
          }
        }
      }

      this.dataChart = {
        labels: globalLabels,
        datasets: datasets,
      };

      const palette = this.#themeService.getThemeProperty('colorsPalette');
      this.dataChart.datasets.forEach((ds: any, i: number) => {
        const color = i < palette.length ? palette[i] : this.#getRandomColor();
        const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
        const backGroundColor = color + _opacity.toString(16).toUpperCase();
        if (this.chartType === ChartType.LINE) {
          ds.borderColor = color;
        } else if (this.chartType === ChartType.BAR || this.chartType === ChartType.COLUMN) {
          ds.backgroundColor = backGroundColor;
        } else if (this.chartType === ChartType.RADAR || this.chartType === ChartType.AREA) {
          ds.backgroundColor = backGroundColor;
          ds.borderColor = color;
          ds.pointBackgroundColor = color;
          ds.pointBorderColor = '#fff';
        }
      });
    } else {
      for (const labels in dataset) {
        const groupLevel = labels.replace(/\&\|/g, ' - ');
        globalLabels.push(groupLevel);
        data.push(dataset[labels]);
      }

      this.dataChart = {
        labels: globalLabels,
        datasets: [
          {
            data: data,
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };
      const palette = this.#themeService.getThemeProperty('colorsPalette');
      this.dataChart.labels.forEach((label: any, i: number) => {
        const color = i < palette.length ? palette[i] : this.#getRandomColor();
        const _opacity = Math.round(Math.min(Math.max(0.7 || 1, 0), 1) * 255);
        const backGroundColor = color + _opacity.toString(16).toUpperCase();
        this.dataChart.datasets.forEach((ds) => {
          ds.backgroundColor.push(backGroundColor);
          ds.hoverBackgroundColor.push(color);
        });
      });
    }
    this.options = {
      tooltips: {
        callbacks: {
          title: (item, data) => {
            const label = data.labels[item.index];
            return label;
          },
          label: (item, data) => {
            const dataSetValue = parseFloat(data.datasets[item.datasetIndex].data[item.index]);
            const total = data.datasets[item.datasetIndex].data.reduce((a, b) => {
              return a + parseFloat(b);
            }, 0);
            const pourcentage = ((dataSetValue / total) * 100).toFixed(2) + '%';
            return dataSetValue.toFixed(2) + (total !== 0 ? ` (${pourcentage})` : '');
          },
        },
      },
    };
    if (this.chartType === ChartType.BAR) {
      this.options.indexAxis = 'y';
    }
    setTimeout(() => this.chart.reinit(), 0);
  }

  #getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)];
    return color;
  }
  //#endregion

  //#region Switch Chart
  switchToPieChart() {
    this.chartType = ChartType.PIE;
    this.#transformData();
  }

  switchToLineChart() {
    this.chartType = ChartType.LINE;
    this.#transformData();
  }

  switchToAreaChart() {
    this.chartType = ChartType.AREA;
    this.#transformData();
  }

  switchToPolarChart() {
    this.chartType = ChartType.POLARAREA;
    this.#transformData();
  }

  switchToBarChart() {
    this.chartType = ChartType.BAR;
    this.#transformData();
  }

  switchToColumnChart() {
    this.chartType = ChartType.COLUMN;
    this.#transformData();
  }

  switchToDoughnutChart() {
    this.chartType = ChartType.DOUGHNUT;
    this.#transformData();
  }

  switchToRadarChart() {
    this.chartType = ChartType.RADAR;
    this.#transformData();
  }
  //#endregion

  //#region Other
  getCurrentContext() {
    return (<GridViewUiComponent>this.DOMParentComponent)?.getCurrentContext() ?? null;
  }

  getTabWhereClause() {
    return (<GridViewUiComponent>this.DOMParentComponent)?.getTabWhereclause() ?? null;
  }

  #notifyUrlChange(): void {
    (<BladeUiComponent>this.container)?.notifyUrlChange();
  }
  //#endregion

  onChildUpdate(event): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}
export interface ColumnFilter {
  displayValue: string;
  id: any;
  type: any;
}
