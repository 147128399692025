export namespace Sound {
  const wms_scan = new Audio('assets/iupics/sounds/wms_scan.mp3');
  const wms_error = new Audio('assets/iupics/sounds/wms_error.mp3');

  export function playScan() {
    wms_scan.play();
  }

  export function playError() {
    wms_error.play();
  }
}
