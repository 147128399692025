import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import {
  SpecificWindowCompiereWS,
  SpecificWindowDetailItemFieldCompiereWS,
} from '@compiere-ws/models/specific-window-json';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import {
  IupicsField,
  IupicsSpecificWindow,
  IupicsTableDataHeader,
  NumberType,
} from '@iupics-manager/models/iupics-data';
import { isNil } from 'lodash';
import moment from 'moment';
import { UICreatorUtils } from './ui-creator.utils';

export class SpecificWindowsCreatorUtils {
  private static dataStore: DataStoreService;
  private static defaultLanguage: CompiereLanguage;
  private static connectorService: SecurityManagerService;

  public static setDataStore(dataStore: DataStoreService): void {
    this.dataStore = dataStore;
  }
  public static setConnectorService(connectorService: SecurityManagerService): void {
    this.connectorService = connectorService;
  }

  public static transformSpecificWindow(
    iupicsSpecificWindows: Map<number, IupicsSpecificWindow>,
    specificWindow: SpecificWindowCompiereWS,
    key?: number
  ): any {
    const errors = {};
    if (specificWindow && specificWindow.detail) {
      const iupicsSpecificWindow: IupicsSpecificWindow = {
        AD_FormDetail_ID: specificWindow.AD_FormDetail_ID,
        AD_Form_ID: specificWindow.AD_Form_ID,
        AD_InfoWindow_ID: specificWindow.detail.AD_InfoWindow_ID,
        angularClass: specificWindow.AngularClass,
        description: specificWindow.Description,
        entityType: specificWindow.detail.EntityType,
        help: specificWindow.Help,
        name: specificWindow.Name,
        title: specificWindow.detail.Titre,
        items: [],
      };
      if (specificWindow.detail.items) {
        const dataStructure = { Record_ID: null };
        for (const item of specificWindow.detail.items) {
          if ((item.Type && item.Type === 'T') || (item.detail && item.detail.Type && item.detail.Type === 'T')) {
            let concernedItem = item;
            if (item.detail && item.detail.Type && item.detail.Type === 'T') {
              concernedItem = item.detail;
              concernedItem.DisplayLogic = item.DisplayLogic;
              concernedItem.IsActive = item.IsActive;
              concernedItem.IsFrontEnd = item.IsFrontEnd;
              concernedItem.IsMandatory = item.IsMandatory;
              concernedItem.MandatoryLogic = item.MandatoryLogic;
              concernedItem.CssClass = item.CssClass;
              concernedItem.IsNextLine = item.IsNextLine;
              concernedItem.IsLaunchSearchGrid = item.IsLaunchSearchGrid;
              concernedItem.hasUniversalFilter = item.hasUniversalFilter;
              concernedItem.hasCheckbox = item.hasCheckbox;
              concernedItem.ColumnName = item.ColumnName;
            }
            try {
              const specificWindowGridItem = this.transformSpecificWindowGrid(concernedItem);
              if (!dataStructure.hasOwnProperty('selections')) {
                dataStructure['selections'] = [];
              }
              dataStructure['selections'].push({
                AD_FormDetail_ID: concernedItem.AD_FormDetail_ID,
                selection: [],
              });
              iupicsSpecificWindow.items.push(specificWindowGridItem);
            } catch (err) {
              errors[item.ColumnName] = err;
            }
          } else {
            try {
              const specificWindowFieldItem = this.transformSpecificWindowField(item);
              dataStructure[specificWindowFieldItem.data.columnName] = null;
              iupicsSpecificWindow.items.push(specificWindowFieldItem);
              if (specificWindowFieldItem.data.isRange) {
                specificWindowFieldItem.data.colspan = 1;
                specificWindowFieldItem.data.label += '_From';
                const fieldTo = this.transformSpecificWindowField(item);
                fieldTo.isRange = false;
                fieldTo.data.fromRange = false;
                fieldTo.data.columnName += '_To';
                fieldTo.data.isSameLine = true;
                fieldTo.data.nbCol = 2;
                fieldTo.data.colspan = 1;
                fieldTo.data.label += '_To';
                iupicsSpecificWindow.items.push(fieldTo);
              }
            } catch (err) {
              errors[item.ColumnName] = err;
            }
          }
        }
        this.dataStore.addSpecificWindowDataStructure(specificWindow.AD_Form_ID, dataStructure);
      }
      iupicsSpecificWindows.set(key ?? specificWindow.AD_Form_ID, iupicsSpecificWindow);
      let keys = '';
      let values = '';
      const errorKeys = Object.keys(errors);
      for (const key of errorKeys) {
        if (values !== '') {
          values += ', ';
        }
        values += errors[key];
        if (keys !== '') {
          keys += ', ';
        }
        keys += key;
      }
      return Object.keys(errors).length > 0 ? { keys: keys, values: values } : {};
    }
  }

  private static transformSpecificWindowField(item: SpecificWindowDetailItemFieldCompiereWS): any {
    const fieldData = item.field.field;
    fieldData.poName = item.field.poName;
    const newComponent = UICreatorUtils.getComponentNameFromReference(item.AD_Reference_ID);
    if (newComponent === 'CalendarUiComponent') {
      this.dataStore.saveDateColumn(fieldData.ColumnName, item.AD_Reference_ID);
    }
    if (newComponent) {
      let whereClauseStr = '';
      if (item.WhereClause) {
        whereClauseStr += '(' + item.WhereClause.replace('\n', ' ') + ')';
      }
      if (fieldData.ValidationCode) {
        if (whereClauseStr) {
          whereClauseStr += ' AND ';
          whereClauseStr += '(' + fieldData.ValidationCode.replace('\n', ' ') + ')';
        } else {
          whereClauseStr += fieldData.ValidationCode.replace('\n', ' ');
        }
      }
      const specificWindowField: IupicsField = {
        processId: item.AD_Process_ID,
        formId: item.AD_Form_ID,
        referenceId: item.AD_Reference_ID,
        seqNo: item.SeqNo,
        formDetailId: item.AD_FormDetail_ID,
        formDetailItemId: item.AD_FormDetailItem_ID,
        data: {
          formId: item.AD_Form_ID,
          columnId: fieldData.AD_Column_ID,
          fieldId: fieldData.AD_Field_ID,
          label: fieldData.name,
          poLabel: fieldData.poName,
          columnName: fieldData.ColumnName,
          displayLogic: item.DisplayLogic,
          isOptional: item.IsOptional,
          readOnlyLogic: item.ReadOnlyLogic,
          hasExtendedLogic: item.hasExtendedLogic,
          cssClass: item.CssClass,
          isMandatory: item.IsMandatory,
          description: item?.field?.field?.Description ?? '',
          help: item?.field?.field?.Help ?? '',
          mandatoryLogic: item.MandatoryLogic,
          defaultValue: item.DefaultValue,
          defaultValue2: item.DefaultValue2,
          isSearch:
            item.AD_Reference_ID === 30 || (item.field.urlSearch !== null && item.field.urlSearch !== undefined),
          needTime: item.AD_Reference_ID === 16 || item.AD_Reference_ID === 24,
          isLaunchSearchGrid: item.IsLaunchSearchGrid,
          isFilterSearch: item.IsSelectionColumn,
          isRange: item.IsRange,
          nbrAddressSlot: 1,
          urlList: item.field.urlList,
          details: item.field.details,
          urlSearch: item.field.urlSearch,
          isSameLine: !item.IsNextLine,
          numberType:
            item.AD_Reference_ID === 12 || item.AD_Reference_ID === 37
              ? NumberType.AMOUNT
              : item.AD_Reference_ID === 11
                ? NumberType.INTEGER
                : item.AD_Reference_ID === 22
                  ? NumberType.FLOAT
                  : item.AD_Reference_ID === 29
                    ? NumberType.QUANTITY
                    : null,
          validationCode: whereClauseStr,
        },
        component: newComponent,
        cssClass: newComponent === 'ButtonUiComponent' ? 'p-col-12 p-md-3 p-lg-2' : 'p-col-12 p-md-3 p-lg-3',
      };
      return specificWindowField;
    } else {
      return undefined;
    }
  }

  private static transformSpecificWindowGrid(grid: SpecificWindowDetailItemFieldCompiereWS): any {
    const specificWindowGrid: any = {
      component: 'GridViewUiComponent',
      formDetailId: grid.AD_FormDetail_ID,
      infoWindowId: grid.AD_InfoWindow_ID,
      tableId: grid.AD_Table_ID,
      data: {
        columnsTableHeader: [],
        urlSearch: '/FormDetailItem/searchTab/' + grid.AD_FormDetail_ID,
        AD_FormDetail_ID: grid.AD_FormDetail_ID,
        title: grid.Titre,
        columnsDisplayLogicMap: new Map<string, string>(),
        columnsDetails: new Map<string, any>(),
        displayLogic: grid.DisplayLogic,
        readOnlyLogic: grid.ReadOnlyLogic,
        isMandatory: grid.IsMandatory,
        cssClass: grid.CssClass,
        mandatoryLogic: grid.MandatoryLogic,
        isLaunchSearchGrid: grid.IsLaunchSearchGrid,
        hasCheckbox: grid.hasCheckbox,
        hasUniversalFilter: grid.hasUniversalFilter,
        editorColumns: [],
        columnName: grid.ColumnName,
        maxNbLines: grid.maxNbLines,
      },
      cssClass: grid.CssClass ? grid.CssClass : 'p-col-12',
      name: grid.Name,
    };
    for (const item of grid.items) {
      const itemFieldData = item.field.field;
      const fieldTransformed: IupicsTableDataHeader = {
        field: itemFieldData.ColumnName,
        headerName: itemFieldData.name,
        enableRowGroup: true,
        editable: false,
        hide: item.IsOptional,
        width: 'min-content',
        resizable: true,
      };

      switch (item.AD_Reference_ID) {
        case 24:
          // Time
          fieldTransformed.cellRenderer = (params: any) =>
            !isNil(params.value) ? `${moment(params.value).format('LT')}` : '';
          break;

        case 20:
          // inputSwitch
          fieldTransformed.cellRenderer = (params: any) => {
            if (params.value) {
              if (item.ReadOnlyLogic && item.ReadOnlyLogic.includes('1=2')) {
                return !isNil(params.value)
                  ? `<label class="container-background">
                  <input type="checkbox" ${params.value === 'Y' ? 'checked' : ''} >
                  ${
                    params.value === 'Y'
                      ? '<span class="checkbox icon-checkbox-checked"></span>'
                      : '<span class="checkbox icon-checkbox-crossed"></span>'
                  }
                  </label>`
                  : '';
              } else {
                return !isNil(params.value)
                  ? `<label class="container-background">
                  <input type="checkbox" disabled='disabled' ${params.value === 'Y' ? 'checked' : ''} >
                  ${
                    params.value === 'Y'
                      ? '<span class="checkbox icon-checkbox-checked"></span>'
                      : '<span class="checkbox icon-checkbox-crossed"></span>'
                  }
                  </label>`
                  : '';
              }
            }
          };
          break;
        case 17:
          this.displayBackgroundField(fieldTransformed, item);
          break;
        case 28:
          this.displayBackgroundField(fieldTransformed, item);
          break;
          //#START CUSTO-SAMVAZ
        case 12:
          this.AmountCH(fieldTransformed);
          break;
        case 37:
          this.AmountCH(fieldTransformed);
          break;
        case 11:
          this.AmountCH(fieldTransformed);
          break;
        case 22:
          this.AmountCH(fieldTransformed);
          break;
        case 29:
          this.AmountCH(fieldTransformed);
          break;
          //#END CUSTO-SAMVAZ
        default:
          break;
      }

      if (item.IsEditable) {
        specificWindowGrid.data.editorColumns.push(itemFieldData.ColumnName);
        fieldTransformed.cellEditor = SpecificWindowsCreatorUtils.getCellEditor(item.AD_Reference_ID);
        fieldTransformed.cellEditorParams = {
          label: '',
          templates: {
            items: null,
            columnName: itemFieldData.ColumnName,
            urlList: item.field.urlList,
            urlSearch: item.field.urlSearch,
            isSearch: true,
            columnId: itemFieldData.AD_Column_ID,
            fieldTypeForm: true,
            formDetailId: item.AD_FormDetail_ID,
            defaultLanguage: this.defaultLanguage,
            needTime: item.AD_Reference_ID === 16 || item.AD_Reference_ID === 24,
            numberType: item.AD_Reference_ID === 12 ? NumberType.FLOAT : NumberType.INTEGER,
            validationCode: itemFieldData.ValidationCode,
          },
        };
        fieldTransformed.editable = true;
        fieldTransformed.alwaysEditable = true;
      }
      specificWindowGrid.data.columnsTableHeader.push(fieldTransformed);
      specificWindowGrid.data.columnsDisplayLogicMap.set(itemFieldData.ColumnName, item.DisplayLogic);
      specificWindowGrid.data.columnsDetails.set(itemFieldData.ColumnName, item);
    }
    return specificWindowGrid;
  }

  private static getCellEditor(referenceId: number, fieldLength: number = 0): string {
    /* Display Type 10	String	*/
    /* Display Type 14	Text	*/
    /* Display Type 40	URL	*/
    /* Display Type 36	File Path	*/
    /* Display Type 39 File Name	*/
    if (referenceId === 10 || referenceId === 14 || referenceId === 40 || referenceId === 38 || referenceId === 39) {
      return 'textEditor';
    } else if (
      /* Display Type 11	Integer	*/
      /* Display Type 12	Amount	*/
      /* Display Type 13	ID	*/
      /* Display Type 22	Number	*/
      /* Display Type 29	Quantity	*/
      /* Display Type 37	CostPrice	*/
      referenceId === 11 ||
      referenceId === 12 ||
      referenceId === 13 ||
      referenceId === 22 ||
      referenceId === 29 ||
      referenceId === 37
    ) {
      return 'numberEditor';
    } else if (referenceId >= 15 && referenceId <= 16) {
      /* Display Type 15	Date	*/
      /* Display Type 16	DateTime	*/
      return 'calendarEditor';
    } else if (referenceId === 24) {
      /* Display Type 24	Time	*/
      return 'textEditor';
    } else if (
      (referenceId >= 17 && referenceId <= 19) ||
      referenceId === 30 ||
      referenceId === 42 ||
      referenceId === 27 ||
      referenceId === 31 ||
      referenceId === 25
    ) {
      /* Display Type 17	List	*/
      /* Display Type 18	Table	*/
      /* Display Type 19	TableDir	*/
      /* Display Type 30	Search	*/
      /* Display Type 27	Color   */
      /* Display Type 42	PrinterName	*/
      /* Display Type 31	Locator	*/
      /* Display Type 25	Account	*/
      return 'autocompleteEditor';
    } else if (referenceId === 20) {
      /* Display Type 20	YN	*/
      return 'checkboxEditor';
    } else if (referenceId === 21) {
      /* Display Type 21	Location	*/
      return 'textEditor';
    } else if (referenceId === 28 || referenceId === 46) {
      /* Display Type 28	Button	*/
      /* Display type 46 Link Button */
      return 'buttonEditor';
    } else if (referenceId === 36 || referenceId === 34 || referenceId === 23) {
      /* Display Type 36	CLOB	*/
      /* Display Type 23	BLOB	*/
      /* Display Type 34	Memo	*/
      return 'textEditor';
    } else {
      // console.log(`No Renderer defined for AD_Reference_ID : ${referenceId}`);
      return undefined;
    }
  }

  public static displayBackgroundField(
    fieldTransformed: IupicsTableDataHeader,
    item: SpecificWindowDetailItemFieldCompiereWS
  ) {
    if (
      item.AD_Reference_Value_ID > 0 &&
      item.field &&
      item.field.details &&
      item.field.details.tableName === 'AD_Ref_List' &&
      item.field.details.cssColor
    ) {
      fieldTransformed.cellRenderer = (params: any) =>
        !isNil(params.value)
          ? `<span style="background: ${
              item.field.details.cssColor[params.value] || 'transparent'
            }" class="text-background">${params.valueFormatted}</span>`
          : '';
    }
    return fieldTransformed;
  }

  
  //#START CUSTO-SAMVAZ
  public static AmountCH(
    fieldTransformed: IupicsTableDataHeader
  ) {
    this.defaultLanguage = this.connectorService.getIupicsDefaultLanguage();
        let lang = this.defaultLanguage.iso_code.replace('_', '-');
        if (lang === 'fr-FR') {
          lang = 'de-CH';
        }
        // Montant - Integer - Float
        fieldTransformed.cellRenderer = (params: any) =>
          params.value !== null && params.value !== undefined
            ? `${params.value.toLocaleString(lang)}`
            : '0';
    return fieldTransformed;
  }
  //#END CUSTO-SAMVAZ
}
