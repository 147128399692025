import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { FilterModel, FilterModelOperator, SortModel } from '@iupics/apiz-grid';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable, Subject, catchError, iif, map, of, switchMap, zip } from 'rxjs';
import {
  SKWDataListParams,
  SKWFilter,
  SKWInventoryLine,
  SKWLineStatus,
  SKWListData,
  SKWListDataParams,
  SKWListTabType,
  SKWPalletForm,
  SKWTaskStatus,
  SKWTransferHeaderData,
  SKWTransferLineData,
  SKWTransferLineFormData
} from '../models/storekeeper-window.model';

@Injectable()
export class SKWDataService {
  readonly #DEFAULT_TRANSFER_FETCH_LENGTH = environment.constant.StorekeeperTransferPageSize ?? 30;

  readonly #BASE_URL = `${environment.config.backend.ws.url}/wms/storekeeper`;
  readonly #CREATE_TRANSFER_URL = `${this.#BASE_URL}/transfer/create`;
  readonly #CREATE_INVENTORY_URL = `${this.#BASE_URL}/inventory/create`;
  readonly #CREATE_PALLET_URL = `${this.#BASE_URL}/pallet/create`;

  // TODO: adapt to use WS
  socket$ = new Subject<WSMessage>();

  #http = inject(HttpClient);

  #dataStore = inject(DataStoreService);
  #securityService = inject(SecurityManagerService);

  #workflowService = inject(CompiereWorkflowService);

  getData(
    filters: SKWFilter[] | undefined,
    options?: {
      tab?: SKWListTabType;
      status?: SKWTaskStatus;
      params?: SKWListDataParams;
    }
  ): Observable<{ data: SKWListData; params: SKWListDataParams }> {
    if (!options?.tab) {
      return zip(
        this.getTasksAS(filters, options?.params),
        this.getTasksCO(filters, options?.params),
        this.getTransfers(filters, options?.params)
      ).pipe(
        map(([tasksAS, tasksCO, transfers]) => ({
          data: { tasks: { AS: tasksAS.data, CO: tasksCO.data }, transfers: transfers.data },
          params: {
            tasks: {
              AS: tasksAS.params,
              CO: tasksCO.params
            },
            transfers: transfers.params
          }
        }))
      );
    }

    if (options?.tab === SKWListTabType.TRANSFERS) {
      return this.getTransfers(filters, options?.params).pipe(
        map(({ data, params }) => ({ data: { transfers: data }, params: { transfers: params } }))
      );
    }

    return this.getTasks(filters, options?.params, options?.status);
  }

  getTasks(
    filters: SKWFilter[] | undefined,
    _params: SKWListDataParams,
    status?: SKWTaskStatus
  ): Observable<{ data: SKWListData; params: SKWListDataParams }> {
    if (!status) {
      return zip(this.getTasksAS(filters, _params), this.getTasksCO(filters, _params)).pipe(
        map(([tasksAS, tasksCO]) => ({
          data: { tasks: { AS: tasksAS.data, CO: tasksCO.data } },
          params: { tasks: { AS: tasksAS.params, CO: tasksCO.params } }
        }))
      );
    }

    return (status === SKWTaskStatus.AS ? this.getTasksAS(filters, _params) : this.getTasksCO(filters, _params)).pipe(
      map(({ data, params }) => ({ data: { tasks: { [status]: data } }, params: { tasks: { [status]: params } } }))
    );
  }

  getTasksAS(filters: SKWFilter[] | undefined, params: SKWListDataParams) {
    return this.#getTasksForStatus(
      filters,
      [
        {
          colId: 'Priority',
          sort: 'desc'
        },
        {
          colId: 'EndDate',
          sort: 'asc'
        }
      ],
      params,
      SKWTaskStatus.AS
    );
  }

  getTasksCO(filters: SKWFilter[] | undefined, params: SKWListDataParams) {
    return this.#getTasksForStatus(
      filters,
      [
        {
          colId: 'Updated',
          sort: 'desc'
        }
      ],
      params,
      SKWTaskStatus.CO
    );
  }

  #getTasksForStatus(
    filters: SKWFilter[] | undefined,
    sortModel: SortModel[],
    params: SKWListDataParams,
    taskStatus: SKWTaskStatus
  ) {
    const filterModel: FilterModel = {
      Storekeeper_ID: {
        filterType: 'number',
        operators: [FilterModelOperator.EQUALS],
        values: [this.#securityService.getIupicsUserAccount().id],
        configs: [null]
      },
      DocStatus: {
        filterType: 'set',
        operators: ['equals'],
        values: [[taskStatus]],
        configs: [null]
      }
    };

    if (taskStatus === SKWTaskStatus.CO) {
      filterModel['Updated'] = {
        filterType: 'date',
        operators: [FilterModelOperator.EQUALS],
        values: [moment(Date.now()).format('YYYY-MM-DD')],
        configs: [null]
      };
    }

    return this.#getTransfers(filters, {
      filterModel,
      sortModel,
      startRow: params?.tasks?.[taskStatus].nextStartRow ?? 0,
      endRow: (params?.tasks?.[taskStatus].nextStartRow ?? 0) + this.#DEFAULT_TRANSFER_FETCH_LENGTH
    });
  }

  getTransfers(filters: SKWFilter[] | undefined, params: SKWListDataParams) {
    return this.#getTransfers(filters, {
      filterModel: {
        DocStatus: {
          filterType: 'set',
          operators: ['equals'],
          values: [['TC']],
          configs: [null]
        },
        Storekeeper_ID: {
          filterType: 'set',
          operators: ['isNull'],
          values: [null],
          configs: [null]
        }
      },
      sortModel: [
        {
          colId: 'Priority',
          sort: 'desc'
        },
        {
          colId: 'EndDate',
          sort: 'asc'
        }
        // {
        //   colId: 'Created',
        //   sort: 'asc',
        // },
      ],
      startRow: params?.transfers.nextStartRow ?? 0,
      endRow: (params?.transfers.nextStartRow ?? 0) + this.#DEFAULT_TRANSFER_FETCH_LENGTH
    });
  }

  getTransfer({ Record_ID, table_id, table_name }: SKWTransferHeaderData) {
    const filterModel: FilterModel = {
      Record_ID: {
        filterType: 'set',
        operators: ['equals'],
        values: [Record_ID]
      }
    };

    if (table_id) {
      Object.assign(filterModel, {
        table_id: {
          filterType: 'set',
          operators: ['equals'],
          values: [table_id]
        }
      });
    }

    if (table_name) {
      Object.assign(filterModel, {
        table_name: {
          filterType: 'set',
          operators: ['equals'],
          values: [table_name]
        }
      });
    }

    return this.#getTransfers(undefined, {
      startRow: 0,
      endRow: 1,
      filterModel
    }).pipe(map((result) => result?.data?.[0]));
  }

  #getTransfers(
    filters: SKWFilter[] | undefined,
    request: {
      filterModel?: FilterModel;
      sortModel?: SortModel[];
      startRow: number;
      endRow: number;
    }
  ) {
    return this.#dataStore
      .getDataGrid({
        windowId: undefined,
        compiereRequest: {
          windowType: CompiereDataGridType.TABLE,
          tableName: 'rv_storekeeper_tasks',
          startRow: request.startRow,
          endRow: request.endRow,
          windowCtx: this.#getWindowCtx(),
          validation: this.#getValidationFromFilters(filters),
          filterModel: request?.filterModel ?? {},
          groupKeys: [],
          rowGroupCols: [],
          sortModel: request?.sortModel ?? [],
          valueCols: []
        }
      })
      .pipe(
        map((result) => ({
          data: <SKWTransferHeaderData[]>result.data,
          params: {
            nextStartRow: result.compiereRequest.endRow,
            endRow: result.lastRow
          }
        }))
      );
  }

  getTransferDetails(tfHeader: SKWTransferHeaderData, params?: SKWDataListParams, lineTab?: SKWLineStatus) {
    const filterModel: FilterModel = {
      Record_ID: {
        filterType: 'set',
        operators: ['equals'],
        values: [[tfHeader.Record_ID]],
        configs: [null]
      },
      table_id: {
        filterType: 'set',
        operators: ['equals'],
        values: [tfHeader.table_id]
      },
      table_name: {
        filterType: 'set',
        operators: ['equals'],
        values: [tfHeader.table_name]
      },
      DocStatus: {
        filterType: 'set',
        operators: ['notEqual'],
        values: ['CO']
      }
    };

    if (lineTab) {
      filterModel.DocStatus = {
        filterType: 'set',
        operators: ['equals'],
        values: [lineTab]
      };
    }

    return this.#dataStore.getDataGrid({
      windowId: undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'rv_storekeeper_tasks_details',
        startRow: params?.startRow ?? 0,
        endRow: params?.endRow ?? 30,
        windowCtx: this.#getWindowCtx(),
        validation: '',
        filterModel,
        groupKeys: [],
        rowGroupCols: [],
        sortModel: [],
        valueCols: []
      }
    });
  }

  saveTransfer(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData) {
    return this.#http
      .post<{ Record_ID: number; success: boolean; message: string }>(this.#CREATE_TRANSFER_URL, {
        M_Movement_ID: transfer.Record_ID,
        // table_id: transfer.table_id,
        // table_name: transfer.table_name,
        Storekeeper_ID: transfer.Storekeeper_ID,
        M_Locator_ID: line?.locator?.id,
        M_Product_ID: line?.Product?.id,
        Quantity: line?.Qty,
        Description: transfer?.Description,
        Comment: transfer?.Comment,
        windowCtx: this.#getWindowCtx()
      })
      .pipe(map((result) => ({ request: { transfer, line }, result })));
  }

  savePalletTransfer(data: SKWPalletForm) {
    return this.#http
      .post(this.#CREATE_PALLET_URL, {
        ...data,
        windowCtx: this.#getWindowCtx()
      })
      .pipe(map((result) => ({ request: { pallet: data }, result })));
  }

  assignTransferRequest(transfer: SKWTransferHeaderData) {
    return this.#updateTransfer('AM', transfer);
  }

  removeTask(transfer: SKWTransferHeaderData) {
    return this.#updateTransfer('VO', transfer);
  }

  finishTask(transfer: SKWTransferHeaderData) {
    return this.#updateTransfer('CO', transfer);
  }

  #updateTransfer(action: string, transfer: SKWTransferHeaderData): Observable<SKWRequestResult<SKWTransferHeaderData>> {
    return this.#runWF(
      action,
      transfer.table_name,
      '' + transfer.table_id,
      '' + transfer.AD_Process_ID.id,
      '' + transfer.Record_ID,
      {
        ...this.#getWindowCtx(),
        DocStatus: { id: transfer.DocStatus }
      }
    ).pipe(
      switchMap((result) =>
        iif(
          () => !result?.Success,
          of({ error: result?.Message, data: transfer }),
          of({
            data: {
              ...transfer,
              DocStatus: result?.DocStatus?.id,
              Storekeeper_ID: action === SKWTaskStatus.AS ? this.#securityService.getIupicsUserAccount().id : undefined
            }
          })
        )
      ),
      catchError((error) => of({ error: error?.message, data: transfer }))
    );
  }

  updateTransferLine(
    line: SKWTransferLineData,
    data: SKWTransferLineFormData
  ): Observable<SKWRequestResult<SKWTransferLineData>> {
    return this.#runWF(
      line.DocStatus === SKWLineStatus.TL ? 'LC' : 'UC',
      line.tableline_name,
      '' + line.tableline_id,
      '' + line.AD_Process_ID.id,
      '' + line.Line_ID,
      {
        ...this.#getWindowCtx(),
        Input_Locator: data?.locator?.id,
        Input_Palette: data?.palette?.id,
        Input_Product: data?.Product?.id,
        Input_Qty: data?.Qty,
        DocStatus: { id: line.DocStatus }
      }
    ).pipe(
      switchMap((result) =>
        iif(
          () => !result?.Success,
          of({ error: result?.Message, data: line }),
          of({
            data: {
              ...line,
              DocStatus: result?.DocStatus?.id
            }
          })
        )
      ),
      catchError((error) => of({ error: error?.message, data: line }))
    );
  }

  #runWF(action: string, table_Name: string, table_id: string, ad_process_id: string, record_id: string, windowCtx = {}) {
    return this.#workflowService.runWF({
      action,
      table_id,
      ad_process_id,
      windowCtx,
      table_Name,
      record_id
    });
  }

  saveInventoryRequest(lines: SKWInventoryLine[]) {
    return this.#http.post<{ Record_ID: number; success: boolean; message: string }>(this.#CREATE_INVENTORY_URL, {
      lines: lines.map((l) => ({
        M_Locator_ID: l.M_Locator_ID.id,
        M_Product_ID: l.M_Product_ID.id,
        Quantity: l.Quantity
      })),
      windowCtx: this.#getWindowCtx()
    });
  }

  finishInventory(M_Inventory_ID: string) {
    return this.#runWF('CO', 'M_Inventory', '321', '107', M_Inventory_ID, {
      ...this.#getWindowCtx(),
      DocStatus: { id: 'DR' }
    });
  }

  #getValidationFromFilters(filters: SKWFilter[] | undefined) {
    // "isOF='Y' OR isEx='Y' OR isRA='Y' OR isPalette='Y'";
    const validation =
      filters?.map(({ value }) => value).join(' OR ') ??
      "isOF='Y' OR isEx='Y' OR isRA='Y' OR isPalette='Y' OR isManual='Y' OR isCond='Y'";
    return validation ? '(' + validation + ')' : '';
  }

  #getWindowCtx() {
    return this.#securityService.getIupicsUserContext();
  }
}

export type WSMessage = {
  action: 'add' | 'remove' | 'update';
  data: SKWTransferHeaderData[];
};

export type SKWRequestResult<T> = {
  data?: T;
  error?: string;
};
