//#START CUSTO-SAMVAZ
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { TranslateModule } from '@ngx-translate/core';
import  SpecificWindowUiComponent  from '../specific-window-ui/specific-window-ui.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { SidebarModule } from 'primeng/sidebar';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'iu-third-party-address-label-ui',
  templateUrl: './third-party-address-label-ui.component.html',
  styleUrls: ['./third-party-address-label-ui.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export class ThirdPartyAddressLabelUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  descriptionList: any[] = [];
  constructor() {
    super();
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'C_BPartner_ID' || item.data.columnName === 'C_BPartner_Location_ID') {
      this.descriptionList = [];
      this.dataStore.data['Description'] = null;
      this.dataContainers.forEach((datacontainer) => {
        if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'Description') {
          datacontainer.updateStore(this.dataStore.data['Description']);
        }
      });
    }
    if (
      item.data.columnName === 'C_BPartner_Location_ID' &&
      this.dataStore.data['C_BPartner_ID'] &&
      this.dataStore.data['C_BPartner_Location_ID']
    ) {
      const Pbartner_request: DataStoreRequest = {
        windowId: null,
        parent_constraint: null,
        compiereRequest: {
          startRow: 0,
          tableName: 'C_BPartner',
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['C_BPartner_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      this.store.getDataGrid(Pbartner_request).subscribe((res) => {
        if (res && res.data && res.data.length > 0 && res.data[0]['NAME']) {
          this.descriptionList.push(res.data[0]['NAME']);
          this.setDescription();
        }
      });

      const BPartnerLocation_Request: DataStoreRequest = {
        windowId: null,
        parent_constraint: null,
        compiereRequest: {
          startRow: 0,
          tableName: 'C_BPartner_Location',
          filterModel: {
            C_BPartner_Location_ID: {
              filterType: CompiereDataGridFilterType.SET,
              operators: [OperatorFilterType.EQUALS],
              values: [this.dataStore.data['C_BPartner_Location_ID'].id]
            }
          }
        }
      };

      this.store.getDataGrid(BPartnerLocation_Request).subscribe((res) => {
        if (res && res.data && res.data.length > 0) {
          this.store.getDataGrid(this.getUserRequest(res.data[0]['AD_USER_ID']), true).subscribe((resUser) => {
            if (resUser && resUser.data && resUser.data.length > 0) {
              let user = '';
              if (resUser.data[0]['C_Greeting_ID'] && resUser.data[0]['C_Greeting_ID'].displayValue) {
                user += resUser.data[0]['C_Greeting_ID'].displayValue + ' ';
              }
              if (resUser.data[0]['Name']) {
                user += resUser.data[0]['Name'] + ' ';
              } else {
                if (resUser.data[0]['ContactLastName']) {
                  user += resUser.data[0]['ContactLastName'] + ' ';
                }
                if (resUser.data[0]['ContactName']) {
                  user += resUser.data[0]['ContactName'] + ' ';
                }
              }
              if (user) {
                this.descriptionList.push(user);
                this.setDescription();
              }
            }
            if (res.data[0]['C_LOCATION_ID']) {
              this.store.getDataGrid(this.getLocationRequest(res.data[0]['C_LOCATION_ID']), true).subscribe((result) => {
                if (result && result.data && result.data.length > 0) {
                  let address = '';
                  if (result.data[0]['Address1']) {
                    address += result.data[0]['Address1']+ '\n';
                  }
                  if (result.data[0]['Address2']) {
                    address += result.data[0]['Address2']+ '\n';
                  }
                  if (result.data[0]['Address3']) {
                    address += result.data[0]['Address3']+ '\n';
                  }
                  if (result.data[0]['Address4']) {
                    address += result.data[0]['Address4']+ '\n';
                  }
                  if (address) {
                    this.descriptionList.push(address);
                  }
                  address = '';
                  if (result.data[0]['Postal']) {
                    address += result.data[0]['Postal'] + ' ';
                  }
                  if (result.data[0]['City']) {
                    address += result.data[0]['City'] + ' ';
                  } else if (result.data[0]['C_City_ID'] && result.data[0]['C_City_ID'].displayValue) {
                    address += result.data[0]['C_City_ID'].displayValue + ' ';
                  }

                  if (address) {
                    this.descriptionList.push(address);
                  }
                  this.setDescription();

                  if (result.data[0]['C_Country_ID']) {
                    this.store
                      .getDataGrid(this.getCountryRequest(result.data[0]['C_Country_ID'].id), true)
                      .subscribe((resCountry) => {
                        if (
                          resCountry &&
                          resCountry.data &&
                          resCountry.data.length > 0 &&
                          resCountry.data[0]['Name'] &&
                          resCountry.data[0]['CountryCode'] !== 'CH'
                        ) {
                          this.descriptionList.push(resCountry.data[0]['Name']);
                          this.setDescription();
                        }
                      });
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  setDescription() {
    this.dataStore.data['Description'] = this.descriptionList.join('\n');
    this.dataContainers.forEach((datacontainer) => {
      if (datacontainer && datacontainer.data && datacontainer.data.columnName === 'Description') {
        datacontainer.updateStore(this.dataStore.data['Description']);
      }
    });
  }

  checkBeforeProcessing() {
    this.descriptionList = [];
    this.dataStore.data['Description'].split('\n').forEach((line: string) => {
      this.descriptionList.push(line);
    });
    this.dataStore.data['Description'] = this.descriptionList.join(';');
    return true;
  }

  getLocationRequest(c_location_id: number) {
    return {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'C_Location',

        filterModel: {
          C_Location_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [c_location_id]
          }
        }
      }
    };
  }

  getCountryRequest(c_country_id: number) {
    return {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'C_Country',

        filterModel: {
          C_Country_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [c_country_id]
          }
        }
      }
    };
  }

  getUserRequest(ad_user_id: number) {
    return {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'AD_User',
        filterModel: {
          AD_User_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [ad_user_id]
          }
        }
      }
    };
  }
}