@if (!state.loaded()) {
  <div class="skw-wrapper">
    <iu-storekeeper-loading [text]="'LinesLoading' | SKWTranslate" />
  </div>
} @else if (isComplete()) {
  <div class="stamp-container">
    <div class="stamp">{{ 'Finished' | SKWTranslate }}</div>
  </div>
  <div class="skw-wrapper flex">
    <iu-storekeeper-button [label]="'BackToTasks' | SKWTranslate" (onClick)="backToTasks($event)" />
    <iu-storekeeper-button [label]="'Print' | SKWTranslate" [type]="'outlined'" />
  </div>
} @else if (!lines() || lines()?.length === 0) {
  <ng-container [ngTemplateOutlet]="title" />
  <ng-container [ngTemplateOutlet]="linesEmpty" />
} @else {
  <iu-storekeeper-window-input-scan [isSearch]="true" [lines]="lines()" (search)="handleSearch($event)" />

  <ng-container [ngTemplateOutlet]="title" />

  @if (isConditioning() && tabActive() === 'UL') {
    <div class="list-content">
      <div class="line" (click)="selectLine($event, lines()?.[0])">
        <!-- TODO: review data for pallet -->
        <p class="description" [innerHTML]="lines()?.[0]?.palette_destination_barcode"></p>
        <div class="where">
          <span class="text">{{ lines()?.[0]?.locator_destination?.displayValue }}</span>
        </div>
      </div>
    </div>
  } @else {
    <cdk-virtual-scroll-viewport [itemSize]="118" [minBufferPx]="900" [maxBufferPx]="900" class="list-content">
      <div class="line" *cdkVirtualFor="let line of lines(); trackBy: trackByFn.bind(this)" (click)="selectLine($event, line)">
        @if (!isPalette()) {
          <h4 class="title">
            <span class="qty">{{ line.Qty }}</span>
            <span class="separator">X</span>
            <span class="product">{{ line.M_Product_ID.displayValue }}</span>
          </h4>

          @if (line.product_description?.length > 0) {
            <p class="description" [innerHTML]="line.product_description"></p>
          } @else {
            <p class="description no-description">{{ 'NoDescription' | SKWTranslate }}</p>
          }
        } @else {
          <!-- TODO: review data for pallet -->
          <p class="description" [innerHTML]="line.palette_source_barcode"></p>
        }

        <div class="where">
          <span class="text">{{ (tabActive() === 'TL' ? line.locator_source : line.locator_destination)?.displayValue }}</span>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  }

  @if (state.pending() === 'get_more') {
    <iu-storekeeper-loading class="center" [text]="'LoadingMoreLines' | SKWTranslate" />
  }
}

<ng-template #title>
  <div class="title-bar">
    <h3 class="title">{{ (!isPalette() ? 'Lines' : 'Pallet') | SKWTranslate }}</h3>

    @if (!isPalette() && (isNewTransfer() || canAddLine()) && tabActive() === 'TL') {
      <iu-storekeeper-button [label]="'Add' | SKWTranslate" [type]="'outlined'" (onClick)="addNewLine($event)" />
    }
  </div>
</ng-template>

<ng-template #linesEmpty>
  <div class="skw-wrapper">
    @if (isLinesComplete()) {
      <iu-storekeeper-empty-data
        [icon]="'icon-dolly-empty'"
        [text]="'LineProcessingComplete' | SKWTranslate"
        [showButton]="false"
      />
    } @else if (!hasLines()) {
      <iu-storekeeper-empty-data
        [icon]="'icon-dolly-empty'"
        [text]="'NoLineForThisTransfer' | SKWTranslate"
        [showButton]="false"
      />
    } @else if (tabActive() === 'TL') {
      <iu-storekeeper-empty-data [icon]="'icon-dolly-empty'" [text]="'NoLineToLoad' | SKWTranslate" [showButton]="true" />
    } @else {
      <iu-storekeeper-empty-data [icon]="'icon-truck'" [text]="'NoLineToUnload' | SKWTranslate" [showButton]="true" />
    }
  </div>
</ng-template>
