import { DestroyRef, Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { signalSlice } from '@iupics/apiz-grid';
import { Observable, Subject, catchError, iif, map, of, switchMap, tap } from 'rxjs';
import SpecificWindowUiComponent from '../../specific-window-ui/specific-window-ui.component';
import {
  SKWContextState,
  SKWFilter,
  SKWInventoryLine,
  SKWListData,
  SKWListDataParams,
  SKWListTabType,
  SKWStateAction,
  SKWStateActionType,
  SKWTaskStatus,
  SKWTransferHeaderData,
  SKWTransferLineFormData
} from '../models/storekeeper-window.model';
import { SKWMessageService, SKWMessageType } from './storekeeper-window-message.service';
import { SKWNavigationService } from './storekeeper-window-navigation.service';
import { SKWTranslateService } from './storekeeper-window-translate.service';
import { SKWDataService, SKWRequestResult, WSMessage } from './strokeeper-window-data.service';

@Injectable()
export class SKWContextService {
  #destroyRef = inject(DestroyRef);
  #SKWNavigationService: SKWNavigationService;
  #SKWDataService = inject(SKWDataService);
  #SKWMessageService = inject(SKWMessageService);
  #SKWTranslateService = inject(SKWTranslateService);

  #beforeAction$ = new Subject<{ action: SKWStateAction; state: SKWContextState }>();
  #action$ = new Subject<{ action: SKWStateAction; state: SKWContextState }>();
  #error$ = new Subject<SKWContextState['error']>();

  isInitialized = false;

  #getMoreData$ = new Subject();

  container: SpecificWindowUiComponent;

  state = signalSlice({
    initialState: this.#getInitState(),
    sources: [
      this.#getDataObs({}).pipe(tap(() => (this.isInitialized = true))),
      this.#beforeAction$.pipe(
        map(({ action, state }) => {
          if (!action) {
            return { loaded: true, pending: undefined };
          }

          const { isLoading, clearData, type } = action;

          const newState = {
            loaded: !isLoading,
            pending: type
          };

          if (clearData) {
            if (!this.isInitialized) {
              Object.assign(newState, { data: undefined });
            } else {
              const newData = { ...state.data };
              if (this.#SKWNavigationService.listTabActive() === SKWListTabType.TRANSFERS) {
                newData.transfers = undefined;
              } else if (this.#SKWNavigationService.listStatusTabActive() === SKWTaskStatus.AS && newData?.tasks?.AS) {
                newData.tasks.AS = undefined;
              } else if (newData?.tasks?.CO) {
                newData.tasks.CO = undefined;
              }
            }
          }

          return newState;
        })
      ),
      this.#action$.pipe(
        tap((action) => this.#beforeAction$.next(action)),
        switchMap(({ action: { source, after, clearData } }) =>
          iif(
            () => !source,
            iif(() => clearData, this.#getDataObs({ clear: clearData }), of({})),
            source?.pipe(map((state) => state ?? {}))
          ).pipe(
            map((result) => ({ ...result, loaded: true, pending: undefined })),
            catchError((error) => of({ error, loaded: true, pending: undefined })),
            tap((state) => after?.(<SKWContextState>state))
          )
        )
      ),
      this.#error$.pipe(map((error) => ({ error, loaded: true })))
    ],
    actionSources: {
      handleMessage: (state, action$: Observable<WSMessage>) => {
        // TODO: use this when WS connected
        return action$.pipe(map(() => ({ data: state().data }))); /* .pipe(
          map(({ action, data }) => {
            const d = [...state().data];
            switch (action) {
              case 'add':
                d.push(...data);
                break;
              case 'remove': {
                for (const t of data) {
                  const index = d.findIndex((tt) => tt.M_Movement_ID === t.M_Movement_ID);
                  d.splice(index, 1);
                }
                break;
              }
              case 'update': {
                for (const t of data) {
                  const index = d.findIndex((tt) => tt.M_Movement_ID === t.M_Movement_ID);
                  d[index] = { ...d[index], ...t };
                }
                break;
              }
            }

            return { data: d };
          })
        ) */
      }
    },
    effects: (state) => ({
      handleError: () => {
        const err = state.error();
        if (err) {
          this.#SKWMessageService.addMessage({
            type: SKWMessageType.ERROR,
            title: 'Error',
            content: err,
            key: 'global_error'
          });
        }
      }
    })
  });

  #filters = signal<SKWFilter[] | undefined>(undefined);
  filters = this.#filters.asReadonly();

  // TODO: Update data with socket message
  #socket$ = this.#SKWDataService.socket$.pipe(tap((message) => this.#handleMessage(message)));

  constructor() {
    for (const obs of [this.#socket$, this.#getMoreDataObs()]) this.#handleObs(obs);
  }

  #handleObs(obs: Observable<any>) {
    obs.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
  }

  setNavService(navService: SKWNavigationService) {
    this.#SKWNavigationService = navService;
  }

  //#region State
  #getInitState(): SKWContextState {
    return {
      data: undefined,
      params: undefined,
      pending: undefined,
      loaded: false,
      error: null
    };
  }

  #formatObsOutputForState(
    obs: Observable<{ data: SKWListData; params: SKWListDataParams }>,
    tab?: SKWListTabType,
    status?: SKWTaskStatus,
    concatData = false
  ): Observable<SKWContextState> {
    return obs.pipe(
      map(({ data, params }) => {
        return {
          data: this.#mergeData(data, tab, status, concatData),
          params,
          pending: undefined,
          loaded: true,
          error: undefined
        };
      }),
      catchError((err) =>
        of({
          data: undefined,
          params: undefined,
          pending: undefined,
          loaded: true,
          error: err
        })
      )
    );
  }

  #concat<T>(a: T[] | undefined, b: T[] | undefined) {
    return [...(a ?? []), ...(b ?? [])];
  }

  #mergeData({ transfers, tasks }: SKWListData, tab?: SKWListTabType, status?: SKWTaskStatus, concatData = false) {
    const data = { ...this.state?.data() };

    if (!tab) {
      return concatData
        ? {
            transfers: this.#concat(this.state?.data()?.transfers, transfers),
            tasks: {
              AS: this.#concat(this.state?.data()?.tasks?.AS, tasks?.AS),
              CO: this.#concat(this.state?.data()?.tasks?.CO, tasks?.CO)
            }
          }
        : { transfers, tasks };
    }

    if (tab === SKWListTabType.TRANSFERS) {
      data.transfers = concatData ? this.#concat(data?.transfers, transfers) : transfers;
    } else {
      data.tasks = {
        ...(data?.tasks ?? {}),
        [status]: concatData ? this.#concat(data?.tasks?.[status], tasks?.[status]) : tasks?.[status]
      };
    }

    return data;
  }
  //#endregion

  //#region Actions
  addMoreData() {
    this.#getMoreData$.next(undefined);
  }

  refresh() {
    this.newAction({
      type: SKWStateActionType.REFRESH,
      clearData: true,
      isLoading: true
    });
  }

  save(saveObs: Observable<unknown>, impactTransfersData = true) {
    this.newAction({
      type: SKWStateActionType.SAVE,
      clearData: impactTransfersData,
      isLoading: true,
      source: saveObs.pipe(map(() => undefined))
    });
  }

  saveInventory(lines: SKWInventoryLine[]) {
    this.save(this.#saveInventoryObs(lines), false);
  }

  saveTransfer(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData) {
    this.save(this.#saveTransferObs(transfer, line), false);
  }

  assignTransfer(transfer: SKWTransferHeaderData, callback?: (result: SKWRequestResult<SKWTransferHeaderData>) => void) {
    this.newAction({
      type: SKWStateActionType.ASSIGN,
      clearData: false,
      isLoading: true,
      source: this.#SKWDataService.assignTransferRequest(transfer).pipe(
        tap((result) => callback(result)),
        map((result) => {
          this.#SKWMessageService.addMessage({
            title: !result.error ? 'SuccessfulAssignments' : 'FailedAssignments',
            content: !result.error
              ? (data) => {
                  let str = this.#SKWTranslateService.getTranslation('Transfers');
                  if (data.length > 1) {
                    str += ` (${data.map((d) => d?.data?.Record_ID).join(',')})`;
                  }

                  str += ` ${this.#SKWTranslateService.getTranslation('CorrectlyAssigned')}`;

                  return str;
                }
              : result.error,
            type: !result.error ? SKWMessageType.SUCCESS : SKWMessageType.ERROR,
            key: !result.error ? 'assignSuccess' : 'assignError',
            data: result.data
          });

          if (result.error) {
            return undefined;
          }

          const data = this.state.data();
          this.#moveFromListTo(data.transfers, data.tasks.AS, result.data);
          return { data: this.#dereferenceData(data) };
        })
      )
    });
  }

  removeTask(task: SKWTransferHeaderData, callback?: (result: SKWRequestResult<SKWTransferHeaderData>) => void) {
    this.newAction({
      type: SKWStateActionType.REMOVE,
      clearData: false,
      isLoading: true,
      source: this.#SKWDataService.removeTask(task).pipe(
        tap((result) => callback(result)),
        map((result) => {
          const data = this.state.data();
          this.#moveFromListTo(data.tasks.AS, data.transfers, result.data);
          return { data: this.#dereferenceData(data) };
        })
      )
    });
  }

  #moveFromListTo(a: SKWTransferHeaderData[], b: SKWTransferHeaderData[], item: SKWTransferHeaderData) {
    const index = a.findIndex((t) => t.Record_ID === item.Record_ID);
    a.splice(index, 1);
    b.push(item);
  }

  #dereferenceData(data: SKWListData) {
    return {
      transfers: [...(data?.transfers ?? [])],
      tasks: {
        AS: [...(data?.tasks?.AS ?? [])],
        CO: [...(data?.tasks?.CO ?? [])]
      }
    };
  }

  updateFilters(filters: SKWFilter[]) {
    this.#filters.set(filters);
    this.refresh();
  }

  #handleMessage(message: WSMessage) {
    this.state.handleMessage(message);
  }

  newAction(action: SKWStateAction) {
    this.#action$.next({ action, state: this.state() });
  }
  //#endregion

  //#region Observable
  #getDataObs({ clear = false, concatData = false }): Observable<SKWContextState> {
    return this.#formatObsOutputForState(
      this.#SKWDataService.getData(
        this.#filters?.(),
        this.isInitialized
          ? {
              tab: this.#SKWNavigationService.listTabActive(),
              status: this.#SKWNavigationService.listStatusTabActive(),
              params: clear ? undefined : this.state.params()
            }
          : undefined
      ),
      this.#SKWNavigationService?.listTabActive?.(),
      this.#SKWNavigationService?.listStatusTabActive?.(),
      concatData
    );
  }

  #getMoreDataObs() {
    return this.#getMoreData$.pipe(
      tap(() => {
        this.newAction({
          type: SKWStateActionType.GET_MORE,
          isLoading: false,
          source: this.#getDataObs({ concatData: true })
        });
      })
    );
  }

  #saveTransferObs(transfer: SKWTransferHeaderData, line: SKWTransferLineFormData) {
    return this.#SKWDataService.saveTransfer(transfer, line);
  }

  #saveInventoryObs(lines: SKWInventoryLine[]) {
    return this.#SKWDataService.saveInventoryRequest(lines).pipe(
      tap((result) => {
        if (!result.success) {
          this.#SKWMessageService.addMessage({
            type: SKWMessageType.ERROR,
            title: 'InventoryNotSaved',
            content: result.message,
            key: 'saveInventory'
          });
          return;
        }

        this.#SKWMessageService.addMessage({
          type: SKWMessageType.SUCCESS,
          title: 'InventorySaved',
          content: result.message,
          key: 'saveInventory'
        });
      }),
      switchMap((result) => iif(() => !result.success, of(result), this.#SKWDataService.finishInventory('' + result.Record_ID))),
      tap((result) => {
        if (!result.Success) {
          this.#SKWMessageService.addMessage({
            type: SKWMessageType.ERROR,
            title: 'InventoryUnprocessed',
            content: result.message,
            key: 'runWFInventory'
          });
          return;
        }

        this.#SKWMessageService.addMessage({
          type: SKWMessageType.SUCCESS,
          title: 'InventoryProcessed',
          content: result.message,
          key: 'runWFInventory'
        });

        this.#SKWNavigationService.previousPage();
      })
    );
  }
  //#endregion
}
