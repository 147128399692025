//TODO REMOVE AFTER APIZ 6.0.0
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
    inject,
  } from '@angular/core';
  import {
    CompiereDataGridFilterType,
    CompiereDataGridRequestJSON,
    CompiereDataGridResponseJSON,
    CompiereDataGridType,
    DataStoreKey,
    DataStoreRequest,
  } from '@compiere-ws/models/compiere-data-json';
  import { Calendar, CalendarOptions, EventClickArg, EventDropArg, ViewApi } from '@fullcalendar/core';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin, { EventDragStartArg } from '@fullcalendar/interaction';
  import { OperatorFilterType } from '@iupics-components/models/universal-filter';
  import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
  import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
  import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
  import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
  import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
  import { IupicsEvent } from '@iupics-manager/models/iupics-event';
  import { ApizGridUtils } from '@iupics-util/tools/apiz-grid.utils';
  import { ApizGridEvent, AppliedItem, GridOptionsAppliedItems, injectGridApiService } from '@iupics/apiz-grid';
  import { SplitManagerService } from '@web-desktop/controllers/split-manager/split-manager.service';
  import * as moment from 'moment';
  import { Observable, Subject, map, of, switchMap, tap } from 'rxjs';
  import GridViewUiComponent from '../../grid-view-ui/grid-view-ui.component';
  import { injectViewColumnsService } from '../../services/view-columns.service';
  import CalendarToolbarUiComponent, { DayGridType } from '../calendar-toolbar-ui/calendar-toolbar-ui.component';
  
  @Component({
    selector: 'iu-calendar-view-ui',
    templateUrl: './calendar-view-ui.component.html',
    styleUrls: ['./calendar-view-ui.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CalendarToolbarUiComponent],
  })
  export default class CalendarViewUiComponent
    extends AbstractDynamicComponent
    implements OnInit, AfterViewInit, OnDestroy
  {
    readonly #DATE_FORMAT = `YYYY-MM-DDTHH:mm:ss.SSS`;
  
    #store = inject(DataStoreService);
    #connectorService = inject(SecurityManagerService);
    #splitManager = inject(SplitManagerService);
    #el = inject(ElementRef);
    #renderer = inject(Renderer2);
  
    #gridApi = injectGridApiService();
    #viewColumnsService = injectViewColumnsService();
  
    context: {
      dateStart: Date;
      defaultView: string;
    };
  
    @ViewChild('fc', { static: true })
    fc: ElementRef;
  
    calendarStore: CalendarStore = {};
  
    #dataStoreRequest$ = new Subject<DataStoreRequest>();
  
    @Input()
    datas: any[] = [];
  
    @Input()
    defaultColumn: { name: any };
  
    @Input()
    initRequest: CompiereDataGridRequestJSON;
  
    get request() {
      return ApizGridUtils.cleanDataRequestForCompiereRequest(
          this.#gridApi.getRequest());
    }
  
    get filters() {
      return this.#gridApi.filters();
    }
  
    @Input()
    style: any;
  
    @Input()
    styleClass: string;
  
    initialized: boolean;
  
    calendar: Calendar;
  
    config: CalendarOptions;
  
    events: any[] = [];
  
    title: string;
  
    selectedStartDateField: string;
    selectedEndDateField: string;
    columnNameDateImpacted: string;
    displayColumns: string[] = ['DocumentNo', 'C_BPartner_ID', 'AD_User_ID'];
  
    #isGetDatagridInProgress = false;
  
    #updateAppliedItems$ = this.#gridApi.appliedItemsUpdated
      .asObservable()
      .pipe(tap((event) => this.#onAppliedItemsUpdated(event)));
  
    @Output()
    clickEmitter = new EventEmitter<any>();
  
    constructor() {
      super();
      this.#gridApi.resetDatasource();
    }
  
    ngOnInit() {
      this.#renderer.setStyle(this.container.scrollableElt.nativeElement, 'background-color', '#fff');
      this.config = {
        plugins: [dayGridPlugin, interactionPlugin],
      };
      this.subscriptions.push(
        this.#splitManager.dragGutterEmitter.subscribe(() => this.handleWindowResize(this.calendar)),
        this.#newDataStoreRequestObs().subscribe(),
        this.#updateAppliedItems$.subscribe()
      );
    }
  
    ngAfterViewInit() {
      if (!this.initialized && this.fc.nativeElement.offsetParent) {
        this.#initialize();
      }
  
      this.#setTitle();
    }
  
    ngOnDestroy() {
      super.ngOnDestroy();
      if (this.calendar) {
        this.calendar.destroy();
        this.initialized = false;
        this.calendar = null;
      }
  
      this.#renderer.removeStyle(this.container.scrollableElt.nativeElement, 'background-color');
    }
  
    //#region Initialization
    /**
     * Initialize full-calendar
     */
    #initialize(): void {
      this.#initCalendarOptions();
      this.calendar = new Calendar(this.fc.nativeElement, this.config);
      this.calendar.render();
      this.initialized = true;
  
      if (this.events) {
        this.calendar.removeAllEventSources();
        this.calendar.addEventSource(this.events);
      }
  
      this.subscriptions.push(
        this.#getColumnInfosObs()
          .pipe(
            tap((defCol) => {
              this.defaultColumn = defCol;
              // We need to apply the initRequest if it exists to the applied items
              const initAppliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(
                this.initRequest,
                this.#gridApi,
                this.#gridApi.columnApi
              );
              // We need to nullify the initRequest to avoid infinite loop
              this.initRequest = undefined;
              // If there is no filter, we need to create an empty array to use reference in #updateDateFilterInRange
              initAppliedItems.filters ||= [];
              // We need to apply filters that may have been set in other views
              initAppliedItems.filters.push(...(this.filters ?? []));
              // We need to update the filter that is linked to the date column to the current calendar range
              // If doesn't exist, we need to create it
              this.#updateDateFilterInRange(initAppliedItems.filters);
              this.#gridApi.updateAppliedItems(initAppliedItems, undefined);
            })
          )
          .subscribe()
      );
  
      this.#viewColumnsService.setView({
        tabId: this.tabId,
        data: this.data,
        ctx: this.#getCurrentContext(),
        shouldSetColDefs: true,
        windowType: this.windowType,
      });
    }
  
    /**
     * Initialise les options du fullcalendar
     */
    #initCalendarOptions() {
      this.config.initialView = this.context?.defaultView ?? 'dayGridMonth';
      this.config.initialDate = this.#formatDate(this.context?.dateStart);
      this.config.headerToolbar = false;
      this.config.locale = this.#connectorService.getIupicsDefaultLanguage().iso_code.replace(/_/g, '-');
      // this.config.height = this.#getRemainingSpaceHeight();
      this.config.handleWindowResize = true;
      this.config.windowResize = (arg: { view: ViewApi }) => this.handleWindowResize(arg);
      this.config.editable = true;
      this.config.droppable = true;
      this.config.eventClick = (info: EventClickArg) => this.handleClickEvent(info);
      this.config.eventDragStart = (info: EventDragStartArg) => this.handleDragStartEvent(info);
      this.config.eventDrop = (info: EventDropArg) => this.handleDropEvent(info);
      this.config.dayMaxEventRows = true;
      this.config.firstDay = 1;
    }
    //#endregion
  
    #getColumnInfosObs(): Observable<{ name: string }> {
      if (this.#isGetDatagridInProgress || !this.container) {
        return;
      }
  
      return this.#viewColumnsService.colDefs$.pipe(
        map(({ columns }) => {
          let defaultColumn = columns.find((c) => c.fieldEntity.field.Name === 'Created') ? { name: 'Created' } : null;
          if (!defaultColumn) {
            let found = false;
            let i = 0;
  
            while (!found && i < columns.length) {
              const field = columns[i]?.fieldEntity?.field;
              if (
                (field?.Name || field?.name) &&
                (field.Name ?? field.name)?.trim() !== '' &&
                (field.IsDisplayed || field.IsKey) &&
                (field.AD_Reference_ID === 15 || field.AD_Reference_ID === 16) &&
                ((field.MRSeqNo !== 0 && field.MRSeqNo !== 99999) || field.IsDisplayed)
              ) {
                defaultColumn = { name: field.ColumnName };
                found = true;
              }
  
              i++;
            }
  
            return defaultColumn;
          }
        })
      );
    }
  
    #getRemainingSpaceHeight() {
      return (<HTMLElement>this.#el.nativeElement).parentElement.clientHeight - 36 - 32;
    }
  
    //#region APPLIED ITEMS
    #updateAppliedItemFilter(filter: AppliedItem<'filter'>) {
      this.#gridApi.updateAppliedItem(filter);
    }
  
    #onAppliedItemsUpdated(event: ApizGridEvent<GridOptionsAppliedItems>) {
      if (!event.data || (!event.data.filters && !event.data.groups && !event.data.aggregates)) {
        return;
      }
  
      this.#queryDataStore(this.request);
    }
    //#endregion
  
    //#region Date Filter
    /**
     * This function will update the filter if the current filter date is not in the current calendar range
     * @param isPrevious - Tells if we are going to the previous or next period
     */
    #changeFilter() {
      const oldFilter = this.request.filterModel[this.columnNameDateImpacted ?? this.defaultColumn?.name];
      const calendarDateStart = this.#getCalendarCurrentDateRangeStart();
      const calendarDateEnd = this.#getCalendarCurrentDateRangeEnd();
      let indexOfFirstBetween = (oldFilter?.operators ?? []).indexOf(OperatorFilterType.BETWEEN);
      const filterDateStart = new Date(oldFilter.values[indexOfFirstBetween]);
      const filterDateEnd = new Date(oldFilter.values[indexOfFirstBetween + 1]);
      // We need to update the filter if the filter date is not in the calendar range
      if (filterDateStart < calendarDateStart || filterDateEnd > calendarDateEnd) {
        this.#updateDateFilterInRange();
      }
    }
  
    /**
     * Retrieve the date filter from the request and update it with the current calendar range
     */
    #updateDateFilterInRange(filters?: AppliedItem<'filter'>[]) {
      let filterDateFound: AppliedItem<'filter'> | undefined = undefined;
      const filtersToCheck = filters ?? this.filters;
      if (filtersToCheck.length > 0) {
        for (const filter of filtersToCheck) {
          if (
            filter.filterType !== CompiereDataGridFilterType.DATE ||
            filter.operators.some((operator) => operator !== OperatorFilterType.BETWEEN)
          ) {
            continue;
          }
  
          filterDateFound = filter;
          this.columnNameDateImpacted = filter.colId;
          break;
        }
      }
  
      if (!filterDateFound && this.defaultColumn) {
        filterDateFound = this.#initDateFilter();
        this.columnNameDateImpacted = this.defaultColumn.name;
        filters.push(filterDateFound);
      }
  
      if (!filterDateFound) {
        return;
      }
  
      filterDateFound.operators = [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN];
      filterDateFound.values[0] = this.#formatDate(this.#getCalendarCurrentDateRangeStart());
      filterDateFound.values[1] = this.#formatDate(this.#getCalendarCurrentDateRangeEnd());
  
      if (!filters) {
        this.#updateAppliedItemFilter(filterDateFound);
      }
    }
  
    /**
     * Initialize the date filter with the current calendar range, if not already set
     */
    #initDateFilter(): AppliedItem<'filter'> | undefined {
      if (!this.defaultColumn) {
        return undefined;
      }
  
      const calendarDateStart = this.#getCalendarCurrentDateRangeStart();
      const calendarDateEnd = this.#getCalendarCurrentDateRangeEnd();
  
      return {
        type: 'filter',
        colId: this.defaultColumn.name,
        filterType: CompiereDataGridFilterType.DATE,
        values: [this.#formatDate(calendarDateStart), this.#formatDate(calendarDateEnd)],
        operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN],
      };
    }
    //#endregion
  
    //#region Data
    refresh() {
      this.#clearLocalDataStore();
      this.#queryDataStore(this.request);
    }
  
    #newDataStoreRequestObs() {
      return this.#dataStoreRequest$.asObservable().pipe(
        tap(() => (this.#isGetDatagridInProgress = true)),
        switchMap((request) => {
          const dataFromLocalDataStore = this.#getDataFromLocalDataStore(request);
          if (dataFromLocalDataStore) {
            return of({ fromCache: true, data: dataFromLocalDataStore.data, request });
          }
  
          return this.#store.getDataGrid(request).pipe(
            map((response) => ({
              fromCache: false,
              data: response.data,
              request,
            }))
          );
        }),
        tap((response) => this.#setData(response.request, response.data, response.fromCache)),
        tap((response) => {
          if (!response.fromCache) {
            this.#notifyUrlChange();
          }
  
          this.#isGetDatagridInProgress = false;
        })
      );
    }
  
    #queryDataStore(request?: any) {
      const dataStoreRequest: DataStoreRequest = {
        windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 0,
          windowCtx: this.#getCurrentContext(),
          validation: this.#getTabWhereClause(),
        },
      };
  
      if (request) {
        dataStoreRequest.compiereRequest.filterModel = this.request.filterModel;
        for (const key in dataStoreRequest.compiereRequest.filterModel ?? {}) {
          const f = dataStoreRequest.compiereRequest.filterModel[key];
          if (f.filterType !== CompiereDataGridFilterType.DATE) {
            continue;
          }
  
          f.values = f.values.map((v) => this.#formatDate(v));
        }
  
        dataStoreRequest.compiereRequest.rowGroupCols = [];
        dataStoreRequest.compiereRequest.sortModel = this.request.sortModel;
      }
  
      this.#dataStoreRequest$.next(dataStoreRequest);
    }
  
    /**
     * Enregistre la date en db
     * @param {string}date
     * @param {DataStoreKey}dsKey
     * @param {string}field
     * @param {Function}revert
     */
    #saveData(date: string, dsKey: DataStoreKey, field: string, revert: Function): void {
      const request: DataStoreRequest = {
        windowId: dsKey.windowId,
        record_id: dsKey.recordId,
        parent_constraint: dsKey.parentId,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: dsKey.tabId,
          startRow: 0,
          endRow: 1,
          windowCtx: this.#getCurrentContext(),
          validation: this.#getTabWhereClause(),
        },
      };
      this.subscriptions.push(
        this.#store.getWindowSingleData(request).subscribe({
          next: (response) => {
            const fieldsChanged = {};
            fieldsChanged[field] = date;
            this.#store.syncDataChanges(response, fieldsChanged, true);
            this.subscriptions.push(
              this.#store.saveWindowData([response.key]).subscribe({
                next: (res) => {
                  if (res === null) revert();
                },
                error: (_) => revert(),
              })
            );
          },
        })
      );
    }
  
    #getCurrentContext() {
      return (<GridViewUiComponent>this.DOMParentComponent)?.getCurrentContext() ?? null;
    }
  
    #getTabWhereClause() {
      return (<GridViewUiComponent>this.DOMParentComponent)?.getTabWhereclause() ?? null;
    }
  
    #setData(dataStoreRequest: DataStoreRequest, data: CompiereDataGridResponseJSON['data'], fromCache = false) {
      if (!data) return;
  
      this.datas = data;
      const columnFilter =
        this.columnNameDateImpacted ??
        this.defaultColumn?.name ??
        Object.keys(dataStoreRequest.compiereRequest?.filterModel ?? {})?.[0] ??
        undefined;
      if (columnFilter) {
        this.#updateDisplayDate(columnFilter.replace(/"/g, ''));
        this.#setDataToLocalDataStore(
          dataStoreRequest.compiereRequest.filterModel[columnFilter].values[0],
          dataStoreRequest.compiereRequest.filterModel[columnFilter].values[1],
          data,
          dataStoreRequest
        );
      }
    }
  
    /**
     * Filtre les datas reçues afin de les afficher correctement.
     * L'id de l'event est le JSON.stringify de la datastoreKey de l'enregistrement.
     * Il est stringify car l'id de l'event n'accepte que des string PAS d'objets.
     */
    #updateDisplayDate(columnFilter: string) {
      this.events = this.datas.map((data) => {
        let title = '';
        let found = false;
        let i = 0;
        while (!found && i < this.displayColumns.length) {
          if (data[this.displayColumns[i]]) {
            found = true;
            title = data[this.displayColumns[i]]?.displayValue ?? data[this.displayColumns[i]];
          }
          i++;
        }
        const windowId = this.data.AD_Window_ID;
        const dataStoreKey = this.#store.generateDataStoreKey(windowId, this.tabId, data['Data_UUID'], null);
        const dateStart = data[columnFilter]
          ? (<string>data[columnFilter]).slice(0, data[columnFilter].length - 2)
          : '' + ':' + data[columnFilter]
            ? (<string>data[columnFilter]).slice(data[columnFilter].length - 2, data[columnFilter].length)
            : '';
  
        return {
          id: JSON.stringify(dataStoreKey),
          title: title,
          start: dateStart && dateStart.length > 10 ? dateStart.slice(0, 10) : dateStart,
          end: dateStart && dateStart.length > 10 ? dateStart.slice(0, 10) : dateStart,
        };
      });
  
      if (this.events && this.calendar) {
        this.calendar.removeAllEventSources();
        this.calendar.addEventSource(this.events);
      }
    }
    //#endregion
  
    //#region Local Data Store
    #getLocalDataStoreKey(start: DateParam, end: DateParam): ConcatenatedDate {
      return `${this.#formatDate(start)}|${this.#formatDate(end)}`;
    }
  
    #setDataToLocalDataStore(start: DateParam, end: DateParam, data: any[], request: DataStoreRequest) {
      const key = this.#getLocalDataStoreKey(start, end);
      this.calendarStore[key] = {
        data: data,
        request: request,
        expiringDate: new Date(new Date().getTime() + 60000),
      };
    }
  
    #getDataFromLocalDataStore(request: DataStoreRequest): CalendarStoreEntry | undefined {
      const start = request.compiereRequest?.filterModel?.[this.columnNameDateImpacted]?.values?.[0];
      const end = request.compiereRequest?.filterModel?.[this.columnNameDateImpacted]?.values?.[1];
      if (!start || !end) return undefined;
      const key = this.#getLocalDataStoreKey(start, end);
  
      let data = this.calendarStore?.[key] ?? undefined;
      // handle data expiration and request mismatch
      if ((data && new Date() > data.expiringDate) || this.#requestMismatch(data?.request, request)) {
        this.#clearEntryFromLocalDataStore(key);
        data = undefined;
      }
  
      return data;
    }
  
    #clearEntryFromLocalDataStore(key: ConcatenatedDate) {
      delete this.calendarStore?.[key];
    }
  
    #clearLocalDataStore() {
      this.calendarStore = {};
    }
  
    //? Maybe we should use a deep comparison
    #requestMismatch(request: DataStoreRequest, newRequest: DataStoreRequest) {
      return JSON.stringify(request) !== JSON.stringify(newRequest);
    }
    //#endregion
  
    //#region Calendar Toolbar
    showNextPeriod() {
      this.calendar.next();
      this.#setTitle();
      this.#changeFilter();
    }
  
    showPrevPeriod() {
      this.calendar.prev();
      this.#setTitle();
      this.#changeFilter();
    }
  
    showToday() {
      this.calendar.today();
      this.#setTitle();
    }
  
    changeView(view: DayGridType) {
      this.calendar.changeView(view);
      this.#changeFilter();
      this.#setTitle();
    }
    //#endregion
  
    //#region Event Handlers
    /**
     * Gère l'évenement du redimensionnement de la fenêtre
     * @param {ViewApi}view La vue actuellement affichée
     */
    handleWindowResize(arg: { view: ViewApi }) {
      this.calendar.setOption('height', this.#getRemainingSpaceHeight());
    }
  
    /**
     * Gère l'évenement d'un click sur un enregistrement dans le calendrier
     * @param {EventClickArg}info
     */
    handleClickEvent(info: EventClickArg) {
      const datastoreKey: DataStoreKey = JSON.parse(info.event.id);
      this.clickEmitter.emit(datastoreKey.recordId);
    }
  
    /**
     * Gère l'évenement du commencement du drag d'un enregistrement dans le calendrier
     * @param {EventDragStartArg}info
     */
    handleDragStartEvent(info: EventDragStartArg) {}
  
    /**
     * Gère l'évenement du drop dans une autre case que celle de départ.
     * @param {EventDropArg}info
     */
    handleDropEvent(info: EventDropArg) {
      const datastoreKey: DataStoreKey = JSON.parse(info.event.id);
      if (info.event.start) {
        this.#saveData(this.#formatDate(info.event.start), datastoreKey, this.columnNameDateImpacted, info.revert);
      }
  
      if (info.event.end) {
        this.#saveData(this.#formatDate(info.event.end), datastoreKey, this.columnNameDateImpacted, info.revert);
      }
    }
  
    /**
     * Permet le detachement du calendar lors du detachement de la split-view
     */
    handleDetach() {
      if (this.calendar?.view) {
        this.context = {
          // dateStart: this.calendar.view.dateProfile.currentRange.start,
          dateStart: this.calendar.view.currentStart,
          defaultView: this.calendar.view.type,
        };
      }
      this.calendar.destroy();
      this.initialized = false;
      this.#renderer.removeStyle(this.container.scrollableElt.nativeElement, 'background-color');
    }
  
    /**
     * Permet l'insertion du calendar lors de l'insertion de la split-view
     */
    handleInsert() {
      setTimeout(() => this.#initialize(), 50);
    }
    //#endregion
  
    //#region Utils
    #setTitle() {
      this.title = this.calendar.view.title;
    }
  
    #formatDate(date?: DateParam): string {
      return moment(date).format(this.#DATE_FORMAT);
      //? Is this necessary?
      // const momentDate = moment(date).format(this.LONG_DATE_FORMAT);
      // return `${momentDate.substring(0, 26)}${momentDate.substring(27)}`;
    }
  
    #getCalendarCurrentDateRangeStart() {
      return this.calendar.getCurrentData().dateProfile.activeRange.start;
    }
  
    #getCalendarCurrentDateRangeEnd() {
      return this.calendar.getCurrentData().dateProfile.activeRange.end;
    }
  
    #notifyUrlChange() {
      // TODO - AFTER_MIGRATION_APIZ_GRID: je pense qu'on pourrait gérer ça autrement
      if (
        this.DOMParentComponent instanceof GridViewUiComponent &&
        !(this.DOMParentComponent.DOMParentComponent instanceof EditTabUiComponent)
      ) {
        (this.DOMParentComponent.DOMParentComponent as BladeUiComponent).notifyUrlChange();
      }
    }
    //#endregion
  
    onChildUpdate(event: IupicsEvent): void {}
  
    onSiblingUpdate(event: IupicsEvent): void {}
  
    onRemoveComponent(event: IupicsEvent): void {}
  }
  
  type CalendarStore = {
    [key: ConcatenatedDate]: CalendarStoreEntry;
  };
  
  type CalendarStoreEntry = {
    data: any[];
    request: DataStoreRequest;
    expiringDate: Date;
  };
  
  type ConcatenatedDate = `${string}|${string}`;
  
  type DateParam = Date | string;
  