//#START CUSTO-SAMVAZ
import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { CallInProgressService } from '@compiere-ws/services/call-in-progress/call-in-progress.service';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import  AutocompleteUiComponent  from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import  EditViewUiComponent  from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { PrintReportManager } from '@iupics-manager/managers/print-report/print-report-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'print-ui',
  templateUrl: './print-ui.component.html',
  styleUrls: ['./print-ui.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    AutocompleteUiComponent,
    SidebarModule,
    ModalUiComponent,
    ButtonModule,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export class PrintUiComponent implements OnInit, OnDestroy {
  @Input()
  dsKey: DataStoreKey;
  @Input()
  sourceModal: ModalUiComponent;
  @Input()
  uuid: any;
  @Input()
  printCtx: object;

  @Input()
  editViewParent: EditViewUiComponent;

  @ViewChild('printerNameField')
  inputPrinterName: AutocompleteUiComponent;

  @Output()
  cancelPrintDataEmitter = new EventEmitter<any>();
  printerNames = { items: [] };
  printerName;

  subscriptions: any[] = [];

  isLoading = false;
  constructor(
    private uiCreatorService: UICreatorService,
    private translateService: TranslateService,
    private exportDataService: CompiereExportDataService,
    private printReportService: PrintReportService,
    private messageManager: MessageManagerService,
    private printReportManager: PrintReportManager,
    private connectorService: SecurityManagerService,
    private socketService: SocketService,
    private callInProgressService: CallInProgressService
  ) {}

  ngOnInit() {
    const ctx = this.connectorService.getIupicsUserContext();
    if (ctx['#Printer'] != null) {
      this.printerName = { id: ctx['#Printer'], displayValue: ctx['#Printer'] };
    } else {
      this.printerName = { id: 'No_Print', displayValue: this.translateService.instant('print.No_Print') };
    }

    this.uiCreatorService.getCupsPrinters().subscribe((cups) => {
      this.printerNames.items.push({ id: 'No_Print', displayValue: this.translateService.instant('print.No_Print') });
      cups.forEach((printer) => {
        this.printerNames.items.push({ id: printer.name, displayValue: printer.name });
      });
    });
  }

  print() {
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.printReport')) {
      if (
        this.inputPrinterName.fieldValue &&
        this.inputPrinterName.fieldValue.id !== null &&
        this.inputPrinterName.fieldValue.id !== undefined
      ) {
        // tslint:disable-next-line:no-string-literal
        this.printCtx['printerName'] = this.inputPrinterName.fieldValue.id;
        this.callInProgressService.setSubscription(
          this.uuid,
          'editview.printReport',
          this.printReportManager.printAndDownloadReport(
            this.dsKey,
            this.connectorService.getIupicsUserAccount().session_id,
            this.printCtx,
            () => {
              if (this.editViewParent) {
                this.editViewParent.refreshData(true);
              }
            }
          )
        );
      } else {
        this.callInProgressService.setSubscription(
          this.uuid,
          'editview.printReport',
          this.printReportManager.printAndDownloadReport(
            this.dsKey,
            this.connectorService.getIupicsUserAccount().session_id,
            this.printCtx,
            () => {
              if (this.editViewParent) {
                this.editViewParent.refreshData(true);
              }
            }
          )
        );
      }
      this.cancelPrintDataEmitter.emit();
    }
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
