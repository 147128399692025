<iu-storekeeper-input
  [type]="'textarea'"
  [label]="'Description' | SKWTranslate"
  [value]="data()?.Description ?? ('NoDescription' | SKWTranslate)"
  [readOnly]="!isNewTransfer()"
  (valueChange)="changeValue('Description', $event)"
/>

<iu-storekeeper-input
  [type]="'textarea'"
  [label]="'comment' | SKWTranslate"
  [value]="data()?.Comment ?? ''"
  [readOnly]="!isNewTransfer()"
  (valueChange)="changeValue('Comment', $event)"
/>

@if (isConditioning()) {
  <div class="column">
    <span>{{ 'Destination' | SKWTranslate }}</span>
    <div class="row">
      <iu-storekeeper-input
        class="flex"
        [type]="'text'"
        [label]="'Location' | SKWTranslate"
        [value]="lines()?.[0]?.locator_destination_barcode"
        [readOnly]="true"
        (valueChange)="changeValue('emplacement', $event)"
      />
      <iu-storekeeper-input
        [type]="'text'"
        [label]="'Pallet' | SKWTranslate"
        [value]="lines()?.[0]?.palette_destination_barcode"
        [readOnly]="true"
        (valueChange)="changeValue('palette', $event)"
      />
    </div>
  </div>
} @else if (canAddPalette()) {
  <iu-storekeeper-button [label]="'AddPallet' | SKWTranslate" />
}
