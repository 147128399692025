import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereParam } from '@compiere-ws/models/compiere-process-json';
import { CompiereDetails, CompiereField, CompiereFieldInfo } from '@compiere-ws/models/window-json';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField, IupicsTableDataHeader, NumberType } from '@iupics-manager/models/iupics-data';
import { FilterType } from '@iupics/apiz-grid';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import moment from 'moment';
export class UICreatorUtils {
  private static fieldGroups: IupicsField[];
  private static dataStore: DataStoreService;
  private static connectorService: SecurityManagerService;
  private static defaultLanguage: CompiereLanguage;
  private static translateService: TranslateService;

  public static setDataStore(dataStore: DataStoreService): void {
    this.dataStore = dataStore;
  }

  public static setConnectorService(connectorService: SecurityManagerService): void {
    this.connectorService = connectorService;
  }

  public static setTranslateService(translateService: TranslateService): void {
    this.translateService = translateService;
  }

  public static getComponentNameFromReference(referenceId: number, fieldLength: number = 0): string {
    /* Display Type 10	String	*/
    /* Display Type 14	Text	*/
    /* Display Type 40	URL	*/
    /* Display Type 36	File Path	*/
    /* Display Type 39 File Name	*/
    if (referenceId === 10 || referenceId === 14 || referenceId === 40 || referenceId === 38 || referenceId === 39) {
      if (referenceId === 14 || fieldLength > 254) {
        return 'InputTextareaUiComponent';
      } else {
        return 'InputTextUiComponent';
      }
    } else if (
      /* Display Type 11	Integer	*/
      /* Display Type 12	Amount	*/
      /* Display Type 13	ID	*/
      /* Display Type 22	Number	*/
      /* Display Type 29	Quantity	*/
      /* Display Type 37	CostPrice	*/
      referenceId === 11 ||
      referenceId === 12 ||
      referenceId === 13 ||
      referenceId === 22 ||
      referenceId === 29 ||
      referenceId === 37
    ) {
      return 'InputNumberUiComponent';
    } else if (referenceId >= 15 && referenceId <= 16) {
      /* Display Type 15	Date	*/
      /* Display Type 16	DateTime	*/
      return 'CalendarUiComponent';
    } else if (referenceId === 24) {
      /* Display Type 24	Time	*/
      return 'InputTimeUiComponent';
    } else if (
      (referenceId >= 17 && referenceId <= 19) ||
      referenceId === 30 ||
      referenceId === 42 ||
      referenceId === 31 ||
      referenceId === 25 ||
      referenceId === 35
    ) {
      /* Display Type 17	List	*/
      /* Display Type 18	Table	*/
      /* Display Type 19	TableDir	*/
      /* Display Type 25	Account	*/
      /* Display Type 30	Search	*/
      /* Display Type 31	Locator	*/
      /* Display Type 35	PAttribute	*/
      /* Display Type 42	PrinterName	*/
      return 'AutocompleteUiComponent';
    } else if (referenceId === 20) {
      /* Display Type 20	YN	*/
      return 'InputSwitchUiComponent';
    } else if (referenceId === 21) {
      /* Display Type 21	Location	*/
      return 'InputLocationUiComponent';
    } else if (referenceId === 28 || referenceId === 46) {
      /* Display Type 28	Button	*/
      /* Display type 46 Link Button */
      return 'ButtonUiComponent';
    } else if (referenceId === 36 || referenceId === 34 || referenceId === 23) {
      /* Display Type 36	CLOB	*/
      /* Display Type 23	BLOB	*/
      /* Display Type 34	Memo	*/
      return 'InputTextareaUiComponent';
    } else if (referenceId === 32) {
      /* Display Type 32 Image	*/
      return 'InputImageUiComponent';
    } else if (referenceId === 45) {
      /* Display Type 45 Upload File */
      return 'InputFileUiComponent';
    } else if (referenceId === 77) {
      /* Display Type 77 Comment */
      return 'CommentUiComponent';
    } else if (referenceId === 78) {
      /* Display Type 78 Text HTML */
      return 'InputTextHtmlUiComponent';
    } else if (referenceId === 79) {
      /* Display Type 78 Text JSON */
      return 'InputJsonUiComponent';
    } else if (referenceId === 27) {
      /* Display Type 27	Color   */
      return 'InputColorUiComponent';
    } else {
      //   console.log(`No Component defined for AD_Reference_ID : ${referenceId}`);
      return undefined;
    }
  }

  public static transformField(
    fieldEntity: CompiereField | CompiereParam,
    windowName?: string,
    tabInfo?: any,
    tabReadOnly?: boolean
  ): IupicsField {
    const field = fieldEntity.field;
    if (field.IsParent || (tabInfo && tabInfo.linkColumnName && tabInfo.linkColumnName === field.ColumnName)) {
      field.DefaultValue = '@' + field.ColumnName + '@';
    }
    const newComponent = UICreatorUtils.getComponentNameFromReference(field.AD_Reference_ID, field.DisplayLength);
    if (newComponent === 'CalendarUiComponent') {
      this.dataStore.saveDateColumn(field.ColumnName, field.AD_Reference_ID);
    }
    if (newComponent) {
      if (fieldEntity.callouts) {
        fieldEntity.callouts.fieldId = field.AD_Field_ID;
        // MODIFY THIS SHIT
        fieldEntity.callouts.tabId = fieldEntity.callouts['idtab'];
        fieldEntity.callouts.windowId = 1000234;
        // MODIFY THIS SHIT
      }
      const fieldTransformed: IupicsField = {
        columnId: field.AD_Column_ID,
        fieldId: field.AD_Field_ID,
        processId: field.AD_Process_ID,
        formId: field.AD_Form_ID,
        referenceId: field.AD_Reference_ID,
        displayLength: field.DisplayLength,
        isCallout: field.IsCallout,
        seqNo: field.SeqNo,
        isReadOnly: tabReadOnly || field.IsReadOnly,
        isRange: field.isRange,
        data: {
          fieldId: field.AD_Field_ID,
          columnId: field.AD_Column_ID,
          formId: field.AD_Form_ID,
          isUpdateable: field.IsUpdateable,
          isAlwaysUpdatable: field.IsAlwaysUpdateable,
          isSameLine: field.IsSameLine,
          label:
            field.AD_Field_ID === -2
              ? this.translateService.instant('mandatoryColumns.' + field.ColumnName)
              : field.Name
                ? field.Name
                : field.name, // 130422
          fieldType: field.IsEncryptedColumn || field.IsEncryptedField ? 'password' : 'text',
          isCopy: field.IsCopy,
          items: fieldEntity.data,
          isMandatory: field.IsMandatoryUI,
          description: field.Description,
          help: field.Help,
          columnName: field.ColumnName,
          displayLogic: !field.IsDisplayed ? '1=2' : field.DisplayLogic,
          isDisplayed: field.IsDisplayed != undefined ? field.IsDisplayed : field.AD_Column_ID === -2 ? false : true,
          mandatoryLogic: field.MandatoryLogic,
          readOnlyLogic: tabReadOnly ? '1=1' : field.IsReadOnly ? '1=1' : field.ReadOnlyLogic,
          nbrAddressSlot: 1,
          details: fieldEntity.details,
          urlList: fieldEntity.urlList,
          urlSearch: fieldEntity.urlSearch,
          urlCallouts: fieldEntity.urlCallouts,
          callouts: fieldEntity.callouts,
          tableName: tabInfo && tabInfo.tableName ? tabInfo.tableName : null,
          calloutLabel:
            windowName && tabInfo && tabInfo.tabName ? windowName + ' / ' + tabInfo.tabName + ' / ' + field.Name : null,
          tabId: field.AD_Tab_ID,
          isSearch:
            field.AD_Reference_ID === 30 || (fieldEntity.urlSearch !== null && fieldEntity.urlSearch !== undefined),
          isAccount: field.AD_Reference_ID === 25,
          needTime: field.AD_Reference_ID === 16 || field.AD_Reference_ID === 24,
          defaultValue: field.DefaultValue,
          defaultValue2: field.DefaultValue2,
          searchColumns: fieldEntity.details ? fieldEntity.details.searchColumns : null,
          nbCol: Global.isMobileWidth() ? 1 : parseInt(field.ColNo, 0),
          marginTop: field.MarginTop,
          colspan: field.ColspanEdit,
          jsonDefId: field.jsonDefId,
          numberType:
            field.AD_Reference_ID === 12 || field.AD_Reference_ID === 37
              ? NumberType.AMOUNT
              : field.AD_Reference_ID === 11
                ? NumberType.INTEGER
                : field.AD_Reference_ID === 22
                  ? NumberType.FLOAT
                  : field.AD_Reference_ID === 29
                    ? NumberType.QUANTITY
                    : null,
          validationCode: field.ValidationCode ? field.ValidationCode.replace(/\n/g, ' ') : null,
          detailZoom:
            fieldEntity.details &&
            (field.AD_Reference_ID === 30 || field.AD_Reference_ID === 19 || field.AD_Reference_ID === 18)
              ? {
                  tableName: fieldEntity.details.tableName,
                  columnKey: fieldEntity.details.keyColumn,
                }
              : null,
          displayKanban: field.DisplayKanban,
          componentName: newComponent,
          isFieldOnly: field.IsFieldOnly,
          isButtonReadOnly: field.IsButtonReadOnly,
          vFormat: field.AD_Reference_ID === 10 ? field.VFormat : null,
          //#START CUSTO-SAMVAZ
          z_ZoomTab: field.z_ZoomTab,
          isDefaultFocus : field.isDefaultFocus
          //#END CUSTO-SAMVAZ
        },
        component: newComponent,
      }; // 17 list, 18 table, 19 table dir , 30 search
      if (field.IsDisplayed && field.FieldGroup) {
        let fieldGroup = null;
        for (const fieldGroupIterate of this.fieldGroups) {
          if (fieldGroupIterate.data.label === field.FieldGroup) {
            fieldGroup = fieldGroupIterate;
            break;
          }
        }
        if (fieldGroup) {
          fieldGroup.children.push(fieldTransformed);
          return undefined;
        } else {
          fieldGroup = {
            columnId: field.AD_Column_ID,
            fieldId: field.AD_Field_ID,
            processId: field.AD_Process_ID,
            formId: field.AD_Form_ID,
            referenceId: field.AD_Reference_ID,
            displayLength: field.DisplayLength,
            isCallout: field.IsCallout,
            seqNo: field.SeqNo,
            isReadOnly: field.IsReadOnly,
            data: {
              isUpdateable: field.IsUpdateable,
              isAlwaysUpdatable: field.IsAlwaysUpdateable,
              isSameLine: field.IsSameLine,
              label: field.FieldGroup,
              isMandatory: field.IsMandatoryUI,
              displayLogic: field.FieldGroupDisplayLogic,
              isCollapsedDefault: field.FieldGroupExpanded !== undefined ? !field.FieldGroupExpanded : undefined,
            },
            component: 'AccordionUiComponent',
            children: [],
          };
          this.fieldGroups.push(fieldGroup);
          fieldGroup.children.push(fieldTransformed);
        }
        return fieldGroup;
      } else {
        return fieldTransformed;
      }
    }
    return undefined;
  }

  public static transformFieldForGrid(
    field: CompiereFieldInfo,
    isSearch: boolean = false,
    details?: CompiereDetails
  ): IupicsTableDataHeader {
    // 130422 field_id -2 are mandatoryfields which doesnt exist in AD

    if (field.AD_Field_ID === -2 || (field.IsDisplayed && field.MRSeqNo > 0) || isSearch) {
      const fieldTransformed: IupicsTableDataHeader = {
        field: field.ColumnName,
        headerName:
          field.AD_Field_ID === -2
            ? this.translateService.instant('mandatoryColumns.' + field.ColumnName)
            : field.Name
              ? field.Name
              : field.name, // 130422
        enableRowGroup: true,
        editable: false,
        hide: !field.IsDisplayed,
        width: 'min-content',
        resizable: true,
      };
    //#START CUSTO-SAMVAZ
    this.defaultLanguage = this.connectorService.getIupicsDefaultLanguage();
    let lang = this.defaultLanguage.iso_code.replace('_', '-');
    if (lang === 'fr-FR') {
      lang = 'de-CH';
    }
    switch (field.AD_Reference_ID) {
      case 24:
        // Time
        fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value) ? `${moment(params.value).format('LT')}` : '';
        break;

      case 20:
        // InputSwitchUiComponent
        fieldTransformed.cellRenderer = (params: any) =>
          !isNil(params.value)
            ? `<label class="container-background">
              <input type="checkbox" disabled='disabled' ${params.value === 'Y' ? 'checked' : ''} >
              ${
                params.value === 'Y'
                  ? '<span class="checkbox icon-checkbox-checked"></span>'
                  : '<span class="checkbox icon-checkbox-crossed"></span>'
              }
              </label>`
            : '';
        break;
      case 17:
        this.displayBackgroundField(fieldTransformed, field, details);
        break;
      case 28:
        this.displayBackgroundField(fieldTransformed, field, details);
        break;
      case 12:
        // Montant
        fieldTransformed.cellRenderer = (params: any) =>
        !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
        break;
      case 37:
        // Montant
        fieldTransformed.cellRenderer = (params: any) =>
        !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
        break;
      case 11:
        // Integer
        fieldTransformed.cellRenderer = (params: any) =>
        !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
        break;
      case 22:
        // Float
        fieldTransformed.cellRenderer = (params: any) =>
        !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
        break;
      case 29:
        // Float
        fieldTransformed.cellRenderer = (params: any) =>
        !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
        break;
      default:
        break;
        //#END CUSTO-SAMVAZ
      }
      return fieldTransformed;
    }

    return undefined;
  }

  static prepareColumnsTableHeader(columnsTableHeader: IupicsTableDataHeader[]) {
    for (const cth of columnsTableHeader) {
      cth.width = 'min-content';
      //#START CUSTO-SAMVAZ
      this.defaultLanguage = this.connectorService.getIupicsDefaultLanguage();
      let lang = this.defaultLanguage.iso_code.replace('_', '-');
      if (lang === 'fr-FR') {
        lang = 'de-CH';
      }
      switch (cth.AD_Reference_ID) {
        case 24:
          // Time
          cth.cellRenderer = (params: any) => (!isNil(params.value) ? `${moment(params.value).format('LT')}` : '');
          break;

        case 20:
          // InputSwitchUiComponent
          cth.cellRenderer = (params: any) =>
            !isNil(params.value)
              ? `<label class="container-background">
  <input type="checkbox" disabled='disabled' ${params.value === 'Y' ? 'checked' : ''} >
  ${
    params.value === 'Y'
      ? '<span class="checkbox icon-checkbox-checked"></span>'
      : '<span class="checkbox icon-checkbox-crossed"></span>'
  }
  </label>`
              : '';
          break;
        case 17:
          this.displayBackgroundFieldUI(cth);
          break;
        case 28:
          this.displayBackgroundFieldUI(cth);
          break;
          case 12:
            // Montant
            cth.cellRenderer = (params: any) =>
            !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
            break;
          case 37:
            // Montant
            cth.cellRenderer = (params: any) =>
            !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
            break;
          case 11:
            // Integer
            cth.cellRenderer = (params: any) =>
            !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
            break;
          case 22:
            // Float
            cth.cellRenderer = (params: any) =>
            !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
            break;
          case 29:
            // Float
            cth.cellRenderer = (params: any) =>
            !isNil(params.value) ? `${params.value.toLocaleString(lang)}` : '';
            break;
          default:
            break;
            //#END CUSTO-SAMVAZ
      }
      delete cth.cssColors;
      delete cth.AD_Reference_ID;
    }
  }

  private static displayBackgroundFieldUI(cth: IupicsTableDataHeader) {
    if (cth.cssColors) {
      const cssColors = cth.cssColors;
      cth.cellRenderer = (params) =>
        !isNil(params.value)
          ? `<span style="background: ${cssColors[params.value] || 'transparent'}" class="text-background">${
              params.valueFormatted
            }</span>`
          : '';
    }
  }

  public static getFilterTypeFromReference(referenceId: number): FilterType {
    return referenceId === 24
      ? FilterType.TIME
      : referenceId === 15 || referenceId === 16
        ? FilterType.DATE
        : (referenceId >= 17 && referenceId <= 19) ||
            referenceId === 30 ||
            referenceId === 42 ||
            referenceId === 27 ||
            referenceId === 31 ||
            referenceId === 25
          ? FilterType.SET
          : referenceId === 11 ||
              referenceId === 12 ||
              referenceId === 13 ||
              referenceId === 22 ||
              referenceId === 29 ||
              referenceId === 37 ||
              referenceId === 21
            ? FilterType.NUMBER
            : FilterType.TEXT;
  }

  public static formatCtxArea(ctxArea: any): any {
    for (const key of Object.keys(ctxArea)) {
      if (ctxArea[key] === true) {
        ctxArea[key] = 'Y';
      } else if (ctxArea[key] === false) {
        ctxArea[key] = 'N';
      }
    }
    return ctxArea;
  }

  public static displayBackgroundField(
    fieldTransformed: IupicsTableDataHeader,
    field: CompiereFieldInfo,
    details: CompiereDetails
  ) {
    if (field.AD_Reference_Value_ID > 0 && details && details.tableName === 'AD_Ref_List' && details.cssColors) {
      fieldTransformed.cellRenderer = (params) =>
        !isNil(params.value)
          ? `<span style="background: ${details.cssColors[params.value] || 'transparent'}" class="text-background">${
              params.valueFormatted
            }</span>`
          : '';
    }
    return fieldTransformed;
  }
}
